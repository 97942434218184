angular.module('mosaik.services')
  .factory('colorService',
    [function () {
      var hexDigits = new Array ("0","1","2","3","4","5","6","7","8","9","a","b","c","d","e","f");

      function hex(x) {
        return isNaN(x) ? "00" : hexDigits[(x - x % 16) / 16] + hexDigits[x % 16];
      }

      return {
        rgb2hex: function (rgb) {
          // return if already hexa
          if (/^#[0-9A-F]{6}$/i.test(rgb)) return rgb;

          //return as received if not expected format
          if (!/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/i.test(rgb)) return rgb;

          rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/i);
          return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
        },
        shade: function (hexColor, percent) {
          // return as received if not a match for an hexadecimal color
          if (!/^#[0-9A-F]{6}$/i.test(hexColor)) return hexColor;

          var R = parseInt(hexColor.substring(1, 3), 16);
          var G = parseInt(hexColor.substring(3, 5), 16);
          var B = parseInt(hexColor.substring(5, 7), 16);

          R = parseInt(R * (100 + percent) / 100);
          G = parseInt(G * (100 + percent) / 100);
          B = parseInt(B * (100 + percent) / 100);

          R = (R < 255) ? R : 255;
          G = (G < 255) ? G : 255;
          B = (B < 255) ? B : 255;

          var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
          var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
          var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

          return "#" + RR + GG + BB;
        }
      }
    }
    ]
  )