angular.module('mosaik.services')
  .factory('accessCodeService',
    ['$rootScope', '$timeout', '$http', '$state', 'notificationHelper', '$window', '$translate', 'sessionStateService', 'translationService',
      function ($rootScope, $timeout, $http, $state, notificationHelper, $window, $translate, sessionStateService, translationService) {

        function _setCodeInRootScope(accessCode) {
          $rootScope.accessCode = accessCode
        }

        function _removeDashesAndSpaces(value) {
          return (value || '').replace(/-|\s/g, '')
        }

        var self = {
          getCode: () => $rootScope.accessCode,

          activateCodeTimeout: (params) => {
            if (self.getCode()) {
              $timeout(() => { // code expired
                if (self.getCode() === params.accessCode) {
                  self.deleteCode()
                }
              }, 180000)
            }
          },

          deleteCode: () => delete $rootScope.accessCode,
          /**
           * Redeem the given code
           * @param {*} params Object containing the access code to redeem. 
           * @returns Promise, resolves on redeem success, reject on failure with xhr response.
           */
          redeem: (params) => {
            params.accessCode = _removeDashesAndSpaces(params.accessCode)
            return $http.put('/api/redeem/organization', params)
          },

          /**
           * Validate given code. If no code is given, try to validate current code in memory.
           * @param {*} params Object containing the code to validate
           * @param {*} notifyOnCodeAlreadyUsed Show notification to user if the code has already been used.
           * @returns A promise with object which may contain a "data" property for the validation results.
           */
          validateCode: (params, notifyOnCodeAlreadyUsed) => {
            return new Promise((resolve, reject) => {
              const givenCode = _removeDashesAndSpaces(params.accessCode)
              const captcha = params.captcha
              const currentCode = self.getCode()

              // resolve if givenCode is valid, override currentCode         
              if (givenCode) {
                return $http.put('/api/validateCode/validate', { accessCode: givenCode, captcha: captcha })
                  .then((success) => {
                    success.data.accessCode = givenCode
                    _setCodeInRootScope(givenCode)
                    translationService.change(success.data.locale, () => resolve(success))
                  }, (error) => {
                    translationService.change(error.data.locale, () => {
                      self.showErrorMessage(error.data.errorName, error.data.message, notifyOnCodeAlreadyUsed)
                      reject(error)
                    })
                  })
              }

              // no given code, and already have a valid code
              if (currentCode) {
                return resolve({ data: { accessCode: currentCode } })
              }
              // failed to validate, the caller should ask the user for a valid code
              reject({})
            })
          },

          showErrorMessage: (errorName, title, notifyOnCodeAlreadyUsed) => {
            let html = ''
            let confirmButtonText = $translate.instant('ContactMyAdmin')
            let cancelButtonText = $translate.instant('ContactSupport')
            let icon = 'error'
            let showCancelButton = true
            let confirmGoToState
            let dismissGoToState

            if (errorName === 'AccessCodeAlreadyUsed') {
              html = $translate.instant('AccessCodeAlreadyUsedhelp')
              icon = 'warning'
              confirmButtonText = $translate.instant('AlreadyRegisteredLogin')
              cancelButtonText = $translate.instant('NotRegisterGetHelp')
              confirmGoToState = 'passport'

              if (notifyOnCodeAlreadyUsed) {
                return notificationHelper.warning(title + '<br><br>' + html)
              }
            }


            if (errorName === 'AccessCodeDoesNotExist') {
              html = $translate.instant('AccessCodeDoesNotExistHelp')
              dismissGoToState = 'help'
            }

            if (errorName === 'AccessCodeExpired') {
              html = $translate.instant('AccessCodeExpiredHelp')
              dismissGoToState = 'help'
            }

            // captcha related errors
            if (errorName === 'CaptchaRequired') {
              icon = 'warning'
              confirmButtonText = 'OK'
              showCancelButton = false
            }

            // low level errors
            if (!errorName) {
              confirmButtonText = 'OK'
              showCancelButton = false
            }

            $window.Swal.fire({
              icon,
              title,
              html,
              showCancelButton,
              showCloseButton: true,
              confirmButtonText,
              cancelButtonText
            }).then((result) => {
              // closed without pressing a button
              if (!result.isConfirmed && result.dismiss !== 'cancel') return

              if (result.dismiss === 'cancel') {
                if (dismissGoToState) {
                  return $state.go(dismissGoToState, {}, { reload: true })
                }

                if (errorName === 'AccessCodeAlreadyUsed') {
                  // show options for support
                  $window.Swal.fire({
                    icon: 'question',
                    title: $translate.instant('HowYouWantToObtainHelp'),
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonText: $translate.instant('ContactMyAdmin'),
                    cancelButtonText: $translate.instant('ContactSupport')
                  }).then((result) => {
                    if (result.dismiss === 'cancel') {
                      $state.go('help', {}, { reload: true })
                    }
                  })
                }
              }

              if (confirmGoToState === 'passport') {
                return sessionStateService.terminateSession()
                  .then((result) => $state.go(confirmGoToState, {}, { reload: true }))
              }
            })
          }
        }
        return self
      }
    ]
  )
