angular.module('mosaik.controllers')
  .controller('settingsController',
    ['$scope', '$translate', '$timeout', '$location', 'sessionState', 'tags',
      function ($scope, $translate, $timeout, $location, sessionState, tags) {
        // params init
        $timeout(() => {
          $scope.show = true
          $scope.strategyData = {
            strategyName: sessionState.strategyName
          }
        })

        // Expose for childrens
        $scope.sessionState = sessionState
        $scope.tags = tags


        $scope.$on('nav-tab-change', function (event, args) {
          // activate the switch state manually; bug in BS-switch, has to be done with a delay
          if (args && args.tab === 'tags') {
            $timeout(() => {
              angular.element('.checkbox-switch').bootstrapSwitch({ state: angular.element(this).is(':checked') }).trigger('change')
            }, 200)
          }
        })
      }
    ])
