angular.module('mosaik.directives')
  .directive('repeatDone',
    ['$timeout',
      $timeout => {
        return {
          restrict: 'A',
          scope: {
            last: '='
          },
          link: (scope, element, attrs) => {
            if (scope.last) $timeout(() => scope.$emit('repeat-done'))
          }
        }
      }
    ])