angular.module('mosaik.controllers')
  .controller('recoverPasswordController',
    ['$scope', '$state', '$http', '$window', 'notificationHelper', '$translate', 'captchaHelper', '$timeout', 'utilService',
      function ($scope, $state, $http, $window, notificationHelper, $translate, captchaHelper, $timeout, utilService) {
        $timeout(function () {
          $scope.show = true
          utilService.focusOnDesktop('recover-email')
        }, 100)
        $scope.user = {
          captcha: ''
        }

        $scope.captchaRequired = false     
        var captchaConfig = {
          'elementid': 'recaptcha-container',
          'callback': response => $scope.user.captcha = response,
          'expired-callback': () =>  $scope.user.captcha = null
        }

        // Scope function to perform actual access code redeem
        $scope.recover = function () {
          $scope.loading = true

          $http.put('/api/password/recover',
            $scope.user
          )
            .then(success => {
              notificationHelper.successFrom(success)
              $state.go(success.data.redirectState.name, success.data.redirectState.params, { reload: 'secure' })
            })
            .catch(error => {
              notificationHelper.failureFrom(error)
              // activate captcha if required by backend
              if (error.data.captchaRequired) {
                if (!$scope.captchaRequired) {
                  // first time we need captcha for this form: we need to load      
                  captchaHelper.load(captchaConfig, true)
                } else {
                  captchaHelper.reset()
                }
                $timeout(() => {
                  $scope.captchaRequired = true
                })
              }

              if (!error.data.captchaRequired) {
                captchaHelper.remove()
                $scope.captchaRequired = false
              }
            })
            .finally(() => $scope.loading = false)
        }

        $scope.$on('locale-changed', function (event, args) {
          if ($scope.captchaRequired) {
            captchaHelper.load(captchaConfig)
          }
        })
      }
    ]
)
