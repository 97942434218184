angular.module('mosaik.controllers')
  .controller('memoConsultationController',
  ['$scope', 'sessionState', '$translate', '$timeout', 'datatablesHelper', 'dashboardService',
    function ($scope, sessionState, $translate, $timeout, datatablesHelper, dashboardService) {
      // access rights
      $scope.canAccessDashboard = sessionState.canAccessDashboard
      $scope.canAccessMemo = sessionState.canAccessMemo
      // parameters
      var tableid = '#datatables-memo-consultation'
      var tableElement = angular.element(tableid)
      var api = '/admin-api/organizationMemoConsultation'
      $scope.queryHidden = {
        orgid: sessionState.orgidSelector
      }
      $scope.queryDefault = {
        state: 'visible'
      }
      $scope.query = {
        state: '',
      }
      // data
      // editors
      // DT table
      $scope.table

      $timeout(function () {
        datatablesHelper.init($scope, tableid, api, '', initializeTable)
      })

      function initializeTable() {
        var datatableParams = angular.extend(datatablesHelper.getDatatablesParams({ filteringInput: false }), {
          columns: [
            { data: 'id' },
            { 
              data: 'statusOnline',
              render: function (data, type, row) {
                if (data) {
                  return '<div class="label-mosaik label-mosaik-success">' + $translate.instant('Visible') + '</div>'
                }
                return '<div class="label-mosaik label-mosaik-danger">' + $translate.instant('Invisible') + '</div>'
              }
            },
            { data: 'title' },
            {
              data: function (row, type) {
                var value = row.consultationScaled != null ? String(Math.round(row.consultationScaled * 100)) : ''
                if (type === 'export') {
                  return value !== '' ? value + '%' : ''
                }
                return '<div class="progress">' +
                  '<div class="progress-bar progress-bar-default" role="progressbar" data-transitiongoal="' + value + '" aria-valuenow="' + value + '" aria-valuemin="0" aria-valuemax="100"></div>' +
                  '</div>'
              }
            },
            { 
              data: 'expiresAt',
              render: function (data, type, row) {
                if (type === 'export') {
                  return datatablesHelper.renderDateTimeReport(data)
                }
                return datatablesHelper.renderDateFromNow(data)
              }
            },
            { 
              data: 'publishedAt',
              render: function (data, type, row) {
                return datatablesHelper.renderDateTimeReport(data)
              }
            }
          ],
          ordering: true,
          order: [[5, 'desc']],
          select: false,
          createdRow: function (row, data, dataIndex) {
            angular.element(row).find('.progress .progress-bar').progressbar({ display_text: 'fill' })
          }
        })
        datatablesHelper.createAndSetTable(datatableParams)
      }
    }
  ])
