angular.module('mosaik.services')
  .factory('organizationService',
    ['httpService', 'utilService', '$translate',
      function (httpService, utilService, $translate) {
        const renderOption = org => `<option value="${org.id}" data-content="${org.name}&nbsp;<small class='text-id'>${org.internalOrgid}</small>"></option>`
        const _organizations = state => httpService.get('/admin-api/organizationByName?state=' + state, { createOptions: true, renderOption })

        return {
          get: (orgid, params) => httpService.get('/admin-api/organization/' + orgid),
          organizations: () => _organizations('active'),
          allOrganizations: () => _organizations('all'),
          formatAddress: org => String((org.civicNumber || '') + ' ' + (org.streetName || '') + ' ' + (org.city || '') + ' ' + (org.province || '') + ' ' + (org.country || '') + ' ' + (org.postalCode || '')).trim(),

          fetchTenantid: (domainName, domainNameField, ssoTenantidField, editor) => {
            return new Promise((resolve, reject) => {
              // cleanup messages and errors
              domainNameField.error('')
              ssoTenantidField.error('')
              ssoTenantidField.message('')
              editor.error('')
              // domain must be valid
              if (!domainName || !utilService.getDomainRegex().test(domainName)) {
                domainNameField.error($translate.instant('InvalidValue', { fieldName: $translate.instant('DomainName') }))
                reject()
              } else {
                httpService.get(`https://login.microsoftonline.com/${domainName}/.well-known/openid-configuration`, { noCache: true, dataSubKey: 'token_endpoint' })
                  .then(value => {
                    const id = value.split("/")[3]
                    if (id) {
                      ssoTenantidField.val(id)
                      ssoTenantidField.message($translate.instant('ValueFound'))
                    } else {
                      ssoTenantidField.message($translate.instant('ValueNotFound'))
                    }                    
                    resolve(id)
                  }, error => {
                    const message = error && error.error_description ? error.error_description : error.message || error
                    ssoTenantidField.error(message.toString())
                    reject(error)
                  })
              }
            })
            
          }
        }
      }]
  )