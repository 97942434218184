angular.module('mosaik.services')
  .factory('utilService',
    ['$timeout', '$window', 'appConfig', 'browserService',
      function ($timeout, $window, appConfig, browserService) {
        const htmlStripperElement = $window.document.createElement('div')

        // Borrowed from Lodash (_.deburr)
        /** Used to map Latin Unicode letters to basic Latin letters. */
        const deburredLetters = {
          // Latin-1 Supplement block.
          '\xc0': 'A', '\xc1': 'A', '\xc2': 'A', '\xc3': 'A', '\xc4': 'A', '\xc5': 'A',
          '\xe0': 'a', '\xe1': 'a', '\xe2': 'a', '\xe3': 'a', '\xe4': 'a', '\xe5': 'a',
          '\xc7': 'C', '\xe7': 'c',
          '\xd0': 'D', '\xf0': 'd',
          '\xc8': 'E', '\xc9': 'E', '\xca': 'E', '\xcb': 'E',
          '\xe8': 'e', '\xe9': 'e', '\xea': 'e', '\xeb': 'e',
          '\xcc': 'I', '\xcd': 'I', '\xce': 'I', '\xcf': 'I',
          '\xec': 'i', '\xed': 'i', '\xee': 'i', '\xef': 'i',
          '\xd1': 'N', '\xf1': 'n',
          '\xd2': 'O', '\xd3': 'O', '\xd4': 'O', '\xd5': 'O', '\xd6': 'O', '\xd8': 'O',
          '\xf2': 'o', '\xf3': 'o', '\xf4': 'o', '\xf5': 'o', '\xf6': 'o', '\xf8': 'o',
          '\xd9': 'U', '\xda': 'U', '\xdb': 'U', '\xdc': 'U',
          '\xf9': 'u', '\xfa': 'u', '\xfb': 'u', '\xfc': 'u',
          '\xdd': 'Y', '\xfd': 'y', '\xff': 'y',
          '\xc6': 'Ae', '\xe6': 'ae',
          '\xde': 'Th', '\xfe': 'th',
          '\xdf': 'ss',
          // Latin Extended-A block.
          '\u0100': 'A', '\u0102': 'A', '\u0104': 'A',
          '\u0101': 'a', '\u0103': 'a', '\u0105': 'a',
          '\u0106': 'C', '\u0108': 'C', '\u010a': 'C', '\u010c': 'C',
          '\u0107': 'c', '\u0109': 'c', '\u010b': 'c', '\u010d': 'c',
          '\u010e': 'D', '\u0110': 'D', '\u010f': 'd', '\u0111': 'd',
          '\u0112': 'E', '\u0114': 'E', '\u0116': 'E', '\u0118': 'E', '\u011a': 'E',
          '\u0113': 'e', '\u0115': 'e', '\u0117': 'e', '\u0119': 'e', '\u011b': 'e',
          '\u011c': 'G', '\u011e': 'G', '\u0120': 'G', '\u0122': 'G',
          '\u011d': 'g', '\u011f': 'g', '\u0121': 'g', '\u0123': 'g',
          '\u0124': 'H', '\u0126': 'H', '\u0125': 'h', '\u0127': 'h',
          '\u0128': 'I', '\u012a': 'I', '\u012c': 'I', '\u012e': 'I', '\u0130': 'I',
          '\u0129': 'i', '\u012b': 'i', '\u012d': 'i', '\u012f': 'i', '\u0131': 'i',
          '\u0134': 'J', '\u0135': 'j',
          '\u0136': 'K', '\u0137': 'k', '\u0138': 'k',
          '\u0139': 'L', '\u013b': 'L', '\u013d': 'L', '\u013f': 'L', '\u0141': 'L',
          '\u013a': 'l', '\u013c': 'l', '\u013e': 'l', '\u0140': 'l', '\u0142': 'l',
          '\u0143': 'N', '\u0145': 'N', '\u0147': 'N', '\u014a': 'N',
          '\u0144': 'n', '\u0146': 'n', '\u0148': 'n', '\u014b': 'n',
          '\u014c': 'O', '\u014e': 'O', '\u0150': 'O',
          '\u014d': 'o', '\u014f': 'o', '\u0151': 'o',
          '\u0154': 'R', '\u0156': 'R', '\u0158': 'R',
          '\u0155': 'r', '\u0157': 'r', '\u0159': 'r',
          '\u015a': 'S', '\u015c': 'S', '\u015e': 'S', '\u0160': 'S',
          '\u015b': 's', '\u015d': 's', '\u015f': 's', '\u0161': 's',
          '\u0162': 'T', '\u0164': 'T', '\u0166': 'T',
          '\u0163': 't', '\u0165': 't', '\u0167': 't',
          '\u0168': 'U', '\u016a': 'U', '\u016c': 'U', '\u016e': 'U', '\u0170': 'U', '\u0172': 'U',
          '\u0169': 'u', '\u016b': 'u', '\u016d': 'u', '\u016f': 'u', '\u0171': 'u', '\u0173': 'u',
          '\u0174': 'W', '\u0175': 'w',
          '\u0176': 'Y', '\u0177': 'y', '\u0178': 'Y',
          '\u0179': 'Z', '\u017b': 'Z', '\u017d': 'Z',
          '\u017a': 'z', '\u017c': 'z', '\u017e': 'z',
          '\u0132': 'IJ', '\u0133': 'ij',
          '\u0152': 'Oe', '\u0153': 'oe',
          '\u0149': "'n", '\u017f': 's'
        };

        /** Used to match Latin Unicode letters (excluding mathematical operators). */
        const reLatin = /[\xc0-\xd6\xd8-\xf6\xf8-\xff\u0100-\u017f]/g;

        /** Used to compose unicode character classes. */
        const rsComboMarksRange = '\\u0300-\\u036f',
          reComboHalfMarksRange = '\\ufe20-\\ufe2f',
          rsComboSymbolsRange = '\\u20d0-\\u20ff',
          rsComboMarksExtendedRange = '\\u1ab0-\\u1aff',
          rsComboMarksSupplementRange = '\\u1dc0-\\u1dff',
          rsComboRange = rsComboMarksRange + reComboHalfMarksRange + rsComboSymbolsRange + rsComboMarksExtendedRange + rsComboMarksSupplementRange;

        /** Used to compose unicode capture groups. */
        const rsCombo = '[' + rsComboRange + ']';

        /**
         * Used to match [combining diacritical marks](https://en.wikipedia.org/wiki/Combining_Diacritical_Marks) and
         * [combining diacritical marks for symbols](https://en.wikipedia.org/wiki/Combining_Diacritical_Marks_for_Symbols).
         */
        const reComboMark = RegExp(rsCombo, 'g');

        function deburrLetter(key) {
          return deburredLetters[key];
        };

        const htmlEntityMap = {
          "<": "&lt;",
          ">": "&gt;",
          '"': '&quot;',
          "'": '&#39;',
          "/": '&#x2F;'
      };

        const self = {
          /**
           * Extract the unique values from a property in an array of objects
           * @param {[*]} data Array of objects which have the "key" property. Defaults to empty array.
           * @param {String} key Optional property value to extract. Defaults to "id" 
           * @returns {[]} Array of primitive values
           */
          ids: (data = [], key = 'id') => [...new Set(data.map(one => one[key]).filter(id => !!id))],

          normalizeToBase: (string) => {
            string = string.toString();
            return string && string.replace(reLatin, deburrLetter).replace(reComboMark, '');
          },

          propertyUniqByWithCount: function (values, prop) {
            var results = { length: 0 }

            for (var i = 0; i < values.length; i++) {
              if (!results[values[i][prop]]) {
                results[values[i][prop]] = { count: 1 }
                results.length++
              } else {
                results[values[i][prop]].count++
              }
            }
            return results
          },

          concatArrayProp: function (values, prop) {
            var results = []
            // Dumb but probably faster than array.concat()... Provided arrays are small anyway
            for (var i = 0; i < values.length; i++) {
              for (var j = 0; j < values[i][prop].length; j++) {
                results.push(values[i][prop][j])
              }
            }
            return results
          },

          removeAlreadyOwnedChilds: function (parents, allPossibleChilds, childArrayName, childIdPropertyName, valuePropertyName) {
            var results = allPossibleChilds.slice(0) //clone the array to not modify "childs" array
            var parentOwnedChilds = self.concatArrayProp(parents, childArrayName)
            var uniqParentOwnedChilds = self.propertyUniqByWithCount(parentOwnedChilds, childIdPropertyName)
            // remove unique common childs
            for (var i = allPossibleChilds.length - 1; i >= 0; i--) {
              if (uniqParentOwnedChilds[allPossibleChilds[i][valuePropertyName]] && uniqParentOwnedChilds[allPossibleChilds[i][valuePropertyName]].count === parents.length) {
                results.splice(i, 1)
              }
            }
            return results
          },

          removeNotOwnedChilds: function (parents, allPossibleChilds, childArrayName, childIdPropertyName, valuePropertyName) {
            var results = allPossibleChilds.slice(0) //clone the array to not modify "childs" array
            var parentOwnedChilds = self.concatArrayProp(parents, childArrayName)
            var uniqParentOwnedChilds = self.propertyUniqByWithCount(parentOwnedChilds, childIdPropertyName)
            // remove childs that nobody owns
            for (var i = allPossibleChilds.length - 1; i >= 0; i--) {
              if (!uniqParentOwnedChilds[allPossibleChilds[i][valuePropertyName]]) {
                results.splice(i, 1)
              }
            }
            return results
          },

          /**
           * Given object of objects, keep only a specific properties
           * @param {Object} objects a plain JS object mapping objects
           * @param {String} subKeysToKeep Only keep that key in each objects
           * @returns {Object} New object containing objects with only the subkey.
           */
          filterPropKeepSubKeys: function (objects, subKeysToKeep) {
            var results = {}
            subKeysToKeep = subKeysToKeep ? subKeysToKeep : ['id']
            for (var key in objects) {
              if (objects.hasOwnProperty(key)) {
                var obj = {}
                for (var i = 0; i < subKeysToKeep.length; i++) {
                  obj[subKeysToKeep[i]] = objects[key][subKeysToKeep[i]]
                }
                results[key] = obj
              }
            }
            return results
          },

          /**
           * Create a map from an array of objects
           * @param {[Object]}  arrValues Array of object elements
           * @param {String} keyProperty The property found in each element to use for the returned map. Default value: "id".
           * @return {Object} A map of elements with their keyProperty as the key
           */
          mapObjectsByKey: function (arrValues, keyProperty = 'id') {
            arrValues = self.toArray(arrValues)
            let results = {}
            for (let i = 0; i < arrValues.length; i++) {
              results[arrValues[i][keyProperty]] = arrValues[i]
            }
            return results
          },


          focusOnDesktop: (id) => {
            // timeout makes sure that it is invoked after any other event has been triggered.
            // e.g. click events that need to run before the focus or
            // inputs elements that are in a disabled state but are enabled when those events
            // are triggered.
            $timeout(() => {
              if (browserService.isMobile()) {
                return
              }
              const element = $window.document.getElementById(id)
              if (element) {
                element.focus()
              }
            })
          },

          findById: function (values, id) {
            for (var i = 0; i < values.length; i++) {
              if (values[i].id == id) {
                return values[i]
              }
            }
            return null
          },

          /**
           * 
           * @param {*} obj An array to return as is or an object to be returned as an array of one item
           * @returns 
           */
          toArray: function (obj) {
            return Array.isArray(obj) ? obj : (obj ? [obj] : [])
          },

          /**
           * 
           * @param {*} iterable An object with a length property and with all iterable properties being a sequential number up to length
           */
          iterableObjectToArray: function (iterable) {
            var result = []
            for (var i = 0; i < iterable.length; i++) {
              result.push(iterable[i])
            }
            return result
          },

          arrayPushTo: function (source, destination) {
            for (var i = 0; i < source.length; i++) {
              destination.push(source[i])
            }
          },

          // TODO : possibly to remove
          findIndexById: function (values, id) {
            for (var i = 0; i < values.length; i++) {
              if (values[i].id == id) {
                return i
              }
            }
            return -1
          },

          arrayBufferToJSON: function (buffer) {
            if (!buffer || !(buffer instanceof ArrayBuffer)) {
              return ""
            }
            if ('TextDecoder' in $window) {
              // Decode as UTF-8
              var dataView = new DataView(buffer)
              var decoder = new TextDecoder('utf8')
              return JSON.parse(decoder.decode(dataView))
            }
            // Fallback encode as UTF URI code and then decode it. Nice Hack.
            function _pad(n) {
              return n.length < 2 ? "0" + n : n
            }

            var array = new Uint8Array(buffer);
            var str = "";
            for (var i = 0, len = array.length; i < len; ++i) {
              str += ("%" + _pad(array[i].toString(16)))
            }

            return JSON.parse(decodeURIComponent(str))
          },

          debounce: function debounce(wait, func, immediate) {
            var timeout;
            return function () {
              var context = this, args = arguments;
              var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
              };
              var callNow = immediate && !timeout;
              clearTimeout(timeout);
              timeout = setTimeout(later, wait);
              if (callNow) func.apply(context, args);
            }
          },

          lastStringValueAfterDot: propertyName => {
            const dotIndex = (propertyName || '').lastIndexOf('.')
            return dotIndex === -1 ? propertyName : propertyName.substring(dotIndex + 1)
          },

          getStrictNoSpecialCharPattern: () => '[^\,\"\'<>\%\&]+',
          getidPattern: () => '[^\,\"\'<>\%\&\\s\\\\]+',
          getPasswordPattern: () => '.{8,}',
          getEmailPattern: () => '(([^<>()\\[\\]\\\\,;:\\s@"\\.]+(\\.[^<>()\\[\\]\\\\,;:\\s@"\\.]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))',
          getEmailRegex: () => /^(([^<>()\[\]\\,;:\s@"\.]+(\.[^<>()\[\]\\,;:\s@"\.]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          getDomainPattern: () => '(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}',
          getDomainRegex: () => /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/,
          getInternalUseridPattern: () => '^[0-9A-z_$#@\\-]{0,64}$',
          stripHtmlTags: (text = '') => {
            //TODO: use sanitize-html, but requires the use of project builder (webpack, etc.)
            if (!text) return ''
            htmlStripperElement.innerHTML = String(text).replace(/<[^>]+>/gm, '')
            return htmlStripperElement.textContent
          },
          isHtmlDoc: (text = '') => {
            if (!text) return false
            return text.match(/<!DOCTYPE|<html>/gi)
          },
          htmlKeepBody: (text = '') => {
            if (!text) return ''
            return text.replaceAll('\n', '').replace(/<head>.*<\/head>/,'').replace(/<script>.*<\/script>/g,'').replace(/<!DOCTYPE html>|<html>|<\/html>/g,'').trim()
          },
          escapeHtmlEntities: (html = '') => html.replace(/[<>"'\/]/g, s => htmlEntityMap[s]),

          sortArrayByTwoProperties: function (array, property1, property2) {
            var p1Setup = property1.split(' ')
            var p1Name = p1Setup[0]
            var p1SortOrder = p1Setup.length && p1Setup[1] === 'desc' ? -1 : 1

            var p2Setup = property2.split(' ')
            var p2Name = p2Setup[0]
            var p2SortOrder = p2Setup.length && p2Setup[1] === 'desc' ? -1 : 1

            array.sort(function compare(a, b) {
              var result1 = a[p1Name] < b[p1Name] ? -1 : a[p1Name] > b[p1Name] ? 1 : 0
              result1 = result1 * p1SortOrder

              var result2 = a[p2Name] < b[p2Name] ? -1 : a[p2Name] > b[p2Name] ? 1 : 0
              result2 = result2 * p2SortOrder
              return result1 || result2
            })
          },

          toArrayById: function (tokenized) {
            if (!tokenized) {
              return []
            }
            return tokenized.split(',').map(function (elem) {
              return {
                id: elem
              }
            })
          },

          isClassicTheme: function () {
            return appConfig.theme.indexOf('classic') !== -1
          },

          isDarkTheme: function () {
            return appConfig.theme.indexOf('dark') !== -1
          },

          lineBreakToHTML: function (value) {
            return (value || '').replace(/(?:\r\n|\r|\n)/g, '<br>')
          },

          textToHtmlAlignLeft: function (data) {
            return '<div style="text-align: left;">' + self.lineBreakToHTML(data) + '</div>'
          },

          capitalizeFirstLetter: function (sentence) {
            return sentence.replace(/^\w{1}|\s+\w{1}/g, function (firstLetterOfaWord) {
              return firstLetterOfaWord.toUpperCase()
            })
          },

          jsonPrettyPrint: (obj) => {
            // see also json-pretty.less
            function replacer(match, pIndent, pKey, pVal, pEnd) {
              var key = '<span class=json-key>';
              var val = '<span class=json-value>';
              var str = '<span class=json-string>';
              var r = pIndent || '';
              if (pKey)
                r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
              if (pVal)
                r = r + (pVal[0] == '"' ? str : val) + pVal + '</span>';
              return r + (pEnd || '');
            }

            const jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{]|{},?)?$/mg;
            return JSON.stringify(obj, null, 2)
              .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
              .replace(/</g, '&lt;').replace(/>/g, '&gt;')
              .replace(jsonLine, replacer);
          }
        }
        return self
      }])
