angular.module('mosaik.directives')
  .directive('toggleSwitch',
    ['$translate', '$location', '$timeout', 'utilService',
      ($translate, $location, $timeout, utilService) => {
        return {
          restrict: 'A',
          scope: {
            default: '<',
            value: '=?'
          },
          link: (scope, element, attrs) => {
            // set text from key
            element.attr('data-on-text', $translate.instant(element.attr('data-on-text')))
            element.attr('data-off-text', $translate.instant(element.attr('data-off-text')))
            // 
            const eventName = element.attr('data-event-name')
            const selectionParamName = utilService.lastStringValueAfterDot(element.attr('value'))
            const locationParam = $location.search()[selectionParamName]

            // set default
            if (typeof scope.default !== 'boolean') {
              scope.default = false
            }

            // init value if there is a query param
            scope.value = locationParam ? true : scope.default

            // create/init element
            element.bootstrapSwitch('state', scope.value)
            // set query param
            if (scope.value) {
              $timeout(() => {
                if (selectionParamName) {
                  $location.search(selectionParamName, scope.value || null)
                  $location.replace()
                }
              })
            }

            // event listener on switch toggle
            element.on('switchChange.bootstrapSwitch', (event, state) => {
              scope.$emit(eventName, state)
              // emit data modifier
              if (selectionParamName) {
                scope.$emit('toggle-modified', { key: selectionParamName, value: state })
              } else {
                // apply data directly
                scope.value = state
              }
              // set query param
              $timeout(() => {
                if (selectionParamName) {
                  $location.search(selectionParamName, state || null)
                  $location.replace()
                }
              })
            })

            scope.$on('filter-param-clear', (event, args) => {
              if (selectionParamName && args.key === selectionParamName) {
                // if cleared, set default
                $timeout(() => {
                  $location.search(selectionParamName, args.value || null)
                  $location.replace()
                  element.bootstrapSwitch('state', args.value)
                })
              }
            })
          }
        }
      }
    ])