angular.module('mosaik.services')
  .factory('chatService',
    ['$window', '$timeout', '$rootScope', '$translate', 'translationService',
      function ($window, $timeout, $rootScope, $translate, translationService) {

        $rootScope.$on('locale-changed', (event, args) => {
          if ($window.smartsupp) {
            $window.smartsupp('language', translationService.getLocaleShort())
          }
        })

        const self = {
          start: (options, callback) => {
            // check for offline state
            const offlineUntil = $translate.instant('ChatBoxIsOfflineUntilDateTime')
            const chatboxIsOffline = $window.moment().isBefore(offlineUntil)

            if (chatboxIsOffline) {
              const fromNow = $window.moment(offlineUntil).fromNow()
              $window.Swal.fire({
                icon: 'info',
                title: $translate.instant('Offline'),
                html: `${$translate.instant('ChatBoxIsOfflineMessage')}<br><br><p><b>${$translate.instant('BackOnlineIn', { fromNow })}</b></p>`
              }).then(() => {})
              return callback()
            }

            if ($window.smartsupp) {
              self.open()
              return callback()
            }
            $window._smartsupp = $window._smartsupp || {}
            $window._smartsupp.key = 'eec0864a524bbd4eeab06d39d130bcf6b2e561c9'

            _smartsupp.offsetX = 0

            const o = $window.smartsupp = function () {
              o._.push(arguments)
            }
            o._ = []
            const firstScript = $window.document.getElementsByTagName('script')[0]
            const script = $window.document.createElement('script')
            script.id = 'chat-application-script'
            script.type = 'text/javascript'
            script.charset = 'utf-8'
            script.async = true
            script.src = '//www.smartsuppchat.com/loader.js?'
            firstScript.parentNode.insertBefore(script, firstScript)

            $window.smartsupp('language', translationService.getLocaleShort())
            $window.smartsupp('email', options.email)
            $window.smartsupp('name', options.name)
            // extra info
            $window.smartsupp('variables', {
              userid: { label: 'User ID', value: options.userid || '' },
              orgid: { label: 'Org ID', value: options.orgid || '' },
              instance: { label: 'Instance', value: options.instance || '' }
            })
            self.open()
            $timeout(() => {
              callback()
            }, 1500)
          },

          open: () => {
            $window.smartsupp(() => {
              $window.smartsupp('chat:open')
            })
          },

          close: () => {
            if (!$window.smartsupp) {
              return
            }
            $window.smartsupp('chat:close')
          },

          destroy: () => {
            if ($window.smartsupp) {
              $window.smartsupp('chat:end')
            }
            // destroy scripts and object tags
            angular.element('script#chat-application-script').remove()
            angular.element('script[src*="rec.smartlook.com"]').remove()
            angular.element('div#chat-application').remove()
            //destroy properties
            delete $window.smartsupp
            delete $window._smartsupp
          }
        }

        return self
      }
    ])
