angular.module('mosaik.services')
  .factory('keyService',
    ['$window', 
     function ($window) {
      
      return {
        setShortcut: function (shortcut, scope, handler) {
          $window.key(shortcut, scope, handler)
        },

        setScope: function (scope) {
          $window.key.setScope(scope)
        },

        deleteScope: function (scope) {
          $window.key.deleteScope(scope)
        },

        deleteShortcut: function (shortcut, scope) {
          $window.key.unbind(shortcut, scope)
        }
      }
    }]
)
