angular.module('mosaik.providers')
  .provider('config', function () {
    var self = this
    this.locale = {
      fileNameConvention: {
        prefix: '/i18n/',
        suffix: '.json'
      },
      // Replace during Gulp process by gulp-template from config/app.js file.
      locales: ["fr","en","es"],
      localesMap: {
        'en_AU': 'en',
        'en_CA': 'en',
        'en_NZ': 'en',
        'en_PH': 'en',
        'en_UK': 'en',
        'en_US': 'en',
        'fr_FR': 'fr',
        'fr_CA': 'fr',
        'en-AU': 'en',
        'en-CA': 'en',
        'en-NZ': 'en',
        'en-PH': 'en',
        'en-UK': 'en',
        'en-US': 'en',
        'fr-FR': 'fr',
        'fr-CA': 'fr',
        'es-ES': 'es',
        'es-MX': 'es'
      },
      bootstrapSelectMap: {
        'fr': 'fr_FR',
        'es': 'es_ES',
        'en': 'en_US'
      },
      default:  "fr",
      getDatatables: function (langkey) {
        return '/i18n/data-tables/' + (langkey || self.locale.default) + '.json'
      },
      getBootstrapSelect: function (langkey) {
        return '/i18n/bootstrap-select/defaults-' + self.locale.bootstrapSelectMap[langkey || self.locale.default] + '.min.js'
      }
    }

    this.intlTelInput = {
      initialCountry: 'ca',
      countrySearch: false,
      preferredCountries: ['ca', 'us'],
      utilsScript: '/public/lib/intl-tel-input/utils.js'
    }

    this.vexThemeClassName = 'vex-theme-mosaik'

    this.serviceWorker = {"active":true,"showInstallApp":false}

    this.theme = 'dark-classic'

    this.$get = function () {
      return {
        locale: self.locale,
        theme: self.theme,
        intlTelInput: self.intlTelInput,
        vexThemeClassName: self.vexThemeClassName,
        serviceWorker: self.serviceWorker
      }
    }
  })