angular.module('mosaik.services')
  .factory('translationService',
    ['$window', '$translate', '$rootScope', '$templateCache', '$http', 'localeService', 'config',
      function ($window, $translate, $rootScope, $templateCache, $http, localeService, config) {
        let _locales
        // locale value variations
        let _locale = ''
        let _localeRecaptcha = ''
        let _localeShort = ''
        // Store datatable locales dictionnaries
        const _localesDatatable = {}

        function _setCurrentLocaleVariations(langkey) {
          _locale = langkey + '_CA'
          _localeRecaptcha = (langkey === 'fr') ? langkey + '-CA' : langkey
          _localeShort = langkey
        }

        function _changeMomentLanguage(langkey) {
          $window.moment.locale((langkey === 'fr' || langkey === 'en') ? langkey + '-ca' : langkey)
        }

        function _changeNumeralLanguage(langkey) {
          $window.numeral.locale((langkey === 'fr') ? langkey + '-ca' : langkey)
        }

        function _changeTranslatorLanguage(langkey) {
          $translate.use(langkey)  // Maybe not necessary to use both... >->
          $translate.preferredLanguage(langkey) // <-
        }

        function _setSessionLocale(langkey) {
          return $http.put('/api/i18n/locale', { locale: langkey })
        }

        function _getSessionLocale() {
          return $http.get('/api/i18n/locale')
            .then(response => response.data.locale)
        }

        function _loadBootstrapSelectScript(langkey) {
          // Adding the script tag to the head as suggested
          // TODO : not clean, to change the language dynamically, the page has to be reloaded
          const head = document.getElementsByTagName('head')[0]
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.src = config.locale.getBootstrapSelect(langkey)
          // Fire the loading
          head.appendChild(script)
        }

        function _changeLanguage(newLocaleKey, callback) {
          if (!newLocaleKey) {
            newLocaleKey = $translate.preferredLanguage()
          }
          newLocaleKey = newLocaleKey.substring(0, 2)

          if (!self.isLanguageAccepted(newLocaleKey)) {
            newLocaleKey = self.getDefaultLanguage()
          }

          if (_localeShort === newLocaleKey) {
            return callback(newLocaleKey)
          }

          _changeTranslatorLanguage(newLocaleKey)
          _changeMomentLanguage(newLocaleKey)
          _changeNumeralLanguage(newLocaleKey)
          _setSessionLocale(newLocaleKey)
            .then(response => {
              _setCurrentLocaleVariations(newLocaleKey)
              _loadBootstrapSelectScript(newLocaleKey)
              $templateCache.removeAll()
              $rootScope.$broadcast('locale-changed', { locale: newLocaleKey })
              return callback(newLocaleKey)
            })
        }

        /**
         * A promise for initial setup and locale loading
         */
        const _initPromise = new Promise((resolve) => {
          Promise.all([localeService.localesCodeFormat(), _getSessionLocale()]).then(function (results) {
            _locales = results[0]
            _changeLanguage(results[1], (langkey) => resolve(langkey))
          })
        })

        const self = {
          initialize: () => _initPromise,
          getHyphensModeAsDatatableOptions: () => {
            const languages = self.getLanguages()
            const result = [
              { label: $translate.instant('none'), value: 'none' },
              { label: $translate.instant('auto'), value: 'auto' },
              { label: $translate.instant('manual'), value: 'manual' }
            ]
            return result
          },
          getLanguages: () => _locales, // must be set by a call to initialize first,
          getLanguagesAsMap: () => {
            const languages = self.getLanguages()
            const result = {}

            for (let i = 0; i < languages.length; i++) {
              result[languages[i].code] = languages[i]
            }
            return result
          },
          getLanguagesAsDatatableOptions: () => {
            const languages = self.getLanguages()
            const result = []

            for (let i = 0; i < languages.length; i++) {
              result.push({ label: $translate.instant(languages[i].code), value: languages[i].code })
            }
            return result
          },
          getDefaultLanguage: () => {
            const languages = self.getLanguages()

            for (let i = 0; i < languages.length; i++) {
              if (languages[i].isDefault) {
                return languages[i].code
              }
            }
          },
          isLanguageAccepted: langkey => {
            const languages = self.getLanguages()
            langkey = langkey.substring(0, 2)

            if (!languages) {
              return false
            }

            for (let i = 0; i < languages.length; i++) {
              if (langkey === languages[i].code) {
                return true
              }
            }
            return false
          },
          getLanguage: code => {
            const _languages = self.getLanguages()
            for (let i = 0; i < _languages.length; i++) {
              if (_languages[i].code === code) {
                return _languages[i]
              }
            }
          },
          getCurrentLanguage: () => self.getLanguage(self.getLocaleShort()),
          change: (newLocaleKey, callback) => {
            _initPromise.then(langkey => {
              _changeLanguage(newLocaleKey, callback || (() => { }))
            })
          },
          /**
           * Get the datatables i18n file, load from remote if not yet stored
           * @param {*} callback 
           */
          getDatatables: callback => {
            // check if already loaded and stored
            if (_localesDatatable && _localesDatatable[_localeShort]) {
              return callback(_localesDatatable[_localeShort])
            }
            // load from remote
            $http.get(config.locale.getDatatables(_localeShort))
              .then(response => {
                _localesDatatable[_localeShort] = response.data
                callback(_localesDatatable[_localeShort])
              })
          },

          dateToHumanLongFormat: (date) => $window.moment(date).format('LL'),
          datetimeToHumanLongFormat: (datetime) => $window.moment(datetime).format('LLLL'),
          getLocaleShort: () => _localeShort,
          getLocale: () => _locale,
          getLocaleCaptcha: () => _localeRecaptcha,
          selectLanguage: (titleKey = 'localeQuestionTitle') => {
            const inputOptions = self.getLanguages().reduce((result, locale) => {
              result[locale.code] = locale.name
              return result
            }, {})
            const localeCount = Object.keys(inputOptions).length
            const locale = self.getLocaleShort()

            const promise = new Promise(resolve => {
              // Ask the user for his/her preferred language
              Swal.fire({
                title: $translate.instant(titleKey),
                html: $translate.instant('LocaleQuestionSubtitle'),
                confirmButtonText: $translate.instant('Continue'),
                icon: 'question',
                input: localeCount > 3 ? 'select' : 'radio',
                inputValue: locale,
                inputOptions,
                inputPlaceholder: localeCount > 3 ? $translate.instant('LocaleSelectLanguage') : null,
                inputValidator: value => {
                  return new Promise(resolve => {
                    if (value) {
                      return resolve()
                    }
                    resolve($translate.instant('LocaleSelectLanguageWarn'))
                  })
                }
              }).then(result => {
                resolve(result.value)
              })
            })
            return promise
          }
        }

        return self
      }
    ])
