angular.module('mosaik.services')
  .factory('stateService',
    ['$translate',
      ($translate) => {
        return {
          getStates: () => [
            { id: 'completed', key: 'Completed' },
            { id: 'inprogress', key: 'InProgress' },
            { id: 'toredo', key: 'ToRedo' },
            { id: 'notstarted', key: 'NotStarted' }
          ],
          format: (state) => {
            let level = 'info'
            let icon = ''
            switch (state) {
              case 'ToRedo':
                level = 'warning'
                icon = 'fa-repeat'
                break
              case "Completed":
                level = 'success'
                icon = 'fa-check-circle'
                break
              case "InProgress":
                level = 'info'
                icon = 'fa-pause-circle'
                break
              case "NotStarted":
                level = 'danger'
                icon = 'fa-stop-circle'
                break
            }
            return `<span class="label-mosaik label-mosaik-${level}"><span class=\"fa fa-fw ${icon}\"></span>&nbsp;&nbsp;${$translate.instant(state)}</span>`
          },
          formatAsText: (state) => $translate.instant(state)
        }
      }]
  )