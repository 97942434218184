/*
 Select picker related directives, for use with a select picker dom obj

 Requires properties to be set:

  - last="$last" (tells the repeat directive reached the last element)
  - selection="obj.elementSelectedArray"
  - dataset="theDatasetToSelectElementFrom"

Optional properties:
  - showAllOption: boolean; Add a 'show all' option at the top of the list, which will offset other options number by +1.
  - valuePropertyName string (the name of the property to use and store in the selection object, default to "id")

 Emits a select-modified event to the parents scopes

 Ex:
      <select id="roles-selectpicker" class="selectpicker select-roles show-tick show-menu-arrow" title="{{'RolesFilterTitle' | translate}}"
       select-picker-change selection="query.roles" dataset="roles" multiple data-multiple-separator=" ">
        <option ng-repeat="role in roles track by $index" last='$last' repeat-picker value="{{role.id}}" 
        <option ng-repeat="role in roles" last='$last' repeat-picker value="{{role.id}}"
          select-picker-set-content-attr-role></option>
      </select>
*/

angular.module('mosaik.directives')
  .directive('selectPickerFilterOperatorActionsBtn',
    ['$timeout', '$location', 'utilService', '$compile', '$translate',
      function ($timeout, $location, utilService, $compile, $translate) {
        return {
          // Attributes as parameters:
          //   This element's attribute MUST be set -> param-name="your state param name from parent scope"
          //   This element's attribute CAN be optionaly set -> translate-prefix="Tag|..."
          // NB: cannot use isolated scope because this directive is used with selectPickerChange which requires an isolated scope already
          link: function (scope, element, attrs) {
            if (!attrs.paramName) {
              console.error('selectPickerFilterOperatorActionsBtn: missing paramName attribute on element')
            }
            // Action lavel value translation prefix.
            if (!attrs.translatePrefix) {
              attrs.translatePrefix = ''
            }
            var pickerData
            // Use bs-actionsbox class on top div so the bootstrap-select picker will think there is a actionbox element.
            // NB: This directive cannot be used if the "actions" option is activated on the select-picker element
            var template =
              '<div id="tagFilterOptionsWrapper" class="bs-actionsbox bs-filter-options-group">' +
              '  <label class="text-muted">{{\'FilterOptionsTitle\' | translate}}</label>' +
              '  <div class="btn-group-vertical" role="group" data-toggle="buttons" state-filter-field-group-update>' +
              '    <label class="btn btn-default active" state-filter-field state="query.' + attrs.paramName + '" param-name="' + attrs.paramName + '" ' +
              '        value="or" ng-bind-html="\'' + attrs.translatePrefix + 'FilterOptionsOR\' | translate | trust">' +
              '      <input type="radio" name="options" id="tagFilterOptionsOR" autocomplete="off" checked></input>' +
              '    </label>' +
              '    <label class="btn btn-default" state-filter-field state="query.' + attrs.paramName + '" param-name="' + attrs.paramName + '" ' +
              '        value="and" ng-bind-html="\'' + attrs.translatePrefix + 'FilterOptionsAND\' | translate | trust">' +
              '      <input type="radio" name="options" id="tagFilterOptionsAND" autocomplete="off"></input>' +
              '    </label>' +
              '  </div>' +
              '</div>'
            var currentState = 'or' // default state

            element.on('loaded.bs.select', function (e, clickedIndex, isSelected, previousValue) {
              pickerData = element.data('selectpicker')
              window.pickerData = pickerData
              var menuInner = pickerData.$menuInner
              var compiled = $compile(template)(scope)
              $timeout(function () {
                // insert the state selector element
                angular.element(compiled).insertBefore(menuInner)
                // bind click on the wrapper to block event propagation so the drop menu is not close on button click
                angular.element('#tagFilterOptionsWrapper').bind("click", function (event) {
                  event.preventDefault()
                  event.stopPropagation()
                })
                // set default separator and refresh the select picker to calculate the new height correctly
                setMultipleSeparatorAndRefresh(currentState)
              })
            })

            // update select-picker multiple-separator value based on selected state
            scope.$on('filter-param-loaded', filterParamChangeEvent)
            scope.$on('filter-param-clear', filterParamChangeEvent)
            scope.$on('state-modified', filterParamChangeEvent)

            function filterParamChangeEvent(event, stateObj) {
              if (stateObj.key !== attrs.paramName) {
                return
              }
              currentState = stateObj.value
              $timeout(function () {
                setMultipleSeparatorAndRefresh(stateObj.value)
              })
            }

            function setMultipleSeparatorAndRefresh(newState) {
              var newSeparator = ' ' + $translate.instant(newState) + ' '
              element.attr('data-multiple-separator', newSeparator)

              //the picker may not be instantiate yet
              if (!pickerData) {
                return
              }
              pickerData.options.multipleSeparator = newSeparator
              pickerData.$element.data({ 'multipleSeparator': newSeparator })
              pickerData.refresh()
            }
          }
        }
      }
    ])

angular.module('mosaik.directives')
  .directive('repeatPicker',
    ['$timeout', '$location', 'utilService',
      function ($timeout, $location, utilService) {
        return {
          scope: {
            last: '<',
            default: '<'
          },
          link: function (scope, element, attrs) {
            if (scope.last) {
              $timeout(() => {
                const picker = element.parent()
                const propertyName = picker.attr('selection') || picker.attr('dataset') || '' // todo: check if second expression can be removed
                const selectionParamName = utilService.lastStringValueAfterDot(propertyName)
                const search = $location.search()[selectionParamName]
                // set pre-selected value(s)
                // init select from query string param
                if (search) {
                  // add them to selection
                  picker.selectpicker('val', utilService.toArray(search))
                } else {
                  // or set default value if any
                  if (scope.default) {
                    picker.selectpicker('val', scope.default)
                  }
                }
                picker.selectpicker('render')
                picker.selectpicker('refresh')

                $timeout(() => scope.$emit('select-rendered', { id: picker.attr('id') }))
              })
            }
          }
        }
      }
    ])

  .directive('selectPickerChange',
    ['$timeout', '$location', 'utilService', '$translate',
      function ($timeout, $location, utilService, $translate) {
        return {
          scope: {
            selection: '=',
            dataset: '<',
            optionsIncluded: '<',
            showAllOption: '<',
            default: '<',
            valuePropertyName: '@'
          },
          link: function (scope, element, attrs) {
            const maxOptions = element.attr('data-max-options') ? parseInt(element.attr('data-max-options')) : -1
            scope.multiple = (element.attr('multiple') != null && maxOptions !== 1)
            const selectionParamName = utilService.lastStringValueAfterDot(element.attr('selection'))
            const locationParam = $location.search()[selectionParamName]
            scope.valuePropertyName = scope.valuePropertyName ? scope.valuePropertyName : 'id'

            // Init the selection object if empty
            if (!scope.selection) {
              initSelection()
            }

            scope.$watch('selection', () => {
              $timeout(() => {
                const pickerVal = element.selectpicker('val')
                // do not apply on array for now
                if (Array.isArray(scope.selection) || Array.isArray(pickerVal)) return
                if (scope.selection != null && pickerVal !== scope.selection.toString()) {
                  element.selectpicker('val', scope.selection)
                }
              })
            })

            // init selection with the location param value (if any)
            if (locationParam) {
              if (scope.multiple) {
                utilService.arrayPushTo(utilService.toArray(locationParam), scope.selection)
              } else {
                scope.selection = locationParam
              }
            }
            // auto search input when many elements
            setLiveSearch()

            // event to listen to when dataset is changed
            scope.$on('picker-dataset-change', (event, pickerName) => {
              if (pickerName !== selectionParamName) {
                return
              }
              $timeout(() => {
                setLiveSearch()
                if (scope.optionsIncluded) {
                  loadDatasetManualOptions()
                } else {
                  element.selectpicker('refresh')
                }
              })              
            })

            // listen to selection change from params
            scope.$on('filter-param-loaded', filterParamChangeEvent)
            scope.$on('filter-param-clear', filterParamChangeEvent)

            function filterParamChangeEvent(event, args) {
              if (args.key !== selectionParamName) {
                return
              }
              // cast value(s) as an array if not, for easier processing
              const values = scope.multiple ? utilService.toArray(args.value) : args.value
              const isEmpty = scope.multiple ? values.length === 0 : !values
              // no need to reset if the params are being loaded (can cause issue if obj is not ready yet)
              if (event.name === 'filter-param-loaded' && isEmpty) {
                return
              }
              // make sure the location is clear
              $location.search(selectionParamName, !isEmpty ? values : null)
              $location.replace()
              // adjust the picker's selection
              $timeout(() => {
                if (!isEmpty) {
                  element.selectpicker('val', values)
                } else {
                  initSelection()
                  element.selectpicker('deselectAll')
                }
                element.selectpicker('render')
              })
            }

            function setLiveSearch() {
              if (scope.dataset.length >= 10) {
                element.attr('data-live-search', 'true')
                element.attr('data-live-search-normalize', 'true')
                element.attr('data-live-search-style', 'containsAll')
              }
            }

            // Default number of item to display is set to auto
            if (!element.attr('data-size')) {
              element.attr('data-size', 'auto')
            }
            // default width is 100%
            if (!element.attr('data-width')) {
              element.attr('data-width', '100%')
            }
            // default width is 100%
            if (!element.attr('data-window-padding')) {
              element.attr('data-window-padding', '[62, 0, 62, 0]')
            }

            element.on('changed.bs.select', function (event, clickedIndex, isSelected, previousValue) {
              if (clickedIndex == null) { // no info on the selection, must be stopped
                return
              }
              $timeout(() => {
                let paramValue
                const offsetIndex = clickedIndex - (scope.showAllOption ? 1 : 0)

                // set the selection in object
                if (isSelected) {
                  if (offsetIndex >= 0) {
                    update(scope.dataset[offsetIndex], element)
                  } else {
                    clearSelection(element)
                  }
                } else {
                  remove(scope.dataset[offsetIndex], element)
                }

                // set query string param
                if (scope.multiple) {
                  paramValue = scope.selection.map(element => element)
                } else {
                  paramValue = scope.selection
                }

                if (attrs.doNotTrack != null) {
                  return
                }
                $location.search(selectionParamName, paramValue ? paramValue : null)
                $location.replace()
              })
            })

            function update(obj, element) {
              const id = obj[scope.valuePropertyName].toString()

              if (scope.multiple) {
                // array
                // add if not already selected
                if (scope.selection.indexOf(id) === -1) {
                  scope.selection.push(id)
                }
              } else {
                // string
                scope.selection = id
              }
              $timeout(() => {
                scope.$emit('select-modified', { id: element.attr('id'), selected: obj, value: element.val(), selection: scope.selection })
              })
            }

            function initSelection() {
              scope.selection = scope.multiple ? [] : ''
            }

            function clearSelection(element) {
              initSelection()
              $timeout(() => {
                scope.$emit('select-modified', { id: element.attr('id'), selected: null, value: element.val(), selection: scope.selection })
              })
            }

            function remove(obj, element, options) {
              if (scope.multiple) {
                const idx = scope.selection.indexOf(obj[scope.valuePropertyName].toString())
                if (idx !== -1) {
                  scope.selection.splice(idx, 1)
                }
              } else {
                scope.selection = ''
              }
              $timeout(() => scope.$emit('select-modified', {
                id: element.attr('id'),
                selected: obj,
                value: element.val(),
                removed: true,
                selection: scope.selection
              }))
            }

            if (scope.optionsIncluded) {
              loadDatasetManualOptions()
            }

            function loadDatasetManualOptions() {
              // render options manually
              const picker = element
              // generate and add options
              const showAllOption = scope.showAllOption ? `<option value="ShowAll">${$translate.instant('ShowAll')}</option>` : ''
              const options = scope.dataset.reduce((result, item) => result + item.option, '')
              element[0].innerHTML = showAllOption + options

              $timeout(() => {
                const search = $location.search()[selectionParamName]
                // set pre-selected value(s)
                // init select from query string param
                if (search) {
                  // add them to selection
                  picker.selectpicker('val', utilService.toArray(search))
                } else {
                  // or set default value if any
                  if (scope.default) {
                    picker.selectpicker('val', scope.default)
                  }
                }
                picker.selectpicker('render')
                picker.selectpicker('refresh')

                $timeout(() => scope.$emit('select-rendered', { id: picker.attr('id') }))
              })
            }

            scope.$on('select-rendered', (event, args) => {
              // nothing yet
            })
          }
        }
      }
    ])
