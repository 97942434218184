angular.module('mosaik.controllers')
  .controller('dashboardOrganizationController',
    ['$scope', '$state', '$stateParams', '$interpolate', 'sessionState', 'groups', '$translate', '$window', '$timeout', 'datatablesHelper', 'utilService', 'organizationService',
      function ($scope, $state, $stateParams, $interpolate, sessionState, groups, $translate, $window, $timeout, datatablesHelper, utilService, organizationService) {
        // access rights
        $scope.dataReady = false
        $scope.canAccessDashboard = sessionState.canAccessDashboard
        $scope.isRoot = sessionState.isRoot
        // parameters
        const tableid = '#datatables-organization'
        const tableElement = angular.element(tableid)
        const api = {
          url: '/admin-api/organization',
          readType: 'POST'
        }
        $scope.queryHidden = {
          orgid: sessionState.orgidSelector
        }
        $scope.queryDefault = {
          state: 'active',
          subscribed: 'all'
        }
        $scope.query = {
          state: '',
          subscribed: '',
          groups: [],
          search: ''
        }
        // data
        $scope.groups = groups
        const groupsAsObjects = utilService.mapObjectsByKey(groups)
        // editors
        let editorAddress
        let editorTenantid
        // DT table
        $scope.table
        // others
        $scope.orgCount = {
          count: 0
        }

        $timeout(() => datatablesHelper.init($scope, tableid, api, '', initializeTable))

        function initializeTable() {
          editorAddress = datatablesHelper.createEditor(angular.merge(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              name: 'id',
              type: 'hidden',
            }, {
              label: $translate.instant('AddressHint'),
              name: 'hint',
              type: 'hint'
            }, {
              label: $translate.instant('CivicNumber'),
              name: 'civicNumber',
              attr: {
                required: true,
                maxlength: 100,
                name: 'civicNumber'
              }
            }, {
              label: $translate.instant('StreetType'),
              name: 'streetType',
              attr: {
                maxlength: 100,
                name: 'streetType'
              }
            }, {
              label: $translate.instant('StreetName'),
              name: 'streetName',
              attr: {
                required: true,
                maxlength: 300,
                name: 'streetName'
              }
            }, {
              label: $translate.instant('PostalCode'),
              name: 'postalCode',
              attr: {
                required: true,
                maxlength: 40,
                name: 'postalCode'
              }
            }, {
              label: $translate.instant('City'),
              name: 'city',
              attr: {
                required: true,
                maxlength: 300,
                name: 'city'
              }
            }, {
              label: $translate.instant('Province'),
              name: 'province',
              attr: {
                required: true,
                maxlength: 300,
                name: 'province'
              }
            }, {
              label: $translate.instant('Country'),
              name: 'country',
              attr: {
                required: true,
                maxlength: 300,
                name: 'country'
              }
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: api.url + '/address/_id_'
              }
            },
            i18n: {
              edit: {
                title: $translate.instant('AddAddress')
              }
            }
          }))

          editorAddress.on('preSubmit', (e, data, action) => datatablesHelper.onPreSubmit(action, editorAddress))

          editorTenantid = datatablesHelper.createEditor(angular.merge(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              name: 'id',
              type: 'hidden',
            }, {
              label: $translate.instant('DomainName'),
              name: 'domainName',
              attr: {
                maxlength: 255,
                pattern: utilService.getDomainPattern(),
                name: 'domainName'
              }
            }, {
              label: $translate.instant('Tenantid'),
              name: 'ssoTenantid',
              type: 'textAction',
              actionTitle: $translate.instant('GetTenantid'),
              onAction: (input, editor) => {
                // get tenant id from domain name
                const domainName = editor.val('domainName')
                const domainNameField = editor.field('domainName')
                const ssoTenantidField = editor.field('ssoTenantid')
                // try get tenant id
                organizationService.fetchTenantid(domainName, domainNameField, ssoTenantidField, editor)
                  .then(tenantid => {}, error => {})
              },
              attr: {
                maxlength: 128,
                name: 'ssoTenantid'
              }
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: api.url + '/ssoTenantid/_id_'
              }
            },
            i18n: {
              edit: {
                title: $translate.instant('AddTenantid')
              }
            }
          }))

          editorTenantid.on('preSubmit', (e, data, action) => datatablesHelper.onPreSubmit(action, editorTenantid))

          const hiddenButtons = ['create', 'edit', 'remove']
          const extraButtons = [{
            extend: 'edit',
            editor: editorAddress,
            text: $translate.instant('EditAddress')
          }, {
            extend: 'edit',
            editor: editorTenantid,
            text: $translate.instant('EditTenantid')
          }]
          const exportOptions = { columns: [0, 2, 3, 1, 6, 7, 8, 9, 10, 12, 13, 14] }
          const exportMessage = $translate.instant('Organizations')

          const datatableParams = angular.extend(datatablesHelper.getDatatablesParams({
            hiddenButtons,
            extraButtons,
            exportOptions,
            exportMessage,
            extraDTOptions: { serverSide: true },
            excelForceStrCols: ['B', 'C']
          }), {
            columns: [
              { data: 'id' },
              {
                data: 'virtualPreferredName',
                searchable: false,
                orderable: false,
                className: 'all',
                render: (data, type, full) => datatablesHelper.sanitizeEscape(data ? data : full.preferredName) + ' <small class="text-id">' + datatablesHelper.sanitizeEscape(full.internalOrgid) + '</small>'
              },
              { 
                data: 'internalOrgid', 
                visible: false, 
                className: 'never' ,
                render: datatablesHelper.sanitizeEscape
              },
              {
                data: 'businessNumber',
                responsivePriority: 10001,
                render: data => data ? datatablesHelper.sanitizeEscape(data) : ''
              },
              { 
                data: 'name', 
                visible: false, 
                className: 'never',
                render: datatablesHelper.sanitizeEscape
              },
              { 
                data: 'preferredName', 
                visible: false, 
                className: 'never',
                render: datatablesHelper.sanitizeEscape
              },
              { // org groups
                data: 'groups',
                searchable: false,
                orderable: false,
                render: datatablesHelper.renderGroups
              },
              { data: 'activeUserCount', searchable: false },
              { data: 'completedSessionCount' },
              {
                data: 'createdAt',
                render: datatablesHelper.renderDateHuman
              },
              {  // column 10
                data: 'lastSubscribedAt',
                render: datatablesHelper.renderDateTimeHuman
              },
              { // column 11
                data: 'lastAccessAt',
                orderData: 12,
                render: data => data ? $window.moment(data).fromNow() : ''
              },
              { // 12
                data: 'lastAccessAt',
                visible: false,
                className: 'never',
                searchable: false,
                render: data => data ? $window.moment(data).format('YYYY/MM/DD HH:mm') : ''
              },
              { // 13
                data: 'active',
                className: 'all',
                render: datatablesHelper.renderActiveInactive
              },
              { // 14
                data: 'subscribed',
                className: 'all',
                render: datatablesHelper.renderYesNoDefaultWithIcons
              },
              { // render action row menu
                searchable: false,
                orderable: false,
                data: (row, type) => {
                  template = `
                    <div class="dropdown dt-buttons btn-group dt-button-actions">
                      <button type="button" class="btn btn-defaut dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-h"></i> 
                      </button>
                      <ul class="dropdown-menu dropdown-menu-right">
                        <li class="dt-button"><a class="show-invites" data-id="${row.id}">{{"ShowInvites" | translate}}</a></li>
                        <li class="dt-button"><a class="show-users" data-id="${row.id}">{{"ShowUsers" | translate}}</a></li>
                        <li role="separator" class="divider"></li>
                        <li class="dt-button"><a class="show-completed-sessions" data-id="${row.id}">{{"ShowCompletedSessions" | translate}}</a></li>
                        <li class="dt-button"><a class="show-inprogress-sessions" data-id="${row.id}">{{"ShowInProgressSessions" | translate}}</a></li>
                        <li class="dt-button"><a class="show-time-usage" data-id="${row.id}">{{"ShowTimeUsage" | translate}}</a></li>
                        <li class="dt-button"><a class="show-priority-coverage" data-id="${row.id}">{{"ShowPriorityCoverage" | translate}}</a></li>
                        <li role="separator" class="divider"></li>
                        <li class="dt-button"><a class="edit-address" data-id="${row.id}">{{"EditAddress" | translate}}</a></li>
                        <li class="dt-button"><a class="edit-tenantid" data-id="${row.id}">{{"EditTenantid" | translate}}</a></li>
                      </ul>
                    </div>`
                  const compiled = ($interpolate(template)($scope))
                  return compiled
                }
              }
            ],
            order: [11, 'desc']
          })
          datatablesHelper.createAndSetTable(datatableParams).ready(() => {
            $timeout(() => {
              $scope.orgCount.count = $scope.table.settings()[0].fnRecordsTotal()
              $scope.dataReady = true
            })
          })
        }

        function getSelectedData(selectedRows) {
          return selectedRows.row(selectedRows.indexes()[0]).data()
        }

        function showInvites(selectedRows) {
          var data = getSelectedData(selectedRows)
          $state.go('dashboardInviteStatus', { organizations: data.id }, { reload: 'secure' })
        }

        function showUsers(selectedRows) {
          var data = getSelectedData(selectedRows)
          $state.go('dashboardUser', { organizations: data.id }, { reload: 'secure' })
        }

        function showCompletedSessions(selectedRows) {
          var data = getSelectedData(selectedRows)
          $state.go('dashboardSessionCompleted', { organizations: data.id }, { reload: 'secure' })
        }

        function showInProgressSessions(selectedRows) {
          var data = getSelectedData(selectedRows)
          $state.go('dashboardSessionInProgress', { organizations: data.id }, { reload: 'secure' })
        }

        function showTimeUsage(selectedRows) {
          var data = getSelectedData(selectedRows)
          $state.go('dashboardSessionDailyTimeLog', { organizations: data.id }, { reload: 'secure' })
        }

        function showPriorityCoverage(selectedRows) {
          var data = getSelectedData(selectedRows)
          $state.go('dashboardContentCoverage', { organization: data.id }, { reload: 'secure' })
        }

        function editAddress(selectedRows) {
          var data = getSelectedData(selectedRows)

        }

        function doAction(scope, fnAction) {
          datatablesHelper.selectClosestRow($scope.table, datatablesHelper.getParentDiv(scope))
          fnAction($scope.table.rows({ selected: true }))
        }

        tableElement.on('click', 'a.show-invites:not(.disabled)', function (e) {
          doAction(this, showInvites)
        })

        tableElement.on('click', 'a.show-users:not(.disabled)', function (e) {
          doAction(this, showUsers)
        })

        tableElement.on('click', 'a.show-completed-sessions:not(.disabled)', function (e) {
          doAction(this, showCompletedSessions)
        })

        tableElement.on('click', 'a.show-inprogress-sessions:not(.disabled)', function (e) {
          doAction(this, showInProgressSessions)
        })

        tableElement.on('click', 'a.show-time-usage:not(.disabled)', function (e) {
          doAction(this, showTimeUsage)
        })

        tableElement.on('click', 'a.show-priority-coverage:not(.disabled)', function (e) {
          doAction(this, showPriorityCoverage)
        })

        tableElement.on('click', 'a.edit-address:not(.disabled)', function (e) {
          editorAddress.edit(datatablesHelper.selectAndGetClosestRow($scope.table, this).indexes(), true, {
            title: editorAddress.i18n.edit.title,
            buttons: editorAddress.i18n.edit.submit
          })
        })

        tableElement.on('click', 'a.edit-tenantid:not(.disabled)', function (e) {
          editorTenantid.edit(datatablesHelper.selectAndGetClosestRow($scope.table, this).indexes(), true, {
            title: editorTenantid.i18n.edit.title,
            buttons: editorTenantid.i18n.edit.submit
          })
        })
      }
    ])
