angular.module('mosaik.services')
  .factory('datatablesHelper',
    ['$translate', 'translationService', '$timeout', '$window', 'httpService', 'formService', 'sessionStateService', 'fileService', 'notificationHelper', '$location', 'labelService', 'utilService', '$filter', 'timeService', '$sanitize', 'datatablesExportService',
      function ($translate, translationService, $timeout, $window, httpService, formService, sessionStateService, fileService, notificationHelper, $location, labelService, utilService, $filter, timeService, $sanitize, datatablesExportService) {
        let _i18n = undefined
        let _tableid = ''
        let _ajax = {}
        let _param = ''
        let _scope
        let _tableParams
        let tableElement

        const self = {
          init: (scope, tableid, ajax, param, next) => {
            // clean previous table if any
            if (_scope && _scope.table) {
              const editor = _scope.table.editor()
              if (editor) {
                editor.destroy()
              }
              const buttons = _scope.table.buttons()
              if (buttons) {
                buttons.destroy()
              }
              // remove from memory
              _scope.table.destroy(true)
            }
            // start initilization
            _tableid = tableid
            _scope = scope
            _scope.show = false
            // init empty query params containers if none
            if (!_scope.queryDefault) {
              _scope.queryDefault = {}
            }
            if (!_scope.queryHidden) {
              _scope.queryHidden = {}
            }
            if (!_scope.query) {
              _scope.query = {}
            }
            tableElement = angular.element(_tableid)
            // init query empty obj for use when formating url data if none created
            if (!_scope.query) {
              _scope.query = {}
            }
            if (!_scope.queryHidden) {
              _scope.queryHidden = {}
            }
            // ajax object formatting
            if (typeof ajax === 'string') {
              ajax = {
                url: ajax
              }
            }
            _ajax = {} // ajax store
            _ajax.readUrl = ajax.readUrl || ajax.url
            _ajax.readType = ajax.readType || 'GET'

            _ajax.createUrl = ajax.createUrl || ajax.url
            _ajax.createType = ajax.createType || 'POST'

            _ajax.updateUrl = ajax.updateUrl || ajax.url
            _ajax.updateType = ajax.updateType || 'PUT'

            _ajax.deleteUrl = ajax.deleteUrl || ajax.url
            _ajax.deleteType = ajax.deleteType || 'DELETE'

            _ajax.dataSrc = ajax.dataSrc
            _ajax.data = ajax.data

            // execute rest after next apply
            $timeout(() => {
              // save default values if any is given in the query object (init the _scope.query with the preferred default values)
              self.copyObjectTo(_scope.query, _scope.queryDefault, { noOverride: true })
              // set ajax data "set" callback
              if (!_ajax.data) {
                _ajax.data = self.onAjaxData
              }
              // additional uri params (not query)
              _param = param ? '/' + param : ''

              _scope.$on('filter-reset', self.resetFilters)

              translationService.getDatatables(i18n => {
                _i18n = i18n ? i18n : undefined
                $timeout(() => _scope.show = true, 50)
                next()
              })
            })
          },

          /**
           * On before ajax call to add customFilters params to the data (Which is sent in request's body or query)
           * @param {*} data
           */
          onAjaxData: data => {
            const params = Object.assign({}, _scope.query, _scope.queryHidden)
            // first set the "special" search string value which is not stored at the same place as the other filters values
            if (params.search && data.search) {
              data.search.value = params.search
            }
            // set other filters
            self.copyObjectTo(data, params, { ignores: 'search' })
          },

          resetFilters: (event, args) => {
            if (!_scope.table) {
              return
            }
            const stateData = _scope.table.state()
            // reset the values:
            // Clear the query filter values
            for (const paramName in _scope.query) {
              const currentParamValue = _scope.query[paramName]
              let newValue
              // Reset date range as moment obj
              if (paramName === 'dateRange') {
                newValue = {
                  start: null,
                  end: null
                }
                for (const property in currentParamValue) {
                  newValue[property] = _scope.queryDefault[paramName] && _scope.queryDefault[paramName][property] ? $window.moment(_scope.queryDefault[paramName][property]) : null
                }
              } else {
                if (Array.isArray(currentParamValue)) {
                  newValue = _scope.queryDefault[paramName] ? _scope.queryDefault[paramName] : []
                } else {
                  newValue = _scope.queryDefault[paramName] !== undefined ? _scope.queryDefault[paramName] : ''
                }
              }
              _scope.query[paramName] = newValue
              self.setSearchValue(paramName, newValue)
              _scope.$broadcast('filter-param-clear', { key: paramName, value: newValue })
            }
            // change table page length to default if too much
            let pageLen = _scope.table.page.len()
            if (pageLen === -1 || pageLen > 50) {
              _scope.table.page.len(_tableParams.lengthMenu[0][0] || 10)
            }
            // reset ordering
            _scope.table.order(_tableParams.order)
            // reste search
            _scope.table.search(_scope.query['search'] || '')
            // clear the stored state, may not be necessary (see doc why, need to reload the page for it to take effect)
            _scope.table.state.clear()
            // reload data
            self.reload()
          },

          setSearchValueAndBroadcast: (key, value) => {
            self.setSearchValue(key, value)
            _scope.$broadcast('filter-param-loaded', { key, value })
          },

          setSearchValue: (key, value) => {
            if (typeof value === 'object') {
              if (Array.isArray(value)) {
                // array's value must be string, 
                // array may contain object with an id property or just string; reduce it to strings
                return self._setSearchValue(key, utilService.toArray(value))
              } else {
                // must be object of string property/value
                let propertyValue
                for (const property in value) {
                  // store moment obj as date string
                  propertyValue = value[property] instanceof $window.moment ? value[property].format('YYYY-MM-DD') : value[property]
                  self._setSearchValue(key + '.' + property, propertyValue)
                }
              }
              return
            }
            self._setSearchValue(key, value)
          },

          _setSearchValue: (key, value) => {
            $location.search(key, value ? value : null)
            $location.replace()
          },

          /**
           * Convert a MomentJS date to a string
           * @param {Moment} momentDate Date value as an instance of Moment
           * @returns {String} Date as a string YYYY-MM-DD
           */
          formatDate: momentDate => momentDate instanceof $window.moment && momentDate.isValid() ? momentDate.format(timeService.defaultFormat) : null,

          /**
           * 
           * @param {*} data 
           * @param {*} params 
           * @param {*} options 
           * @param {String|[String]} options.ignores Properties to ignore during copy
           * @param {Boolean} noOverride Do not override with value from params if a value already exists in data. Default false.
           */
          copyObjectTo: (data, params, options) => {
            options = options ? options : {}
            options.ignores = options.ignores ? (Array.isArray(options.ignores) ? options.ignores : [options.ignores]) : []
            options.noOverride = options.noOverride || false

            // Set the query search values, do not set empty values
            for (const param in params) {
              const value = params[param]
              // check if ignoring the property
              if (options.ignores.indexOf(param) != -1) {
                continue
              }
              // is array
              if (Array.isArray(value)) {
                // on noOverride, only override when no value
                if (options.noOverride && data[param] && data[param].length !== 0) {
                  continue
                }
                if (value.length !== 0) {
                  data[param] = utilService.toArray(value)
                }
                continue
              }
              // dateRange special object
              // Contains "start" and "end" Moment objects if param name is "dateRange"
              // params.dateRange = { start: instanceof moment, end: instanceof moment }
              // param.dateRange.end can be null if start has a valid date.
              if (param === 'dateRange') {
                if (!data.dateRange) data.dateRange = {}
                const { start: newStart, end: newEnd } = value ? value : {}
                const { start, end } = data.dateRange

                data.dateRange.start = options.noOverride && start ? start : self.formatDate(newStart)
                if (options.noOverride && start) {
                  data.dateRange.end = end
                } else {
                  data.dateRange.end = newEnd ? self.formatDate(newEnd) : end
                }
                continue
              }
              // is primitive
              if (value) {
                // on noOverride, only override when no value
                if (options.noOverride && data[param]) {
                  continue
                }
                data[param] = value
              }
            }
          },

          i18n: () => _i18n,

          createEditor: (params) => {
            const editor = new $window.DataTable.Editor(params)
            return self.setEditorDefaultHandlers(editor, { biggerModalForm: params.biggerModalForm })
          },

          /**
           * Get Editor default params
           * @param {Object} options Default to {}. 
           *                           closeOnBackground: Boolean, default true
           *                           closeOnEsc: Boolean, default true
           * @returns 
           */
          getEditorParams: (options = {}) => {
            const params = {
              i18n: _i18n,
              ajax: {
                create: {
                  type: _ajax.createType, // default to POST, see above
                  url: _ajax.createUrl
                },
                edit: {
                  type: _ajax.updateType, // default to PUT, see above
                  url: _ajax.updateUrl + '/_id_'
                },
                remove: {
                  type: _ajax.deleteType, // default to DELETE, see above
                  url: _ajax.deleteUrl + '/_id_',
                  deleteBody: false // Do not delete the request body on delete, the server may ignore it. Only the ids in the url are required for a DELETE (no query nor body)
                }
              },
              table: _tableid,
              idSrc: 'id'
            }
            params.formOptions = {
              main: {
                onBackground: options.closeOnBackground === false ? 'none' : 'close',
                onEsc: options.closeOnEsc === false ? 'none' : 'close',
              }
            }
            return params
          },

          setEditorDefaultHandlers: (editor, options = {}) => {
            for (const handlerName in self.editorDefaultHandlers) {
              editor.on(handlerName, self.editorDefaultHandlers[handlerName])
            }
            if (options.biggerModalForm) {
              editor.on('open', self.editorConditionalHandlers['open'])
            }
            return editor
          },

          editorDefaultHandlers: {
            submitUnsuccessful: function (e, json) {
              // Always redirect on 401 "not authorized"
              if ((e.status || json.status) === 401) {
                sessionStateService.validSessionOrRedirect()
              }
              this.error(json.error || json.message)
            },
            submitError: function (e, xhr) {
              if (this.display()) {
                // show the error on the visible form
                this.error(notificationHelper.errorMessageFrom(xhr))
              } else {
                // show error user notification box
                notificationHelper.failureFrom(xhr)
              }
            }
          },

          editorConditionalHandlers: {
            open: () => {
              // Change some classes for bigger modal layout
              angular.element('.modal-dialog').addClass('modal-lg')
              angular.element('div.DTE label[data-dte-e="label"]').removeClass('col-lg-4').addClass('col-lg-2')
              angular.element('div.DTE div[data-dte-e="input"]').removeClass('col-lg-8').addClass('col-lg-10')
            }
          },

          /**
           * CTA to download table data from server-side as formatted file.
           * 
           * @param {*} dt DataTable object
           * @param {string} format 
           */
          exportTableDataAs: (dt, format = 'xlsx') => {
            const settings = dt.settings()[0]
            const data = _fnAjaxParameters(settings)
            const url = `${_ajax.readUrl}/download/${format}`
            const method = _ajax.readType

            // format data object
            self.onAjaxData(data)
            //delete data.columns
            //delete data.order
            //delete data.customFilters
            //delete data.select
            //delete data.childRows

            // show waiting alert
            const swal = $window.Swal.fire({
              title: $translate.instant('Processing'),
              icon: 'info',
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didOpen: () => $window.Swal.showLoading()
            })

            // call for download
            httpService.custom({
              url,
              method,
              data,
              responseType: 'blob'
            })
              .then(response => {
                if (!response) return
                fileService.showDownload(response, 'FileReady', 'xlsx')
              })
              .catch(error => {
                notificationHelper.failureFromBlob(error)
              })
              .finally(() => swal.close())
          },

          /**
           * CTA action callback for export buttons
           * @param {*} e 
           * @param {*} dt 
           * @param {*} node 
           * @param {*} config 
           * @param {*} cb 
           * @param {*} button Button instance
           * @param {*} buttonAction button action from DT declaration
           */
          exportButtonAction: function (e, dt, node, config, cb, button, buttonAction) {
            // Do custom processing
            const pageLen = dt.page.len()
            const serverSide = dt.settings()[0].oFeatures.bServerSide
            const body = $(dt.body())
            const responsiveSettings = dt.settings()[0].responsive
            const setFullDataState = (isActive, pageLen) => {
              dt.page.len(pageLen)
              if (responsiveSettings) {
                responsiveSettings.c._skipResize = isActive // Deactivate or activate responsiveness
                responsiveSettings.c.auto = !isActive
              }
            }

            if (serverSide && pageLen !== -1 && config.exportOptions && config.exportOptions.loadFullData) {
              try {
                // reload the data with no page length so all the data is loaded
                body.hide() // hide the table body so the browser won't render it again
                setFullDataState(true, -1) // set to load all data
                // Show process message
                const swal = $window.Swal.fire({
                  icon: 'info',
                  title: $translate.instant('ExportData'),
                  html: $translate.instant('Loading'),
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => $window.Swal.showLoading()
                })

                dt.ajax.reload(json => { // reload full data
                  // Data loaded
                  swal.update({ html: $translate.instant('Formatting') })
                  $window.Swal.showLoading()

                  buttonAction.call(button, e, dt, node, config, () => {
                    // after action
                    // replace pagelen, reload the data with previous pagelen and show the body again
                    setFullDataState(false, pageLen)
                    dt.ajax.reload() // reload data with shorter pagelen
                    setTimeout(() => { // redraw with a delay
                      dt.draw()
                      body.show()
                    }, 500)
                    cb()
                    // close process message
                    swal.close()
                  })
                })
              } catch (error) {
                // show error message
                swal.close()
                $window.Swal.fire({
                  title: $translate.instant('AnErrorOccured'), 
                  html: error.message, 
                  icon: 'error'
                })
                setFullDataState(false, pageLen) // make sure data state is set to what is was
              }
            } else {
              buttonAction.call(button, e, dt, node, config, cb)
            }
          },

          /** 
           * Wrapper for self._getDatatablesParams to structure parameters
           * @param {*} options Object optional
           * @param {*} options.editor DTEditor object optional
           * @param {[string]} options.hiddenButtons Array of string optional list of which default DT Buttons to hide ['create', 'edit', 'remove']
           * @param {*} options.buttonActions Optional action functions override for default DT Buttons { create: createFn, edit: editFn, remove: removeFn }
           * @param {*} options.extraButtons Array of DTButton Objects optional
           * @param {*} options.exportOptions Object optional DTButton Export options
           * @param {string} options.exportMessage String optional Export title
           * @param {*} options.extraDTOptions Object optional extra Datatable options to format the datatables (see DT docs)
           * @param {boolean} options.filteringInput Boolean optional, default to true, only applies if serverSide is false, show the default filtering input box when serverSide is off
           * @param {boolean} options.serverSideExport Boolean optional. Default false. Use server-side export functions for the export buttons 
           * @param {function} options.customizeExcel function optional. ExcelButton customize function callback. See DT doc.
           * @param {[string]} options.excelForceStrCols Array of string optional. Force string format on cells for given Excel columns; must be letters.
           */
          getDatatablesParams: (options) => {
            options = options ? options : {}
            const editor = options.editor ? options.editor : null
            const hiddenButtons = options.hiddenButtons ? options.hiddenButtons : []
            const buttonActions = options.buttonActions ? options.buttonActions : {}
            const extraButtons = options.extraButtons ? options.extraButtons : []
            const exportOptions = options.exportOptions ? options.exportOptions : {}
            const exportMessage = options.exportMessage ? options.exportMessage + ' - ' : ''
            const extraDTOptions = options.extraDTOptions ? options.extraDTOptions : {}
            const filteringInput = options.filteringInput != null ? options.filteringInput : true
            const serverSideExport = options.serverSideExport != null ? options.serverSideExport : false
            const stateDuration = options.context === 'console' ? 129600 : 360 // 36h for console, else 6 minutes default state duration
            const lengthMenu = options.lengthMenu ? options.lengthMenu : [[10, 25, 50, -1], [10, 25, 50, $translate.instant('AllOf')]]
            const customizeExcel = options.customizeExcel
            const excelForceStrCols = options.excelForceStrCols
            const layout = {
              top: 'info',
              topStart: null,
              topEnd: null,
              bottomStart: null,
              bottomEnd: null,
              bottom: 'buttons',
              bottom2: {
                pageLength: {
                  menu: lengthMenu
                }
              },
              bottom3: {
                paging: {
                  buttons: 7,
                }
              }
            }

            const result = _tableParams = {
              language: _i18n,
              ajax: {
                url: _ajax.readUrl + _param,
                data: _ajax.data,
                dataSrc: _ajax.dataSrc,
                type: _ajax.readType,
                error: xhr => {
                  // denied
                  if (xhr.status === 401) {
                    sessionStateService.validSessionOrRedirect()
                  }
                  // aborted (a new request may have cancelled this one, simply ignore tihs one)
                  if (xhr.status === 0) {
                    return
                  }
                  // other error, notify the user
                  notificationHelper.failureFrom(xhr)
                }
              },
              layout,
              lengthMenu, // save for reference (ex: in resetFilters)
              pageLength: lengthMenu[0][0],
              select: 'single',
              responsive: true,
              stateSave: true,
              stateDuration,
              ordering: true,
              processing: true,
              buttons: [],
              stateSaveParams: (settings, data) => {
                // manage the filters
                // Save the current query filter params to the datatable state storage element as "customFilters" property
                // Except the "search" input string which already belong to already existing "search.search" property
                data.customFilters = {}

                for (const param in _scope.query) {
                  const value = _scope.query[param]
                  if (param === 'search' && value != null && data.search) {
                    // automatically saved in data.search.search property, no need to to anything ?
                    data.search.search = value
                    continue
                  }
                  if (param === 'dateRange') {
                    const { start, end } = value
                    dateRange = data.customFilters.dateRange = {}
                    dateRange.start = self.formatDate(start)
                    dateRange.end = self.formatDate(end)
                  } else {
                    data.customFilters[param] = value
                  }
                  self.setSearchValue(param, data.customFilters[param])
                }

                data.userid = sessionStateService.currentSessionSync().id
              },
              stateLoadParams: (settings, data) => {

                // private helpers for the search string value
                const _getSearchValue = searchObj => searchObj ? searchObj.search : ''
                const _setSearchValue = (searchObj, value) => searchObj ? searchObj.search = value : null

                // Reject old data if expired
                const duration = settings.iStateDuration
                const dataExpired = (duration > 0 && data.time < Date.now() - (duration * 1000))

                // pageLength override:
                if (extraDTOptions.pageLength) {
                  data.length = extraDTOptions.pageLength
                }
                // Load the query params:
                // -1- Can be given in the url location search string 
                // -2- or already stored in the local storage customFilter property
                // -3- Use the default given value from the _scope
                // "1" has priority over "2"
                // But always load "2" anyway (if any is stored and not expired)
                const queryParams = $location.search()
                const overrideLocalStorage = Object.keys(queryParams).length !== 0

                // saved state must be from the same userid and not expired
                if (data.userid !== sessionStateService.currentSessionSync().id || dataExpired) {
                  data = { search: {}, customFilters: {} }
                }
                // #--- Query filters:
                // update query filter values if a key correponds and has a value
                // Set the query search values
                // ---
                // 1- first filter: load the "search" string value from data.search local storage
                // ---
                if (queryParams.search != null) {
                  // -1- override with given query param (from location.search) value
                  _setSearchValue(data.search, queryParams.search)
                } else {
                  // -2- use already stored value (from localStorage), by not touching it, just set to default if null
                  // NB: set to empty if we need to override localStorage
                  if (_getSearchValue(data.search) == null || overrideLocalStorage) {
                    _setSearchValue(data.search, '') // set default to empty string if none
                  }
                  // -3- use given default from scope if still none found
                  if (_scope.query.search && !_getSearchValue(data.search)) {
                    _setSearchValue(data.search, _scope.query.search)
                  }
                }
                // make sure the scope has the found value
                _scope.query.search = _getSearchValue(data.search)

                // All other filter keys: load the customFilters value from data.customFilters local storage
                // TODO : Not checking yet the location query params, should be done first (currently done in directives)
                for (const filter in data.customFilters) {
                  let paramValue = data.customFilters[filter]
                  // date range filter store as : .dateRange: { start: 'date string', end: 'date string' }
                  if (filter === 'dateRange') {
                    const dateRange = _scope.query.dateRange ? _scope.query.dateRange : {}
                    const { start: storedStart, end: storedEnd } = paramValue
                    const { queryStart, queryEnd } = { queryStart: queryParams['dateRange.start'], queryEnd: queryParams['dateRange.end'] }

                    // -A- process dateRange.start
                    // -1- override with given query param (from location.search) value
                    if (queryStart) {
                      dateRange.start = $window.moment(queryStart)
                    } else {
                      // -2- use already stored value (from localStorage). NB: do not use if we need to override localStorage
                      if (storedStart && !overrideLocalStorage) {
                        dateRange.start = $window.moment(storedStart)
                      } else {
                        // -3- use given default from scope
                        if (_scope.queryDefault.dateRange.start) dateRange.start = $window.moment(_scope.queryDefault.dateRange.start)
                      }
                    }

                    // -B- process dateRange.end
                    // -1- override with given query param (from location.search) value
                    if (queryEnd) {
                      dateRange.end = $window.moment(queryEnd)
                    } else {
                      // -2- use already stored value (from localStorage). NB: do not use if we need to override localStorage
                      if (storedEnd && !overrideLocalStorage) {
                        dateRange.end = $window.moment(storedEnd)
                      } else {
                        // -3- use given default from scope, do not put end value if there was already a queryStart date
                        if (!queryStart && _scope.queryDefault.dateRange.end) {
                          dateRange.end = $window.moment(_scope.queryDefault.dateRange.end)
                        } else {
                          dateRange.end = null
                        }
                      }
                    }
                    // next filter param from loop ->
                    continue
                  }

                  // 1- check given location query param first
                  if (queryParams[filter]) {
                    paramValue = queryParams[filter]
                  } else {
                    // NB: if there are given query params, ignores localStorage, it means we override all params
                    // removed paramValue which was taken from data.customFilters
                    if (overrideLocalStorage) {
                      paramValue = null
                    }
                  }

                  // 2- or keep with already loaded localStorage value if not null
                  if (Array.isArray(_scope.query[filter])) {
                    _scope.query[filter] = utilService.toArray(paramValue)
                  } else {
                    if (paramValue) {
                      _scope.query[filter] = paramValue
                    }
                  }
                  // 3 - use default
                  // _scope.query has already set to default on load
                }
                // #---
                // Set, save and broadcast the query values
                for (const param in _scope.query) {
                  const currentParamValue = _scope.query[param]
                  let newValue

                  if (param === 'dateRange') {
                    const { start, end } = currentParamValue
                    newValue = {
                      start: self.formatDate(start),
                      end: self.formatDate(end)
                    }
                  } else {
                    if (Array.isArray(currentParamValue)) {
                      queryParams[param] = utilService.toArray(queryParams[param])
                      // only use the localStorage value if there is no location param value
                      // if there is one, the selectPicker will pick it and process it according to it's rules
                      if (!queryParams[param] || queryParams[param].length === 0) {
                        newValue = utilService.toArray(currentParamValue)
                      } else {
                        newValue = queryParams[param]
                      }
                    } else {
                      newValue = _scope.query[param]
                    }
                  }
                  self.setSearchValueAndBroadcast(param, newValue)
                }
              },
              search: {
                search: _scope && _scope.query ? _scope.query.search : ''  // default search value from controller if provided
              },
              initComplete: () => {
                //$timeout(() => {
                //  _scope.table.columns.adjust().responsive.recalc() // make sure the responsive "rules" are calculated correctly
                //}, 50)
              }
            }

            angular.extend(result, extraDTOptions)
            // the bottom buttons
            if (editor) {
              if (hiddenButtons.indexOf('create') === -1) {
                result.buttons.push({ extend: 'create', editor, key: 'a', action: buttonActions.create })
              }
              if (hiddenButtons.indexOf('edit') === -1) {
                result.buttons.push({ extend: 'edit', editor, key: 'e', action: buttonActions.edit })
              }
              if (hiddenButtons.indexOf('remove') === -1) {
                result.buttons.push({ extend: 'remove', editor, action: buttonActions.remove })
              }
            }
            // add extra buttons before print + excel
            for (let i = 0; i < extraButtons.length; i++) {
              result.buttons.push(extraButtons[i])
            }
            // export buttons
            const showPrint = hiddenButtons.indexOf('print') === -1
            const showExcel = hiddenButtons.indexOf('excel') === -1
            const showCSV = hiddenButtons.indexOf('csv') === -1
            const excelText = '<i class="fa fa-fw fa-file-excel-o"></i>&nbsp;&nbsp;Excel'
            const csvText = '<i class="fa fa-fw fa-file-text-o"></i>&nbsp;&nbsp;CSV'

            // otherwise, one of them only or none
            if (showPrint || showExcel || showCSV) {
              const exportButton = {
                extend: 'collection',
                text: $translate.instant('Export'),
                className: 'dropdown-toggle dropup last-dropdown',
                dropup: true,
                fade: 100,
                autoClose: true,
                buttons: []
              }
              // prepare button export options
              angular.extend(exportOptions, {
                orthogonal: 'export',
                loadFullData: true
              })

              if (showPrint && !serverSideExport) {
                exportButton.buttons.push({
                  extend: 'copy',
                  text: '<i class="fa fa-fw fa-clipboard"></i>&nbsp;&nbsp;' + $translate.instant('Copy'),
                  exportOptions,
                  action: function (e, dt, node, config, cb) { // customize action
                    self.exportButtonAction(e, dt, node, config, cb, this, DataTable.ext.buttons.copyHtml5.action)
                  }
                })
                exportButton.buttons.push({
                  extend: 'print',
                  autoPrint: false,
                  text: '<i class="fa fa-fw fa-print"></i>&nbsp;&nbsp;' + $translate.instant('Print'),
                  exportOptions,
                  message: '<h4>' + exportMessage + $window.moment().format('D MMMM YYYY, hh:mm:ss') + '</h4>',
                  action: function (e, dt, node, config, cb) { // customize action
                    self.exportButtonAction(e, dt, node, config, cb, this, DataTable.ext.buttons.print.action)
                  }
                })
              }
              if (showExcel) {
                if (serverSideExport) {
                  // client-side export
                  exportButton.buttons.push({
                    extend: 'download',
                    text: excelText,
                    action: (e, dt) => self.exportTableDataAs(dt, 'xlsx')
                  })
                } else {
                  // client-side export
                  const excelButton = {
                    extend: 'excel',
                    autoFilter: true,
                    text: excelText,
                    exportOptions,
                    action: function (e, dt, node, config, cb) { // customize action
                      self.exportButtonAction(e, dt, node, config, cb, this, DataTable.ext.buttons.excelHtml5.action)
                    }
                  }
                  // set Excel button customization properties
                  if (excelForceStrCols) {
                    excelButton.customize = datatablesExportService.customizeForceStrOnCols(excelForceStrCols, customizeExcel)
                  } else {
                    if (customizeExcel) excelButton.customize = customizeExcel
                  }

                  exportButton.buttons.push(excelButton)
                }
              }
              if (showCSV) {
                if (serverSideExport) {
                  exportButton.buttons.push({
                    extend: 'download',
                    text: csvText,
                    action: (e, dt) => self.exportTableDataAs(dt, 'csv')
                  })
                } else {
                  // client-side export
                  exportButton.buttons.push({
                    extend: 'csv',
                    text: csvText,
                    exportOptions,
                    action: function (e, dt, node, config, cb) { // customize action
                      self.exportButtonAction(e, dt, node, config, cb, this, DataTable.ext.buttons.csvHtml5.action)
                    }
                  })
                }
              }
              if (exportButton.buttons.length) {
                result.buttons.push(exportButton)
              }
            }
            return result
          },

          createAndSetTable: (params) => {
            // wrapper to keep track of table objects
            _scope.table = tableElement.DataTable(params)
            // clear table state on session closed
            _scope.$on('clear-session-state', (event, args) => {
              Object.keys(localStorage).filter(key => {
                return key.startsWith('DataTables_datatables')
              }).forEach(key => localStorage.removeItem(key))
            })
            // Default reload on select filter modified
            _scope.$on('select-modified', (event, data) => {
              if (_scope.table && _scope.query) {
                self.reload()
              }
            })
            // default reload and draw on input search modified
            _scope.$on('search-modified', (event, data) => {
              $timeout(() => {
                _scope.query.search = data
                _scope.table.search(data).draw()
              })
            })
            // default reload on state selection filter change
            _scope.$on('state-modified', (event, stateObj) => {
              if (_scope.table && _scope.query[stateObj.key] !== stateObj.value) {
                _scope.query[stateObj.key] = stateObj.value
                self.reload()
              }
            })

            // default reload on toggle state value filter change
            _scope.$on('toggle-modified', (event, stateObj) => {
              if (_scope.table && _scope.query[stateObj.key] !== stateObj.value) {
                _scope.query[stateObj.key] = stateObj.value
                // make sure data is applied before reload
                $timeout(() => {
                  self.reload()
                })
              }
            })


            return _scope.table
          },

          reload: () => {
            _scope.table.ajax.reload()
          },

          /**
           * DT onPreSubmit default pattern. Uses checkFormValidity
           * @param {String} action Action name: 'remove' 'edit' 'create'
           * @param {*} editor DT Editor instance
           * @param {[string]} ignores Array of field names to ignore
           * @returns 
           */
          onPreSubmit: (action, editor, ignores) => action !== 'remove' ? self.checkFormValidity(editor.dom.form, editor, ignores) : true,

          /**
           * Check DT Editor form validity on enabled input field with a valid "name" attribute.
           * For validation on a field, make sure it has a name. For instance: Some types (select-picker, password) must be set via DT field "attr" property in order to be validated here. 
           * @param {*} form The DOM Form
           * @param {*} editor DT Editor instance
           * @param {[string]} ignores Array of field names to ignore
           * @returns 
           */
          checkFormValidity: (form, editor, ignores) => {
            ignores = ignores ? ignores : []
            let error = false
            const getErrorMessage = field => formService.errorMessage(field.validity, field) || field.validationMessage

            if (!form.checkValidity()) {
              for (let i = 0; i < form.length; i++) {
                const field = form[i]
                // do not validate field with no name
                if (!field.name) continue

                // do not validate excluded fields. 
                if (ignores.includes(field.name)) continue

                // get the Editor field ref.
                const editorField = editor.field(field.name)

                // do not validate disabled field
                if (!editorField.enabled()) continue

                // if the field has multiple values, check for each one until all validates or stop on first error.
                if (editorField.s.multiValue) {
                  // test for each values
                  const fieldError = Object.values(editorField.s.multiValues).some(value => {
                    const previousValue = field.value
                    const previousInnerText = field.innerText
                    field.value = value
                    field.innerText = value
                    // do the test and store error message
                    const result = !field.checkValidity()
                    if (result) editorField.error(getErrorMessage(field))
                    field.value = previousValue
                    field.innerText = previousInnerText
                    return result
                  })
                  if (fieldError) error = true // set like this to be able to loop all fields to show all errors at once.

                } else {
                  // single value check
                  const result = !field.checkValidity()
                  if (result) {
                    editorField.error(getErrorMessage(field))
                    error = true // set like this to be able to loop all fields to show all errors at once.
                  }
                }
              }
              if (error) {
                // On error found, also show form generic error message
                editor.error($translate.instant('FormValidationError'))
              }
            }
            return !error
          },

          renderBadge: (data, type, full, meta) => type === 'export' ? data : `<span class="badge">${data}</span>`,
          renderLabel: (data, type, full, meta, labelDefault) => {
            if (type === 'export') return data
            labelDefault = labelDefault ? labelDefault : 'default'
            return '<label class="label-mosaik label-mosaik-' + labelDefault + '">' + data + '</label>'
          },

          renderStateLabel: (data, type, full, meta, state1, state2, text1, text2, textDefault, label1, label2, labelDefault, icon1, icon2, iconDefault) => {
            if (type === 'export') {
              return (data === state1 ? $translate.instant(text1) : data === state2 ? $translate.instant(text2) : $translate.instant(labelDefault))
            }
            textDefault = textDefault != null ? textDefault : data

            label1 = label1 ? label1 : 'success'
            label2 = label2 ? label2 : 'warning'
            labelDefault = labelDefault ? labelDefault : 'default'

            icon1 = icon1 ? '&nbsp;&nbsp;<i class="fa fa-' + icon1 + '"></i>' : ''
            icon2 = icon2 ? '&nbsp;&nbsp;<i class="fa fa-' + icon2 + '"></i>' : ''
            iconDefault = iconDefault ? '&nbsp;&nbsp;<i class="fa fa-' + iconDefault + '"></i>' : ''

            if (data === state1) {
              return '<label class="label-mosaik label-mosaik-' + label1 + '">' + $translate.instant(text1) + icon1 + '</label>'
            }
            if (data === state2) {
              return '<label class="label-mosaik label-mosaik-' + label2 + '">' + $translate.instant(text2) + icon2 + '</label>'
            }
            return '<label class="label-mosaik label-mosaik-' + labelDefault + '">' + $translate.instant(textDefault) + iconDefault + '</label>'
          },

          renderVisibleHidden: (data, type, full, meta, labelVisible, labelHidden, iconVisible, iconHidden) => {
            return self.renderStateLabel(Boolean(data), type, full, meta, true, false, 'Visible', 'Hidden', '', labelVisible || 'success', labelHidden || 'warning', '', iconVisible, iconHidden)
          },

          renderYesNo: (data, type, full, meta, labelYes, labelNo, iconYes, iconNo) => {
            return self.renderStateLabel(Boolean(data), type, full, meta, true, false, 'Yes', 'No', '', labelYes || 'success', labelNo || 'neutral', '', iconYes, iconNo)
          },

          renderYesOnly: (data, type, full, meta, labelYes, labelNo, iconYes, iconNo) => {
            if (!Boolean(data)) {
              return ''
            }
            return self.renderYesNo(data, type, full, meta, labelYes, labelNo, iconYes, iconNo)
          },

          renderYesNoDefaultWithIcons: (data, type, full, meta) => {
            return self.renderYesNo(data, type, full, meta, 'success', 'warning', 'check-circle', 'exclamation-circle')
          },

          renderCorrectIncorrect: (data, type, full, meta) => self.renderStateLabel(Boolean(data), type, full, meta, true, false, 'Correct', 'Incorrect', '', 'success', 'danger'),

          renderActiveInactive: (data, type, full, meta) => {
            return self.renderStateLabel(Boolean(data), type, full, meta, true, false, 'Active', 'Inactive', '', 'success', 'danger')
          },

          radioLabel: (labelKey, hintKey = '') => `${$translate.instant(labelKey)}${hintKey ? `&nbsp;&nbsp;<span class="text-hint"><small><i>${$translate.instant(hintKey)}</i></small></span>` : ''}`,

          getVisibleHiddenOptionsLabels: (hintVisibleKey, hintHiddenKey) => {
            return [
              { label: self.radioLabel('Hidden', hintHiddenKey), value: 0 },
              { label: self.radioLabel('Visible', hintVisibleKey), value: 1 }
            ]
          },

          getYesNoOptionsLabels: (hintYesKey, hintNoKey) => {
            return [
              { label: self.radioLabel('No', hintNoKey), value: 0 },
              { label: self.radioLabel('Yes', hintYesKey), value: 1 }
            ]
          },

          getYesNoOptionsLabelsReverse: (hintYesKey, hintNoKey) => {
            return [
              { label: self.radioLabel('Yes', hintYesKey), value: 0 },
              { label: self.radioLabel('No', hintNoKey), value: 1 }
            ]
          },

          getActiveInactiveOptionsLabels: (hintActiveKey, hintInactiveKey,) => {
            return [
              { label: self.radioLabel('Inactive', hintInactiveKey), value: 0 },
              { label: self.radioLabel('Active', hintActiveKey), value: 1 }
            ]
          },

          getPasswordIsTemporaryOptions: () => [{ label: $translate.instant('AskForPasswordUpdateOnNextLogin'), value: 1 }],

          renderLabelContent: (label, value, i, row) => {
            const visibleState = !row.visible ? '&nbsp;&nbsp;<i class="fa fa-eye-slash"></i>' : ''
            let result
            if (!row.active) {
              result = '<i>' + label + '</i>' + visibleState + '&nbsp;&nbsp;<span class="label-mosaik label-mosaik-danger">' + $translate.instant('Inactive') + '</span>'
            } else {
              result = label + visibleState
            }
            if (row.deprecated) {
              result += '&nbsp;&nbsp;<span class="label-mosaik label-mosaik-warning">' + $translate.instant('Deprecated') + '</span>'
            }
            return result
          },

          sanitize: (data = '') => $sanitize(data),
          sanitizeEscape: (data = '') => utilService.escapeHtmlEntities($sanitize(data)),

          renderLabelOrg: (label, value, i, org) => `${label} <small class="text-id">${org.internalOrgid}</small>`,
          renderOrgNames: organizations => {
            const results = organizations.map(org => {
              return self.renderOrgName(org.id, org.internalOrgid, org.name, org.active)
            })
            return results.join('<br>')
          },
          renderOrgName: (orgid, internalOrgid, orgName, orgActive) => {
            if (!orgid) {
              return ''
            }
            // format as html
            var inactive = !orgActive ? '&nbsp;<div class="label-mosaik label-mosaik-danger">' + $translate.instant('Inactive') + '</div>' : ''
            return self.sanitize(orgName) + ' <small class="text-id">' + self.sanitize(internalOrgid) + '</small>' + inactive
          },

          renderGroups: (groups, type, full, meta, allGroups, showHidden) => {
            if (!groups || !groups.length) { // must have groups
              return '<label class="badge badge-danger"><i class=\'fa fa-exclamation-triangle fa-fw\'></i></label>&nbsp;&nbsp;<small class="text-muted"><i>' + $translate.instant('MissingGroup') + '</i></small>'
            }
            var warning = ''
            // check for librairy access
            if (full.libraries && full.libraries.length === 0) { // must have libraries
              if (type === 'export') {
                warning = $translate.instant('MissingLibraries', { count: groups.length })
              } else {
                warning = '<div class="table-badge"><label class="badge badge-danger"><i class=\'fa fa-exclamation-triangle fa-fw\'></i></label>&nbsp;&nbsp;<small class="text-muted"><i>' + $translate.instant('MissingLibraries', { count: groups.length }) + '</i></small></div>'
              }
            }

            // print, export render
            if (type === 'export') {
              return groups.map(group => {
                if (allGroups) {
                  group = allGroups[group.id]
                }
                if (!showHidden && !group.isVisible) {
                  return
                }
                return group.name
              }).filter(group => {
                return group !== undefined
              }).join(', ') + (warning ? ' (' + warning + ')' : '')
            }
            // screen render
            return groups.map(group => {
              if (allGroups) {
                group = allGroups[group.id]
              }
              if (!showHidden && !group.isVisible) {
                return
              }
              return '<label class="label-mosaik label-mosaik-sm ' + (group.masterOrgid ? 'label-mosaik-default' : 'label-mosaik-primary') + '">' +
                (group.masterOrgid ? '<i class=\'fa fa-industry fa-fw\'></i>&nbsp;&nbsp;' : '') + self.sanitize(group.name) + '</label>' +
                (!group.isVisible ? '&nbsp;<i class="fa fa-eye-slash fa-fw"></i>' : '')
            }).filter(group => {
              return group !== undefined
            }).join('<br>') + (warning ? warning : '')
          },

          /**
           * format the category name as HTML label, one per line
           * if a category cannot be found, it is not shown
           * @param {[String]|String} categories an array of categoryid or a comma separated categoryid string, ex: [1,2,3,4] or "1,2,3,4"
           * @param {String} type DT render type
           * @param {*} full DT Full row or data object 
           * @param {*} meta 
           * @param {*} allCategories must be a map of category object by their categoryid with the localized name property, ex: { 1: {name: "Cat 1"}, 2: { name: "Cat 2"} } 
           * @param {*} options 
           * @returns {String} rendered categories
           */
          renderCategories: (categories, type, full, meta, allCategories, options) => {
            if (!options) {
              options = {}
            }

            if (!categories) {
              return ''
            }
            if (!Array.isArray(categories)) {
              categories = String(categories).split(',')
            }
            // format the categories as HTML label, one per line OR for print, export render
            return categories.map(category => {
              const categoryid = (typeof category === 'object') ? (category.categoryid || category.id) : category
              category = allCategories && allCategories[categoryid] ? allCategories[categoryid] : null
              if (!category) return ''
              const categoryName = (category.name || category) + (options.includeid && category.id ? ' id:' + category.id : '')
              const style = labelService.getLabelCustomStyleAsText(category.color, category.isTextColorDefault)
              return type === 'export' ? categoryName : '<span class="label-mosaik label-mosaik-default" style="' + style + '">' + self.sanitize(categoryName) + '</span>'
            }).filter(category => category !== '').join(type === 'export' ? ', ' : '<br>')
          },

          renderTags: (tags, type, full, meta, allTags) => {
            if (!tags) {
              return ''
            }
            if (!Array.isArray(tags)) {
              tags = String(tags).split(',')
            }

            // print, export render
            if (type === 'export') {
              return tags.map(tag => {
                if (!tag) return
                const tagid = (typeof tag === 'object') ? (tag.tagid || tag.id) : tag
                return allTags && allTags[tagid] ? allTags[tagid].name : tag.name
              }).filter(tag => tag).join(', ')
            }
            // screen render
            return tags.map(tag => {
              const tagid = (typeof tag === 'object') ? (tag.tagid || tag.id) : tag
              if (allTags) {
                tag = allTags[tagid]
              }
              return self.renderOneTag(tag)
            }).filter(tag => tag).join('<br>')
          },

          renderOneTag: (tag) => {
            if (!tag) return ''
            const icon = (tag.isVisibleOnCertificate === 1) ? '<i class="fa fa-certificate fa-fw"></i>&nbsp;&nbsp;' : ''
            const style = labelService.getLabelCustomStyleAsText(tag.color, tag.isTextColorDefault)
            return '<span class="label-mosaik label-mosaik-default" style="' + style + '">' + icon + self.sanitize(tag.name) + '</span>'
          },

          _renderMsTo: (data, type, divider, suffix) => data ? `${(data / divider).toFixed(1)}${type !== 'export' ? suffix : ''}` : '',
          renderMsToSec: (data, type) => self._renderMsTo(data, type, 1000, 's'),
          renderMsToMin: (data, type) => self._renderMsTo(data, type, 60000, 'm'),
          renderDurationHuman: (data) => data ? $window.moment.duration(data).humanize() : '',

          renderDateReport: (data, type) => data ? $filter('date')(data, 'yyyy/MM/dd') : '',
          renderDateTimeReport: (data, type) => data ? $filter('date')(data, 'yyyy/MM/dd HH:mm') : '',
          renderDateTimeReportSec: (data, type) => data ? $filter('date')(data, 'yyyy/MM/dd HH:mm:ss') : '',
          renderDateTimeReportMs: (data, type) => data ? $filter('date')(data, 'yyyy/MM/dd HH:mm:ss.sss') : '',
          renderDateFromNow: (data, type) => {
            if (type === 'export') {
              return self.renderDateTimeReport(data)
            }
            return data ? $window.moment(data).fromNow() : ''
          },
          renderDateHuman: (data, type) => self._renderDateTimeHuman(data, type, 'YYYY/MM/DD', false),
          renderDateTimeHuman: (data, type) => self._renderDateTimeHuman(data, type, null, true),
          _renderDateTimeHuman: (data, type, format, withTime) => {
            if (type === 'export') {
              return self.renderDateTimeReport(data)
            }
            if (!data) {
              return ''
            }
            const createdAt = $window.moment(data, format)
            const createdAtYear = createdAt.year()
            const currentYear = moment().year()
            withTime = withTime ? 'Time' : ''
            return createdAt.format((createdAtYear === currentYear ? $translate.instant('Date' + withTime + 'HumanWoYear') : $translate.instant('Date' + withTime + 'HumanWithYear')))
          },

          renderIcon: (data, type, full) => {
            if (!data) return ''
            if (type === 'export') return data
            return '<div style="text-align: center;width: 100%;padding: 0 15px 0 0"><i class="fa fa-fw fa-2x ' + data + '"' + (full.color ? 'style="color:' + full.color + ';"' : '') + '></i></div>'
          },

          dataForOrdering: (data) => '<div class="td-ordering"><i class="fa fa-fw fa-sort fa-2x"></i></div>',
          formatOptionalField: (fieldkey) => `${$translate.instant(fieldkey)} <small>(${$translate.instant('Optional')})</small>`,

          /**
           * 
           * @param {*} table The DT table object
           * @param {*} editor The DT editor object instance
           * @param {[]} diff The distance array between the row to reorder, nothing will be done if the distance is zero (meaning reordering on the same row)
           * @param {*} edit 
           * @param {*} validateFn A custom validation function to call, it should return true on validation success, false otherwise
           * @returns 
           */
          rowReorder: (table, editor, diff, edit, validateFn) => {
            if (diff.length === 0) {
              return // nothing to reorder, occurs when simply clicking one row on the reorder column
            }
            // run the custom validation function if any
            if (validateFn && !validateFn()) {
              return // validation failed
            }

            let previousNewOrder

            // No rows should be selected, they will be reselected correctly below
            table.rows().deselect()

            // First: select the row to be edited
            for (let i = 0, len = diff.length; i < len; i++) {
              table.row(diff[i].node).select()
            }

            // Start the editor on the selected rows after edit is fully initialted
            // NB: this event is not documented and is supposed to be removed in DTEditorV2
            editor.one('initMultiEdit', (e, node, data, items, type) => {
              // Execute after the initEdit callback has been called
              $timeout(() => {
                const priorityField = editor.field(edit.dataSrc)
                // Multiset the priority values (reorder)
                for (let i = 0, len = diff.length; i < len; i++) {
                  const row = table.row(diff[i].node)
                  let newOrder = diff[i].newData
                  const rowid = row.data()[editor.s.idSrc]

                  if (i === len - 1) {
                    if (newOrder === previousNewOrder) {
                      newOrder = previousNewOrder + 1
                    }
                  }
                  previousNewOrder = newOrder
                  priorityField.multiSet(rowid, newOrder)
                }

                // Submit the modifications
                editor.submit()
              })
            })

            const selected = table.rows({ selected: true })
            editor.edit(selected.indexes(), false)
          },

          getParentDiv: scope => {
            // This is the parent div which is within the selected row. This is needed to find out which is the current selected row
            return $(scope).parent().parent().parent()
          },

          selectClosestRow: (table, element) => {
            table.rows().deselect()
            table.rows($(element).closest('tr')).select()
          },

          selectAndGetClosestRow: (table, scope) => {
            self.selectClosestRow(table, self.getParentDiv(scope))
            return table.rows({ selected: true })
          },

          hideMultiRestoreButton: () => {
            //on multi-value, hide the confirm/restore multi-edit button             
            angular.element('.well.well-sm.multi-restore').hide()
          },

          getThen: (date, addDays) => {
            date.setDate(date.getDate() + addDays)
            return date
          },

          /**
           * 
           * @param {*} editor DT Editor
           * @param {*} parentFieldName Name of the parent field in the editor
           * @param {*} parentCache Array of parents to cache the childs 
           * @param {*} childPropertyName Name of the child property in parents cache
           * @param {*} childFieldName Name of the child field in the editor
           * @param {*} getChilds Function to get the childs, should return a promise
           */
          setDependentAndPopulateChild: (editor, parentFieldName, parentCache, childPropertyName, childFieldName, getChilds) => {

            editor.dependent(parentFieldName, (parentValue, rows, callback) => {
              if (!parentValue) {
                // updating multiple rows, cannot update the child fields which can contain different value when parents are different
                return {
                  hide: [childFieldName]
                }
              }

              const formatResult = childs => {
                const result = {
                  options: {},
                  show: childFieldName
                }
                result.options[childFieldName] = childs
                return result
              }
              const setEditorField = childs => {
                editor.field(childFieldName).update(childs)
                return callback(formatResult(childs))
              }

              // check if already loaded for this org
              if (parentCache[parentValue][childPropertyName]) {
                return setEditorField(parentCache[parentValue][childPropertyName], [])
              }
              getChilds(parentValue)
                .then(childs => {
                  parentCache[parentValue][childPropertyName] = childs
                  setEditorField(childs, [])
                })
            })
          }
        }


        /* *****************************************
         * Copied from DataTable src.
         * *****************************************
         */

        /**
         * Build up the parameters in an object needed for a server-side processing
         * request.
         *  @param {object} oSettings dataTables settings object
         *  @returns {bool} block the table drawing or not
         *  @memberof DataTable#oApi
         */
        function _fnAjaxParameters(settings) {
          var
            columns = settings.aoColumns,
            features = settings.oFeatures,
            preSearch = settings.oPreviousSearch,
            preColSearch = settings.aoPreSearchCols,
            colData = function (idx, prop) {
              return typeof columns[idx][prop] === 'function' ?
                'function' :
                columns[idx][prop];
            };

          return {
            draw: settings.iDraw,
            columns: columns.map(function (column, i) {
              return {
                data: colData(i, 'mData'),
                name: column.sName,
                searchable: column.bSearchable,
                orderable: column.bSortable,
                search: {
                  value: preColSearch[i].search,
                  regex: preColSearch[i].regex,
                  fixed: Object.keys(column.searchFixed).map(function (name) {
                    return {
                      name: name,
                      term: column.searchFixed[name].toString()
                    }
                  })
                }
              };
            }),
            order: _fnSortFlatten(settings).map(function (val) {
              return {
                column: val.col,
                dir: val.dir,
                name: colData(val.col, 'sName')
              };
            }),
            start: settings._iDisplayStart,
            length: features.bPaginate ?
              settings._iDisplayLength :
              -1,
            search: {
              value: preSearch.search,
              regex: preSearch.regex,
              fixed: Object.keys(settings.searchFixed).map(function (name) {
                return {
                  name: name,
                  term: settings.searchFixed[name].toString()
                }
              })
            }
          };
        }

        // PANACHE: adapted from jquery
        var _isPlainObject = function (value) {
          // is this an object?
          return (value != null &&
            Object.prototype.toString.call(value) === '[object Object]')
        }

        function _fnSortFlatten(settings) {
          var
            i, k, kLen,
            aSort = [],
            extSort = DataTable.ext.type.order,
            aoColumns = settings.aoColumns,
            aDataSort, iCol, sType, srcCol,
            fixed = settings.aaSortingFixed,
            fixedObj = _isPlainObject(fixed),
            nestedSort = [];

          if (!settings.oFeatures.bSort) {
            return aSort;
          }

          // Build the sort array, with pre-fix and post-fix options if they have been
          // specified
          if (Array.isArray(fixed)) {
            _fnSortResolve(settings, nestedSort, fixed);
          }

          if (fixedObj && fixed.pre) {
            _fnSortResolve(settings, nestedSort, fixed.pre);
          }

          _fnSortResolve(settings, nestedSort, settings.aaSorting);

          if (fixedObj && fixed.post) {
            _fnSortResolve(settings, nestedSort, fixed.post);
          }

          for (i = 0; i < nestedSort.length; i++) {
            srcCol = nestedSort[i][0];

            if (aoColumns[srcCol]) {
              aDataSort = aoColumns[srcCol].aDataSort;

              for (k = 0, kLen = aDataSort.length; k < kLen; k++) {
                iCol = aDataSort[k];
                sType = aoColumns[iCol].sType || 'string';

                if (nestedSort[i]._idx === undefined) {
                  nestedSort[i]._idx = aoColumns[iCol].asSorting.indexOf(nestedSort[i][1]);
                }

                if (nestedSort[i][1]) {
                  aSort.push({
                    src: srcCol,
                    col: iCol,
                    dir: nestedSort[i][1],
                    index: nestedSort[i]._idx,
                    type: sType,
                    formatter: extSort[sType + "-pre"],
                    sorter: extSort[sType + "-" + nestedSort[i][1]]
                  });
                }
              }
            }
          }

          return aSort;
        }

        var _pluck = function (a, prop, prop2) {
          var out = [];
          var i = 0, ien = a.length;

          // Could have the test in the loop for slightly smaller code, but speed
          // is essential here
          if (prop2 !== undefined) {
            for (; i < ien; i++) {
              if (a[i] && a[i][prop]) {
                out.push(a[i][prop][prop2]);
              }
            }
          }
          else {
            for (; i < ien; i++) {
              if (a[i]) {
                out.push(a[i][prop]);
              }
            }
          }

          return out;
        };

        function _fnSortResolve(settings, nestedSort, sort) {
          var push = function (a) {
            if (_isPlainObject(a)) {
              if (a.idx !== undefined) {
                // Index based ordering
                nestedSort.push([a.idx, a.dir]);
              }
              else if (a.name) {
                // Name based ordering
                var cols = _pluck(settings.aoColumns, 'sName');
                var idx = cols.indexOf(a.name);

                if (idx !== -1) {
                  nestedSort.push([idx, a.dir]);
                }
              }
            }
            else {
              // Plain column index and direction pair
              nestedSort.push(a);
            }
          };

          if (_isPlainObject(sort)) {
            // Object
            push(sort);
          }
          else if (sort.length && typeof sort[0] === 'number') {
            // 1D array
            push(sort);
          }
          else if (sort.length) {
            // 2D array
            for (var z = 0; z < sort.length; z++) {
              push(sort[z]); // Object or array
            }
          }
        }
        // **********************************************

        return self
      }
    ]
  )
