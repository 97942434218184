angular.module('mosaik.services')
  .factory('passportService',
  ['$window', '$http', '$rootScope', 'accessCodeService', 'locationHelper', 'keyService',
    function ($window, $http, $rootScope, accessCodeService, locationHelper, keyService) {
      const scope = 'passport'
      const self = {
        setup: () => $http.get('/api/passport/setup').then(response => response.data),
        signinWithStrategy: strategyName => {
          const requestedStateParam = ($rootScope.requestedState ? '?requestedState=' + encodeURIComponent(JSON.stringify($rootScope.requestedState)) : '')
          const randomForNoCache = (requestedStateParam ? '&' : '?') + '_=' + Math.random().toFixed(8).substring(2)
          const url = locationHelper.getHostname() + '/api/passport/login/' + strategyName + '/' + (accessCodeService.getCode() ? accessCodeService.getCode() : '') + requestedStateParam + randomForNoCache
          $window.location = url
        },

        setShortcuts: strategiesConfig => {
          if (strategiesConfig.google.active) {
            keyService.setShortcut(strategiesConfig.google.shortcut, scope, () => self.signinWithStrategy('google'))
          }
          if (strategiesConfig.facebook.active) {
            keyService.setShortcut(strategiesConfig.facebook.shortcut, scope, () => self.signinWithStrategy('facebook'))
          }
          if (strategiesConfig.linkedin.active) {
            keyService.setShortcut(strategiesConfig.linkedin.shortcut, scope, () => self.signinWithStrategy('linkedin'))
          }
          if (strategiesConfig.microsoft.active) {
            keyService.setShortcut(strategiesConfig.microsoft.shortcut, scope, () => self.signinWithStrategy('microsoft'))
          }
          key.setScope(scope)
        }
      }
      return self
    }]
  )