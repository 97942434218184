angular.module('mosaik.controllers')
  .controller('mobileController',
    ['$scope', '$state', 'sessionState', '$http', '$window', '$translate', 'ngIntlTelInput', 'notificationHelper', '$timeout', 'sessionStateService',
      function ($scope, $state, sessionState, $http, $window, $translate, ngIntlTelInput, notificationHelper, $timeout, sessionStateService) {
        $timeout(() => $scope.show = true, 100)
        $scope.mobile = {
          mobilePhone: sessionState.mobilePhone
        }
        $scope.loading = false

        $scope.setMobilePhone = () => {
          $scope.loading = true

          $http.put('/api/user/me/mobilePhone', $scope.mobile)
            .then(success => {
              notificationHelper.successFrom(success)
              $state.go(success.data.redirectState.name, success.data.redirectState.params, { reload: 'secure' })
            })
            .catch(error => notificationHelper.failureFrom(error))
            .finally(() => $scope.loading = false)
        }

        $scope.gotoNextState = () => sessionStateService.getAndGo('/api/passport/landing/next/mobile')
      }]
  )
