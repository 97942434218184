angular.module('mosaik.controllers')
  .controller('helpController',
    ['$window', '$scope', '$translate', '$timeout', 'sessionState', '$stateParams', '$http', '$compile', 'chatService', '$sce', 'translationService', 'browserService', 'modalService',
      function ($window, $scope, $translate, $timeout, sessionState, $stateParams, $http, $compile, chatService, $sce, translationService, browserService, modalService) {
        $scope.loadingChat = false
        $timeout(() => $scope.show = true, 100)
        $scope.isConnected = sessionState.connected
        $scope.isAdmin = sessionState.isAdmin
        $scope.canAccessApi = sessionState.canAccessApi

        // check for a given state params to force change the language, 
        // if so, we must wait for the locale to change before initializing the typeform
        const checkStateParams = new Promise(resolve => ($stateParams.locale ? translationService.change($stateParams.locale, resolve) : resolve()))
        const open = $stateParams.open

        checkStateParams.then(() => {
          setTypeformData().then(() => {
            if (open === 'contact') {
              $timeout(() => angular.element('#typeform-contact-us').click(), 100)
            } else if (open === 'suggestion') {
              $timeout(() => angular.element('#typeform-suggestion-box').click(), 100)
            } else if (open === 'chat') {
              $timeout(() => $scope.startChat(), 100)
            }
          })
        })
        $scope.$on('locale-changed', () => {
          setTypeformData().then()
        })

        function setTypeformData() {
          return  new Promise(resolve => {
            const hiddens = [
              'instance=' + $translate.instant('MainTitle'),
              'orgid=' + (sessionState.orgid ? sessionState.orgid : 'offline'),
              'orgname=' + (sessionState.internalOrgid ? sessionState.internalOrgid : 'offline'),
              'username=' + (sessionState.email ? sessionState.email : 'offline'),
              'strategyname=' + (sessionState.strategyName ? sessionState.strategyName : 'offline'),
              'isadmin=' + ($scope.isAdmin ? $scope.isAdmin : 'offline')
            ]
            $scope.typeformHidden = hiddens.join(',')
            $scope.contactTypeformid =  $translate.instant('HelpContactTypeformid')
            $scope.suggestionTypeformid =  $translate.instant('HelpSuggestionTypeformid')
            // Force reset typeform
            $timeout(() => {
              if ($window.tf) {
                $window.tf.reload()
              }
              resolve()
            })
          })
        }

        $scope.showFramePopup = (key) => {
          const src = $translate.instant(key)
          modalService.popupFrame(src)
        }

        function getOnlineStatus(value) {
          return value === true ? { class: 'badge-success', text: $translate.instant('Online') } : value === false ? { class: 'badge-danger', text: $translate.instant('Degraded') } : { class: '', text: $translate.instant('Unknown') }
        }

        $scope.showStatusPopup = function () {
          $http.get('/api/help/status')
            .then(function (response) {
              $scope.typeformStatus = getOnlineStatus(response.data.typeformOnline)
              $scope.vimeoStatus = getOnlineStatus(response.data.vimeoOnline)
              
              $scope.mosaik = {
                frameUrl: $sce.trustAsResourceUrl(response.data.mosaik.frameUrl),
                humanUrl: response.data.mosaik.humanUrl
              }
              $scope.typeformUrl = $sce.trustAsUrl(response.data.typeform.url)
              $scope.vimeoUrl = $sce.trustAsUrl(response.data.vimeo.url)

              var compiledContent = $compile(
                '<div class="vex-content-status-filler"></div>' +
                '<iframe scrolling="yes" class="vex-content-iframe vex-content-iframe-status" ng-src="{{mosaik.frameUrl}}"></iframe>' +
                '<div class="vex-content-status-others-container">' +
                '  <div class="vex-content-status-source text-center">' +
                '    <i><h6>{{"ServicesStatusSrc" | translate | trust}}&nbsp;<a target="_blank" ng-href="{{mosaik.humanUrl}}">{{mosaik.humanUrl}}</a>&nbsp;<i class="fa fa-external-link" aria-hidden="true"></i></h6></i>' +
                '  </div>' +
                '  <h4 class="vex-content-status-others-title text-center">{{"ServicesOther" | translate}}</h4>' +
                '  <div class="row">' +
                '    <div class="col-xs-6 col-sm-6 col-md-6 text-right">' + 
                '       <a role="button" class="btn btn-status-other" target="_blank" ng-href="{{typeformUrl}}">Typeform&nbsp;<i class="fa fa-external-link" aria-hidden="true"></i>&nbsp;&nbsp;' + 
                '          <span ng-class="typeformStatus.class" class="badge">{{typeformStatus.text}}</span>' +                   
                '       </a>' +
                '    </div>' +
                '    <div class="col-xs-6 col-sm-6 col-md-6 text-left">' + 
                '       <a role="button" class="btn btn-status-other" target="_blank" ng-href="{{vimeoUrl}}">Vimeo&nbsp;<i class="fa fa-external-link" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + 
                '          <span ng-class="vimeoStatus.class" class="badge">{{vimeoStatus.text}}</span>' +                  
                '       </a>' +
                '    </div>' +
                '  </div>' +
                '</div>')($scope)

              $window.vex.open({
                className: 'vex-fullpage vex-theme-mosaik vex-light',
                contentClassName: 'vex-content-fullpage',
                afterOpen: function () {
                  angular.element(this.contentEl).append(compiledContent)
                }
              })
            })
        }

        $scope.startChat = function () {
          $scope.loadingChat = true

          chatService.start({
            email: sessionState.email,
            name: sessionState.displayName,
            userid: sessionState.id,
            orgid: sessionState.internalOrgid,
            instance: $translate.instant('MainTitle')
          }, function ready() {
            $scope.loadingChat = false                      
          })
        }

        $scope.showUserAgent = function () {       
          $window.Swal.fire({
            title: $translate.instant('HelpUserAgentTitle'),
            html: $translate.instant('HelpUserAgentHtml', browserService.browserInfo()),
            icon: 'info'
          })
        }
      }
    ])
