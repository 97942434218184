angular.module('mosaik.services')
  .factory('notificationHelper',
    ['$window', '$translate', '$location', 'utilService',
      function ($window, $translate, $location, utilService) {
        const httpStatus = {
          '200': 'OK',
          '201': 'Created',
          '202': 'Accepted',
          '203': 'Non-Authoritative Information',
          '204': 'No Content',
          '205': 'Reset Content',
          '206': 'Partial Content',
          '300': 'Multiple Choices',
          '301': 'Moved Permanently',
          '302': 'Found',
          '303': 'See Other',
          '304': 'Not Modified',
          '305': 'Use Proxy',
          '306': 'Unused',
          '307': 'Temporary Redirect',
          '400': 'Bad Request',
          '401': 'Unauthorized',
          '402': 'Payment Required',
          '403': 'Forbidden',
          '404': 'Not Found',
          '405': 'Method Not Allowed',
          '406': 'Not Acceptable',
          '407': 'Proxy Authentication Required',
          '408': 'Request Timeout',
          '409': 'Conflict',
          '410': 'Gone',
          '411': 'Length Required',
          '412': 'Precondition Required',
          '413': 'Request Entry Too Large',
          '414': 'Request-URI Too Long',
          '415': 'Unsupported Media Type',
          '416': 'Requested Range Not Satisfiable',
          '417': 'Expectation Failed',
          '418': 'I\'m a teapot',
          '429': 'Too Many Requests',
          '500': 'Internal Server Error',
          '501': 'Not Implemented',
          '502': 'Bad Gateway',
          '503': 'Service Unavailable',
          '504': 'Gateway Timeout',
          '505': 'HTTP Version Not Supported',
          '': 'Unknown status'
        }

        const globalOptions = {
          position: 'top-right',
          icons: {
            enabled: true,
            prefix: '<i class="fa fa-fw fa-',
            suffix: '"></i>',
            tip: 'question',
            info: 'info-circle',
            success: 'check-circle',
            warning: 'exclamation-circle',
            alert: 'exclamation-triangle',
            confirm: 'exclamation-triangle'
          },
          durations: {
            global: 10000
          }
        }
        const notifier = new $window.AWN(globalOptions)
        let currentNotice

        function getOptions(options, type) {
          const result = {
            labels: {
              tip: options.title || $translate.instant('Tip'),
              info: options.title || $translate.instant('Info'),
              success: options.title || $translate.instant('Success'),
              warning: options.title || $translate.instant('Attention'),
              alert: options.title || $translate.instant('Error')
            },
            durations: {}
          }
          if (options.duration) result.durations[type] = options.duration
          return result
        }

        function notify(message = '', options, type) {
          options = options ? options : {}
          if (!options.keepPrevious && currentNotice) {
            currentNotice.remove()
          }
          const isHtmlDoc = utilService.isHtmlDoc(message)
          //Only strip when it's a full html doc. Othewise, keep formatting.
          const escapedMessage = isHtmlDoc ? utilService.stripHtmlTags(utilService.htmlKeepBody(message)) : message
          return currentNotice = notifier[type](escapedMessage, getOptions(options, type))
        }

        const getReadyState = readyState => {
          const states = {
            0: 'UNSENT, Client created. not called yet',
            1: 'OPENED	Client called',
            2: 'HEADERS_RECEIVED,	CLient called, headers, status available',
            3: 'LOADING, Download in progress',
            4: 'DONE, operation completer'
          }
          return states[readyState] || ''
        }

        const self = {
          /**
           * Extract the error message string from an object
           * @param {*} response A JSON or response xml HTTP object
           * @returns {String} Error message found in the response object or a default "unknow error" string.
           */
          errorMessageFrom: (response) => {
            let message = response.message || response.error ||
              (response.data && response.data.message && typeof response.data.message === 'string' ? response.data.message : '') ||
              (typeof response.data === 'string' && response.data ? response.data : '') ||
              (response.responseJSON ? response.responseJSON.message : undefined) ||
              response.responseText || response.statusText || `${$translate.instant('UnknownError')}${response.status ? ` (Status code: ${response.status})` : ''}`
            const state = typeof response.state === 'function' ? response.state() : ''
            const readyState = response.readyState !== undefined ? getReadyState(response.readyState) : ''
            if (message === 'error') {
              // add more context
              message = `${message}, ${state}, ${readyState}` 
            }
            return message
          },
          failureLevelFrom: (json) => {
            const level = json.level || (json.data ? json.data.level : 'error')
            return level === 'warn' ? 'warning' : level
          },
          longDelayFrom: (json) => json.longDelay || (json.data ? json.data.longDelay : false),
          /**
           * Notify from the state params: from warningMessage or errorMessage and clear the query string from those params.
           * @param {*} stateParams 
           */
          notifyFromParams: (stateParams) => {
            // show error message from query if any
            if (stateParams.errorMessage) {
              self.error(stateParams.errorMessage, { keepPrevious: true })
              $location.search('errorMessage', null)
            }
            // show warning message from query if any
            if (stateParams.warningMessage) {
              self.warning(stateParams.warningMessage, { keepPrevious: true })
              $location.search('warningMessage', null)
            }
          },

          notice: (message, options) => notify(message, options, 'tip'),
          info: (message, options) => notify(message, options, 'info'),
          successFrom: (json) => {
            const message = (json.data && json.data.message ? json.data.message : $translate.instant('Success'))
            currentNotice = self.success(message)
          },
          success: (message, options) => notify(message, options, 'success'),
          failureFrom: (response) => {
            const message = self.errorMessageFrom(response)
            const level = self.failureLevelFrom(response)
            const options = self.longDelayFrom(response) ? { duration: 30000 } : {}
            currentNotice = level === 'error' ? self.error(message, options) : self.warning(message, options)
          },
          failureFromBlob: error => {
            if (error.data) {
              // use a reader because the responseType was set to blob
              const reader = new FileReader()
              reader.status = error.status
              reader.statusText = error.statusText
              reader.readAsText(error.data)
              reader.addEventListener('load', () => {
                try {
                  const json = JSON.parse(reader.result)
                  self.failureFrom(json)
                } catch (parseError) {
                  self.failureFrom(reader)
                }
              }, false)
            } else {
              self.failureFrom(error)
            }
          },
          error: (message, options) => notify(message, options, 'alert'),
          warning: (message, options) => notify(message, options, 'warning'),
          removeAll: () => notifier.closeToasts(),
          swalError: (message, response) => {
            const escapedMessage = utilService.stripHtmlTags(utilService.htmlKeepBody(message))
            const statusText = httpStatus[response.status || '']
            return $window.Swal.fire($translate.instant('NotifyErrorTitle'), `<h3>${statusText}</h3>${escapedMessage}`, 'error')
          }
        }
        return self
      }
    ]
  )
