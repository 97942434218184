angular.module('mosaik.services')
  .factory('memoService',
    ['$http',
      function ($http) {
        return {
          memos: function () {
            var url = '/api/memo'

            return $http.get(url)
              .then(function (response) {
                return response.data
              })
          }
        }
      }]
  )