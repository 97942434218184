angular.module('mosaik.controllers')
  .controller('inviteEmailController',
    ['$scope', 'sessionState', '$state', 'setup', 'organizations', 'tagService', '$window', '$translate', 'sessionStateService', '$timeout', 'translationService', 'inviteService',
      function ($scope, sessionState, $state, setup, organizations, tagService, $window, $translate, sessionStateService, $timeout, translationService, inviteService) {
        if (!sessionState.canInvite) {
          return $state.go('landing', {}, { reload: 'secure' })
        }

        $scope.isRoot = sessionState.isRoot
        $scope.tags = []
        $scope.organizations = organizations
        $scope.selectedTags = []
        $scope.selectedOrg = $scope.defaultOrgid = sessionState.orgid
        $scope.languages = translationService.getLanguages()
        $scope.selectedLanguage = translationService.getCurrentLanguage().code
        $scope.priorityAutoFilters = [{
          value: '0',
          name: $translate.instant('ShowAll')
        }, {
          value: '1',
          name: $translate.instant('Priorities')
        }]

        const emailInput = $window.document.getElementById('email')

        emailInput.addEventListener('paste', event => {
          event.preventDefault()

          let pastedText = event.clipboardData.getData('text/plain')
          // reformat if the list contains no comma
          if (!pastedText.includes(',')) {
            pastedText = pastedText.replace(/\r\n/g, ' ').replace(/\s/g, ',').replace(/\,*\s*$/g, '')

            // Insert modified text in input      
            const selection = $window.getSelection()
            event.currentTarget.value = event.currentTarget.value.replace(selection.toString() || '', pastedText)
          }
          $scope.email = event.currentTarget.value
        })

        setFormDefaultValue()
        loadTags()

        function setFormDefaultValue() {
          $scope.email = ''
          $scope.emails = []
          $scope.stats = {
            count: 0
          }
          $scope.selectedTags = []
          $scope.isAdmin = false
          $scope.addMessage = false
          $scope.message = null
          $scope.priorityAutoFilter = '0'
          $scope.expiredInDays = setup.defaultExpiredInDays
        }

        function resetFormData() {
          $timeout(() => {
            setFormDefaultValue()
            validateInputsState()
            angular.element('#tags-selectpicker').selectpicker('deselectAll')
            angular.element('#advancedOptions').collapse('hide')
            inviteService.inputSwitchOff()
            setFocusOnEmail()
          })
        }

        angular.element('#advancedOptions').on('hide.bs.collapse', () => {
          $timeout(() => {
            $scope.priorityAutoFilter = '0'
            $scope.expiredInDays = setup.defaultExpiredInDays
          })
        })

        $timeout(() => {
          $scope.show = true
          validateInputsState()
          setFocusOnEmail()
        })

        function setFocusOnEmail() {
          $timeout(() => emailInput.focus(), 150)
        }

        $scope.addEmail = () => {
          const emails = formatEmailInput()

          if (!validateEmailInput()) {
            return false
          }
          extractEmailInput(emails)
          validateInputsState()
        }

        function formatEmailInput() {
          let emails = $scope.email ? $scope.email : emailInput.value
          // replace comma-spaces by space
          emails = emails.replace(/[\,\s]/g, ' ')
          // replace space by comma-space
          emails = emails.replace(/[\s]/g, ', ')
          // remove last comma or space
          emails = emails.replace(/\,*\s*$/g, '')

          emailInput.value = emails
          return emails.split(',')
        }

        function validateEmailInput() {
          if (emailInput.checkValidity() === false) {
            if (emailInput.reportValidity) {
              emailInput.reportValidity() // chrome and FF supports it
              return false
            }
            // otherwise show custom message
            $scope.emailIsInvalid = true
            return false
          }
          $scope.emailIsInvalid = false
          return true
        }

        function extractEmailInput(emails) {
          for (let i = 0; i < emails.length; i++) {
            const email = emails[i].trim().toLowerCase()
            if ($scope.emails.indexOf(email) === -1) {
              $scope.emails.push(email)
              $scope.stats.count++
            }
          }
          $scope.email = ''
        }

        $scope.removeEmail = email => {
          const idx = $scope.emails.indexOf(email)
          if (idx !== -1) {
            $scope.emails.splice(idx, 1)
            $scope.stats.count--
            validateInputsState()
          }
        }

        $scope.sendInvites = () => {
          // tell server to send invitations!
          const data = {
            tags: $scope.selectedTags,
            emails: $scope.emails,
            isAdmin: $scope.isAdmin,
            orgid: $scope.selectedOrg,
            locale: $scope.selectedLanguage,
            message: $scope.addMessage ? $scope.message : null,
            priorityAutoFilter: $scope.priorityAutoFilter,
            expiredInDays: $scope.expiredInDays
          }

          $window.Swal.fire({
            title: $translate.instant('SendInfo'),
            text: $translate.instant('SendInfoMessage', $scope.stats),
            icon: 'info',
            showConfirmButton: false,
            showCancelButton: false,
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            preConfirm: () => {
              return new Promise(resolve => inviteService.create('email', data, resolve))
            }
          }).then((result) => {
            const response = result.value
            const data = response.data
            const status = response.status

            if (!result.value) { // dismiss
              return
            }
            // clean cache
            $scope.$emit('data-modified', 'invite')

            if ((status === 200 || status === 201) && data) {
              const successMessage = $translate.instant('SendSuccessMessage', { acceptedCount: data.acceptedCount })
              let rejectedMessage = ''
              if (data.rejectedCount) {
                rejectedMessage += '<br><br>' + $translate.instant('SendSuccessRejectedMessage', {
                  rejectedCount: data.rejectedCount,
                  rejected: data.rejected.map(reject => reject.to).join(', ')
                })
              }
              return $window.Swal.fire({
                title: data.rejectedCount === 0 ? $translate.instant('SendSuccess') : $translate.instant('SendSuccessWithFailed'),
                html: successMessage + rejectedMessage,
                icon: data.rejectedCount === 0 ? 'success' : 'warning',
                showCancelButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                cancelButtonText: $translate.instant('SendSuccessCancel'),
                confirmButtonText: $translate.instant('SendSuccessConfirm')
              }).then((result) => {
                if (result.dismiss === 'cancel') {
                  return $state.go('dashboardInviteStatus', {}, { reload: 'secure' })
                }
                resetFormData()
              })
            }
            return $window.Swal.fire($translate.instant('SendError'), data && data.message ? data.message : response.statusText, 'error')
              .then(() => sessionStateService.validSessionOrRedirect())
          }
          )

          // start the request
          $window.swal.clickConfirm()
        }

        function validateInputsState() {
          const disabled = ($scope.stats.count === 0)
          validateSwitchesState(disabled)
          validateSelectState(disabled)
        }

        function validateSwitchesState(disabled) {
          const switches = $('input.checkbox-switch')
          switches.bootstrapSwitch('disabled', disabled)
        }

        function validateSelectState(disabled) {
          const select = $('.selects')
          select.prop('disabled', disabled)
          select.selectpicker('refresh')
        }

        function loadTags() {
          tagService.tags($scope.selectedOrg)
            .then(tags => {
              $scope.tags = tags
              $scope.selectedTags = []
              $timeout(() => $scope.$broadcast('picker-dataset-change', 'selectedTags'))
            })
        }

        $scope.$on('select-modified', (event, data) => {
          if (data.id === 'organizations-selectpicker') loadTags()
        })

        $scope.$on('invite-is-admin', (event, state) => {
          $scope.isAdmin = state
        })

        $scope.$on('invite-add-message', (event, state) => {
          $scope.addMessage = state
          angular.element('#custom-message').collapse(state ? 'show' : 'hide')
          if (state) {
            return angular.element('#message-area').focus()
          }
          setFocusOnEmail()
        })
      }
    ])