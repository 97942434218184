angular.module('mosaik.services')
  .factory('labelService',
    ['utilService',
      function (utilService) {
        return {
          getLabelCustomStyleAsText: function (color, isTextColorDefault) {
            if (color && utilService.isClassicTheme()) {
              return 'background-color:' + color
            }
            if (color) {
              return 'border-color:' + color + ';color:'+ (isTextColorDefault ? 'inherit' : color) + ';'
            }
            if (isTextColorDefault) {
              return 'color:inherit;'
            }
            return ''
          },
          getLabelCustomStyleAsObj: function (color, isTextColorDefault) {
            var result = {}
            if (color && utilService.isClassicTheme()) {
              result['background-color'] = color
              return result
            }
            if (color) {
              result['border-color'] = color
              result['color'] = (isTextColorDefault ? 'inherit' : color)
              return result
            }
            if (isTextColorDefault) {
              result['color'] = 'inherit'
            }
            return result
          }
        }
      }]
  )