angular.module('mosaik.services')
  .factory('animateService',
    [function () {
      var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend'

      return {
        animateCss: function (selector, animationName, afterAnimationEnd) {
          $(selector).addClass('animate__animated ' + animationName).one(animationEnd, function () {
            $(this).removeClass('animate__animated ' + animationName)
            if (afterAnimationEnd) {
              afterAnimationEnd()
            }
          })
        }
      }
    }]
)
