angular.module('mosaik.controllers')
  .controller('landingController',
    ['$scope', '$state', '$stateParams', '$rootScope', 'translationService', 'sessionStateService',
      function ($scope, $state, $stateParams, $rootScope, translationService, sessionStateService) {

        if ($stateParams.moduleName) {
          // redirect parameters are presents
          $rootScope.requestedState = {
            base: decodeURIComponent($stateParams.moduleName),
            name: $stateParams.stateName,
            params: JSON.parse(decodeURIComponent($stateParams.stateParams))
          }
        }
        // check for locale change request
        const changeLocale = new Promise (resolve => $stateParams.locale ? translationService.change($stateParams.locale, resolve) : resolve())
        // request the best state to land to
        changeLocale.then(() => sessionStateService.getAndGo('/api/passport/landing'))
      }
    ]
  )
