angular.module('mosaik.controllers')
  .controller('memosController',
    ['$scope', '$state', 'sessionState', 'memos', '$translate', '$timeout', 'translationService', 'tutorialService',
      function ($scope, $state, sessionState, memos, $translate, $timeout, translationService, tutorialService) {
        $scope.canAccessMemo = sessionState.canAccessMemo
        $scope.memos = memos
        $scope.showEmptyTitle = ($scope.memos.length === 0)
        // isotope grid options
        var isotopeOptions = {
          masonry: {
            columnWidth: null,
            fitWidth: true
          },
          initLayout: false
        }

        // Initialize the grid
        // wait for the repeat to be completed
        $scope.$on('repeat-done', function () {
          // initialize
          isotopeGrid = angular.element('.grid').isotope(isotopeOptions)
          // bind events
          isotopeGrid.one('arrangeComplete', function () {
            $scope.$broadcast('animate-grid-once')
          })

          // show tutorial and show the memos
          $timeout(function assignContentsList() {
            tutorialService.showIfFirstConnect(sessionState, function () {
              $scope.showFadeIn = true
              // then reload layout and sort on next tick
              $timeout(function () {
                // Make sure to load all items and relayout
                isotopeGrid.isotope('reloadItems')
                isotopeGrid.isotope()
              })
            })
          })
        })

        $scope.showMemoDetail = function (memo) {
          $state.go('memo', { memoid: memo.id }, { reload: 'secure' })
        }

        $scope.$on('locale-changed', function (event, args) {
          $state.go('memos', {}, { reload: true })
        })

        $scope.dateToHuman = function (date) {
          return translationService.dateToHumanLongFormat(date)
        }
      }
    ]
  )
