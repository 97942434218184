angular.module('mosaik.services')
  .factory('modalService',
    ['$window', '$translate',
      function ($window, $translate) {
        return {
          /**
           * 
           * @param {String} src URL source for the iframe content
           * @param {Object} options Popup options: showPrint: Boolean,
           * @returns 
           */
          popupFrame: (src, options = {}) => {
            const instance = $window.vex.open({
              className: 'vex-fullpage vex-theme-mosaik',
              contentClassName: 'vex-content-fullpage',
              unsafeContent: `<iframe class="vex-content-iframe vex-content-iframe-fullpage" src="${src}"></iframe>
              ${options.showPrint ? `<div class="vex-print" data-toggle="tooltip" title="${$translate.instant('Print')}"><i class="fa fa-print" aria-hidden="true"></i></div>` : ''}`
            })
            // add print listenerà
            if (options.showPrint) {
              const print = instance.contentEl.getElementsByClassName('vex-print')[0]
              const iframe = instance.contentEl.getElementsByClassName('vex-content-iframe')[0]
              print.addEventListener('click', () => {
                (iframe.contentDocument.getElementsByTagName('iframe')[0] || iframe).contentWindow.print() // print the subiframe of the main iframe.
              })
            }
            return instance
          }
        }
      }]
  )