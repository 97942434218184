angular.module('mosaik.services')
  .factory('redactorService',
    ['$http',
      function ($http) {
        var self = {
          activateVideoAutoplay: function (articleElement) {
            angular.element(articleElement).find('.video-container.video-autoplay iframe').each(function (index, element) {
              var src = element.getAttribute('src')
              element.setAttribute('src', src + (src.indexOf('?') === -1 ? '?' : '&') + 'autoplay=1')
            })
          },

          /**
           * 
           * @param {*} viewElement The page view element, that contain or will contain the video player(s)
           * @param {*} videoContainerElement Optional. The direct element that contains the video player(s). It may not have been appended to the viewElement yet
           * @param {*} percentageOfViewWidth Optional. Percentage of the view element width to consider for the size calculation. Default is 80%.
           * @returns 
           */
          setVideoSize: function (viewElement, videoContainerElement, percentageOfViewWidth) {
            if (!viewElement) {
              return
            }
            // apply heigth to all video player in article
            var videoPlayers = (videoContainerElement || viewElement).find('.video-container')
            videoPlayers.height(Math.floor(viewElement.width() * 0.5625 * (percentageOfViewWidth || 0.80))) // get a 16:9 ratio, reduce resolution for padding
          }
        }
        return self
      }]
  )