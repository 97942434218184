angular.module('mosaik.controllers')
  .controller('changeTemporaryPasswordController',
  [
    '$scope', '$window', '$translate', '$timeout', 'utilService', 'passwordService',
    function ($scope, $window, $translate, $timeout, utilService, passwordService) {
      $timeout(() => {
        $scope.show = true
        utilService.focusOnDesktop('new-password-1')
      }, 100)

      passwordService.validateStrategyAccessOrRedirect($scope)

      $scope.model = {
        password: '',
        confirmPassword: ''
      }
      $scope.constraints = {
        minLength: passwordService.getMinLength()
      }

      $scope.togglePassword = () => $scope.showPassword = !$scope.showPassword
      $scope.toggleConfirmPassword = () => $scope.showConfirmPassword = !$scope.showConfirmPassword

      // Scope function to perform actual login request to server
      $scope.change = gotoRedirectState => {
        const options = {
          gotoRedirectState: true,
          scope: $scope,
          model: $scope.model,
          api: '/api/password/changeTemporary/me'
        }

        passwordService.changeForMe(options)
      }
    }
  ]
  )
