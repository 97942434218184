angular.module('mosaik.directives')
  .directive('searchFilterField',
    ['$location', '$timeout', 'utilService',
      function ($location, $timeout, utilService) {
        // shared scope with parent, because ng-change and ng-model use
        // Parent scope should have a search property and and a search callback function.
        return {
          scope: {
            search: '='
          },
          link: function (scope, element, attrs) {
            var wrapper = angular.element(element)
            var input = wrapper.find('input')
            var queryValue = $location.search().search
            // get and set the search query value (includes empty '') from the quersy string
            if (queryValue != null) {
              // timeout required here to make sure element's value is set correctly on scope.$apply
              scope.search = queryValue
              $timeout(function () {
                setElementValue(queryValue)
              })
            }

            scope.$on('filter-param-loaded', filterParamChangeEvent)
            scope.$on('filter-param-clear', filterParamChangeEvent)

            element.on('input change', utilService.debounce(350, doSearch))

            function filterParamChangeEvent(event, args) {
              if (args.key !== 'search') {
                return
              }
              setElementValue(args.value)
            }

            function doSearch(event) {
              var val = element.val()
              // same value or already set
              if (scope.search === val) {
                return
              }
              scope.search = val
              setLocation()
              scope.$emit('search-modified', scope.search)
            }

            function setLocation() {
              if (attrs.doNotTrack != null) {
                return
              }
              $location.search('search', scope.search ? scope.search : null)
              $location.replace()
            }

            function setElementValue(value) {
              scope.search = value
              element.val(value)
            }
          }
        }
      }
    ])