angular.module('mosaik.directives')
  .directive('searchClear',
    ['$timeout', 'utilService',
      ($timeout, utilService) => {
        return {
          link: (scope, element, attrs) => {
            const wrapper = angular.element(element)
            const input = wrapper.find('input')
            const clear = wrapper.find('.form-control-clear')
            const clearState = () => input.val('').focus().change()
            const updateDomElement = event => {
              $timeout(() => {
                const visible = Boolean(input.val())
                wrapper.find('.input-icon').toggleClass('hidden', visible)
                wrapper.find('.form-control-clear').toggleClass('hidden', !visible)
              })
            }
            const filterParamChangeEvent = (event, args) => args.key !== 'search' ? undefined : updateDomElement()

            updateDomElement()
            input.on('input change',  utilService.debounce(350, updateDomElement))
            clear.on('click', clearState)

            scope.$on('filter-param-loaded', filterParamChangeEvent)
            scope.$on('filter-param-clear', filterParamChangeEvent)         
          }
        }
      }
    ])