angular.module('mosaik.services')
  .factory('logoService',
    [
      '$translate', '$timeout',
      function ($translate, $timeout) {
        return {
          setLogo: () => {
            // load the top left logo with a delay, some config struggle to load the dictionnary
            $timeout(() => {
              const container = angular.element('#navbar-logo')
              const src = $translate.instant('MainLogoSrc')
              const srcset = $translate.instant('MainLogoSrcset')
              const paddingTop = $translate.instant('MainLogoPaddingTop')
              const width = $translate.instant('MainLogoWidth')
              const height = $translate.instant('MainLogoHeight')
              const img = '<img src="' + src + '" srcset="' + srcset + '" alt="logo" style="padding-top:' + paddingTop + '; width:' + width + '; height:' + height + ';"></img>'
              container.empty()
              container.append(img)
            }, 150)
          }
        }
      }
    ]
)
