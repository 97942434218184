angular.module('mosaik.services')
  .factory('kbTopicService',
    ['sessionStateService', 'httpService',
      function (sessionStateService, httpService) {
        return {
          topics: function () {
            return sessionStateService.lastSession()
              .then(function (currentState) {
                const url = '/api/kb-topic'
                return httpService.get(url, { dataSubKey: 'data' })
              })
          }
        }
      }]
  )