angular.module('mosaik.services')
  .factory('contentDeployService',
    ['$window', '$translate', 'utilService',
      function ($window, $translate, utilService) {
        return {

          runRemoteCmd: function (title, html, action) {
            return $window.Swal.fire({
              title: title.replace(/(?:\n)/g, ' '),
              html,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: $translate.instant('Yes'),
              cancelButtonText: $translate.instant('No'),
              allowOutsideClick: false,
              customClass: {
                popup: 'swal2-popup-command',
                htmlContainer: 'swal2-html-container-monospace'
              },
              preConfirm: function () {
                $window.Swal.update({ showCancelButton: false, showConfirmButton: false })
                $window.Swal.showLoading()

                return new Promise(function (resolve) {
                  var xhr = new XMLHttpRequest()
                  var swalContainer = angular.element('.swal2-container')[0]
                  xhr.open('PUT', '/console-api/' + action, true)

                  xhr.onload = function () {
                    resolve({ data: this.responseText })
                  };

                  xhr.onreadystatechange = function () {
                    if (this.readyState === 3) {
                      $window.Swal.update({ html: html + '<br>' + utilService.textToHtmlAlignLeft(this.responseText) })
                      $window.Swal.showLoading()
                      // scroll down
                      swalContainer.scrollTop = swalContainer.scrollHeight
                    }
                  }

                  xhr.onerror = function (event) {
                    resolve({ error: true, data: `An error occured. Response: ${this.responseText}` })
                  }

                  xhr.onabort = function (event) {
                    resolve({ error: true, abort: true, type: 'abort', data: $translate.instant('WarningRequestAborted') })
                  }

                  xhr.send()
                })
              }
            }).then(
              function confirm(result) {
                const response = result.value
                let data = ''

                if (!result.value) { // dismiss
                  return
                }

                if (response.data) {
                  data = utilService.textToHtmlAlignLeft(response.data)
                }
                // success
                if (!response.error && !data.includes('Error') && !data.includes('Warning')) {
                  return $window.Swal.fire({
                    title: $translate.instant('Success'), 
                    html: data, 
                    icon: 'success',
                    customClass: {
                      popup: 'swal2-popup-command',
                      htmlContainer: 'swal2-html-container-monospace'
                    },
                  })
                }
                // warning
                if (response.abort || data.includes('Warning')) {
                  return $window.Swal.fire({
                    title: $translate.instant('NotifyWarningTitle'), 
                    html: data, 
                    icon: 'warning',
                    customClass: {
                      popup: 'swal2-popup-command',
                      htmlContainer: 'swal2-html-container-monospace'
                    }
                  })
                }
                // error
                $window.Swal.fire({
                  title: $translate.instant('NotifyErrorTitle'), 
                  html: data || response.statusText, 
                  icon: 'error',
                  customClass: {
                    popup: 'swal2-popup-command',
                    htmlContainer: 'swal2-html-container-monospace'
                  }
                })
              }
            )
          }

        }
      }]
  )