angular.module('mosaik.controllers')
  .controller('dashboardTagController',
    ['$scope', '$window', 'sessionState', '$translate', '$timeout', '$filter', 'datatablesHelper', 'organizations', 'utilService', '$interpolate', 'translationService',
      function ($scope, $window, sessionState, $translate, $timeout, $filter, datatablesHelper, organizations, utilService, $interpolate, translationService) {
        // access rights
        $scope.canAccessDashboard = sessionState.canAccessDashboard
        $scope.isRoot = sessionState.isRoot
        // parameters
        const tableid = '#datatables-tag'
        const tableElement = angular.element(tableid)
        const api = '/admin-api/tag'
        $scope.queryHidden = {
          orgid: sessionState.orgidSelector,
          format: 'datatables',
          loadLocales: 1
        }
        $scope.queryDefault = {
          state: 'all'
        }
        $scope.query = {
          state: '',
          organizations: [],
          search: ''
        }
        // data
        $scope.organizations = organizations
        let defaultColor = '#317ab7'
        const defaultName = ['defaultName']
        const localeNameFieldNames = []
        const localeDescriptionFieldNames = []
        const defaultLocaleFieldName = 'locales.' + translationService.getLocaleShort() + '.name'
        const advancedOptionsFields = ['advancedOptionsDivider', 'isVisibleOnCertificate', 'canOptIn']
        if (!utilService.isClassicTheme()) {
          advancedOptionsFields.push('isTextColorDefault')
        }
        // editors
        let editor
        // DT table
        $scope.table

        $timeout(() => datatablesHelper.init($scope, tableid, api, '', initializeTable))

        function initializeTable() {
          const languages = translationService.getLanguages()
          const editorFields = [{
            name: 'id',
            type: 'hidden',
          }, {
            label: $translate.instant('TagNameTitle'),
            name: 'nameDivider',
            type: 'title'
          }, {
            label: $translate.instant('DifferentNameForEachLocale'),
            name: 'differentNameForEachLocale',
            type: 'radio',
            options: datatablesHelper.getYesNoOptionsLabels(),
            default: 0
          }, {
            label: $translate.instant('Name'),
            name: 'defaultName',
            fieldInfo: $translate.instant('CreateTagsMultipleInfo'),
            attr: {
              required: true,
              name: 'defaultName'
            }
          }]

          // name: dynamically add name fields: one for each locales
          languages.forEach(element => {
            const fieldName = 'locales.' + element.code + '.name'
            editorFields.push({
              label: $translate.instant('Name') + ' (' + $translate.instant(element.name) + ')',
              name: fieldName,
              attr: {
                required: true,
                pattern: utilService.getStrictNoSpecialCharPattern(),
                name: fieldName
              }
            })
            localeNameFieldNames.push(fieldName)
          })

          // editor fields: color + org
          editorFields.push({
            label: $translate.instant('OtherOptionsTitle'),
            name: 'otherDivider',
            type: 'title'
          }, {
            label: $translate.instant('Color'),
            name: 'color',
            type: 'color',
            default: defaultColor
          }, {
            label: $translate.instant('BelongsTo'),
            name: 'visibleOnlyByOrgid',
            type: 'selectPicker',
            options: organizations,
            optionsPair: {
              label: 'name',
              value: 'id'
            },
            renderLabel: datatablesHelper.renderLabelOrg
          }, {
            name: 'orgColorDividerBottom',
            type: 'divider'
          })

          // complete editor's fields with advanced options
          // description: dynamically add description fields: one for each locales
          editorFields.push({
            label: `<a>${$translate.instant('AdvancedOptions')}</a>`,
            name: 'optionsDividerTop',
            type: 'linkTitle'
          }, {
            name: 'descriptionDivider',
            type: 'divider'
          })
          localeDescriptionFieldNames.push('descriptionDivider')
          languages.forEach(element => {
            const fieldName = 'locales.' + element.code + '.description'
            editorFields.push({
              label: $translate.instant('Description') + ' (' + $translate.instant(element.name) + ')',
              name: fieldName,
              attr: {
                name: fieldName
              }
            })
            localeDescriptionFieldNames.push(fieldName)
          })

          // other advanced options fields
          editorFields.push({
            name: 'advancedOptionsDivider',
            type: 'divider'
          }, {
            label: $translate.instant('IsVisibleOnCertificate'),
            name: 'isVisibleOnCertificate',
            type: 'radio',
            options: datatablesHelper.getYesNoOptionsLabels(),
            default: 0
          }, {
            label: $translate.instant('UserCanOptIn'),
            name: 'canOptIn',
            type: 'radio',
            options: datatablesHelper.getYesNoOptionsLabels(),
            default: 0
          })
          // advanced field in non classic theme only
          if (!utilService.isClassicTheme()) {
            editorFields.push({
              label: $translate.instant('IsTextColorDefault'),
              name: 'isTextColorDefault',
              type: 'radio',
              options: datatablesHelper.getYesNoOptionsLabels(),
              default: 0
            })
          }

          editor = datatablesHelper.createEditor(angular.merge(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: editorFields,
            ajax: {
              create: {
                type: 'PUT',
                url: api + '/create'
              },
              edit: {
                type: 'PUT',
                url: api + '/update/_id_'
              },
              remove: {
                type: 'DELETE',
                url: api + '/_id_'
              }
            }
          }))

          // bind defaultName to all locales fields (to sync values bc the fields are required)
          const onDependentDefaultName = value => {
            // set locale fields on defaultName value
            languages.forEach(element => {
              editor.field('locales.' + element.code + '.name').val(value)
            })
            return {}
          }
          editor.dependent('defaultName', onDependentDefaultName, { event: 'keyup' })

          //bind the current locale field to the defaultName field (to sync values bc the fields are required)
          const onDependentLocaleNames = value => {
            editor.field('defaultName').val(value)
            return {}
          }
          editor.dependent(defaultLocaleFieldName, onDependentLocaleNames, { event: 'keyup' })

          editor.dependent('differentNameForEachLocale', (value, data) => {
            const multipleSelected = data.row && data.rows.length > 1
            const editMode = data.row != null
            const titleDivider = ['nameDivider']

            // check if user wants to enter a different name for each language
            if (value === 1) {
              return {
                show: localeNameFieldNames.concat(titleDivider),
                enable: localeNameFieldNames.concat(titleDivider),
                hide: defaultName,
                disable: defaultName
              }
            }

            if (editMode && multipleSelected) {
              const hideAndDisable = localeNameFieldNames.concat(defaultName, titleDivider)
              return {
                hide: hideAndDisable,
                disable: hideAndDisable
              }
            }
            return {
              enable: defaultName.concat(titleDivider),
              show: defaultName.concat(titleDivider),
              hide: localeNameFieldNames,
              disable: localeNameFieldNames
            }
          })

          //bind the current locale field to the defaultName field (to sync values bc the fields are required)
          editor.dependent('optionsDividerTop', (value, data) => {
            // fields already visible
            if (editor.displayed().indexOf('canOptIn') !== -1) {
              return {
                hide: advancedOptionsFields.concat(localeDescriptionFieldNames)
              }
            }
            //show the fields
            return {
              show: advancedOptionsFields.concat(localeDescriptionFieldNames)
            }
          }, { event: 'click' })

          // Paste events binding on name fields (one by one for keeping field name)
          const nameFields = []
          localeNameFieldNames.concat(defaultName).forEach(name => {
            nameFields.push({ name, node: editor.node(name) })
          })
          const onPasteName = (event, fieldName) => {
            event.preventDefault()

            let pastedText = event.clipboardData.getData('text/plain')
            // reformat if the list contains no comma
            if (!pastedText.includes(',')) {
              pastedText = pastedText.replace(/\r\n|\r|\n/g, '\t').replace(/\t/g, ',').replace(/\,*\s*$/g, '')

              // Insert modified text in input
              const selection = $window.getSelection()
              const newValue = event.currentTarget.value.replace(selection.toString() || '', pastedText)
              event.currentTarget.value = newValue // must set directly in input
              // call dependent events manually
              if (fieldName === defaultName[0]) onDependentDefaultName(newValue)
              if (fieldName === defaultLocaleFieldName) onDependentLocaleNames(newValue)
              onDependentDefaultName()
            }
          }
          
          nameFields.forEach(field => {
            // listen on paste for each name fields
            field.node.querySelector('input').addEventListener('paste', event => onPasteName(event, field.name))
          })

          // other editor events
          editor.on('preOpen', (e, mode, action) => {
            if (action === 'create') {
              editor.title($translate.instant('CreateTags'))
            }
            if (organizations.length === 1) {
              editor.field('visibleOnlyByOrgid').hide()
            }
          })

          editor.on('initCreate', () => {
            editor.enable('defaultName')
            editor.show('defaultName')
            editor.field('differentNameForEachLocale').val(0)
            editor.enable('differentNameForEachLocale')
            editor.show('differentNameForEachLocale')
            editor.field('color').val(defaultColor)
            localeNameFieldNames.forEach(fieldName => {
              editor.enable(fieldName)
              editor.show(fieldName)
            })
            // hide advanced options by default
            localeDescriptionFieldNames.concat(advancedOptionsFields).forEach(fieldName => {
              editor.hide(fieldName)
            })
          })

          editor.on('initEdit', (e, node, data, items, type) => {
            editor.hide('defaultName')
            editor.disable('defaultName')
            editor.hide('differentNameForEachLocale')
            editor.disable('differentNameForEachLocale')
            // do not edit names on multiple selection
            editor.field('differentNameForEachLocale').val(items.length > 1 ? 0 : 1)
            // show advanced options if at least one value is set
            const descriptionsAreEmpty = Object.keys(data.locales).every(locale => {
              return !data.locales[locale].description
            })
            if (descriptionsAreEmpty && !data.isVisibleOnCertificate && !data.canOptIn && (!data.isTextColorDefault || utilService.isClassicTheme())) {
              // hide advanced options by default
              localeDescriptionFieldNames.concat(advancedOptionsFields).forEach(fieldName => {
                editor.hide(fieldName)
              })
            } else {
              // hide advanced options by default
              localeDescriptionFieldNames.concat(advancedOptionsFields).forEach(fieldName => {
                editor.show(fieldName)
              })
            }
          })

          editor.on('preSubmit', (e, data, action) => datatablesHelper.onPreSubmit(action, editor))

          editor.on('postSubmit', (e, json, data, action) => {
            if (action === 'create' || action === 'edit') {
              const keys = Object.keys(data.data)
              // only reset default color when editing one object
              if (keys.length === 1) {
                defaultColor = data.data[keys[0]].color
              }
            }
            $scope.$emit('data-modified', 'tag')
          })

          const exportOptions = { columns: [0, 1, 3, 4, 5] }
          const exportMessage = $translate.instant('UserTags')

          const datatableParams = angular.extend(datatablesHelper.getDatatablesParams({
            editor,
            exportOptions,
            exportMessage,
            extraDTOptions: { serverSide: true }
          }), {
            columns: [
              { data: 'id', visible: false, className: 'never', searchable: false },
              {
                data: 'name',
                render: (data, type, full) => datatablesHelper.renderOneTag(full)
              },
              { data: 'visibleOnlyByOrgid', visible: false, className: 'never', searchable: false },
              {
                data: 'visibleOnlyByOrgName', // orgname and internalOrgid
                render: (data, type, full) => {
                  if (!full.visibleOnlyByOrgid) {
                    return $translate.instant('EveryOrganization')
                  }
                  const inactive = !full.orgActive ? ' <span class="label-mosaik label-mosaik-danger">' + $translate.instant('Inactive') + '</span>' : ''
                  return data + ' <small class="text-id">' + full.visibleOnlyByInternalOrgid + '<small>' + inactive
                }
              },
              {
                data: 'isVisibleOnCertificate',
                render: datatablesHelper.renderYesNo
              },
              {
                data: 'canOptIn',
                render: datatablesHelper.renderYesNo
              },
              { data: 'color', visible: false, className: 'never', searchable: false },
              { // render action row menu
                data: null,
                searchable: false,
                orderable: false,
                render: (row, type) => {
                  if (!row.visibleOnlyByOrgid) {
                    return ''
                  }
                  const template = `
                    <div class="dt-buttons btn-group dt-button-actions dropdown">
                      <button type="button" class="btn btn-defaut dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-h"></i>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-right">
                        <li class="dt-button"><a class="action-item action-edit" data-id="${row.id}">{{"Edit" | translate}}</a></li>
                        <li class="dt-button"><a class="action-item action-delete" data-id="${row.id}">{{"Delete" | translate}}</a></li>
                      </ul>
                    </div>`
                  const compiled = ($interpolate(template)($scope))
                  return compiled
                }
              }
            ],
            order: [[2], [4, 'desc'], [1]], // visibleOnlyByOrgid, isVisibleOnCertificate desc, name
            select: true
          })
          datatablesHelper.createAndSetTable(datatableParams)

          // disable row select for rows that cannot be edited or deleted
          $scope.table.on('user-select', (e, dt, type, cell, originalEvent) => {
            const row = dt.row(cell.index().row)
            if (row.data().visibleOnlyByOrgid == null) {
              e.preventDefault()
            }
          })
        }

        tableElement.on('click', 'a.action-edit:not(.disabled)', function (e) {
          editor.edit(datatablesHelper.selectAndGetClosestRow($scope.table, this).indexes(), true, {
            title: editor.i18n.edit.title,
            buttons: editor.i18n.edit.submit
          })
        })

        tableElement.on('click', 'a.action-delete:not(.disabled)', function (e) {
          editor.remove(datatablesHelper.selectAndGetClosestRow($scope.table, this).indexes(), true, {
            title: editor.i18n.remove.title,
            message: editor.i18n.remove.confirm['1'],
            buttons: editor.i18n.remove.submit
          })
        })
      }
    ])
