angular.module('mosaik.controllers')

  .directive('repeatUserTags',
    ['$http', '$translate', '$timeout',
      function ($http, $translate, $timeout) {
        return {
          link: function (scope, element, attrs) {
            var checkbox = element.find('.checkbox-switch')

            checkbox.attr('data-on-text', $translate.instant('Yes'))
            checkbox.attr('data-off-text', $translate.instant('No'))
            checkbox.attr('checked', scope.tag.subscribed === 1)
            checkbox.bootstrapSwitch()

            // actions when using checkboxes
            checkbox.on('switchChange.bootstrapSwitch', function onSwitchChange(event, state) {
              var tagid = this.getAttribute('data-tagid')

              if (state) {
                $http.put('/api/user/me/tag/' + tagid)
              } else {
                $http.delete('/api/user/me/tag/' + tagid)
              }
              scope.$emit('data-modified', 'tag')
            })

            // Setup tooltip attributes when using checkboxes
            //   data-toggle="tooltip" data-placement="bottom" title="{{tag.description}}"
            $timeout(function () {
              if (scope.tag.description) {
                var label = element.find('span.label-with-tooltip')
                label.attr('id', 'tag-' + scope.tag.id)
                label.tooltip({
                  title: scope.tag.description,
                  placement: 'bottom'
                })
                label.css('cursor', 'help')
              }
              if (scope.$last) {
                angular.element('[data-toggle="tooltip"]').tooltip()
              }
            })
          }
        }
      }
    ])

  .directive('userTagsNext',
    ['$state', 'sessionStateService',
      function ($state, sessionStateService) {
        return {
          restrict: 'A',
          scope: false,
          link: (scope, element, attrs) => {
            const gotoNextState = () => {
              sessionStateService.getAndGo('/api/passport/landing/next/tags')
            }
            scope.$on('tags-are-empty', gotoNextState)
            element.on('click', gotoNextState)
          }
        }
      }
    ])

  .controller('tagsController',
    ['$scope', '$http', '$translate', '$timeout',
      function ($scope, $http, $translate, $timeout) {
        $scope.show = false
        // $scope.tags inherited from parent scope or found in $resolve ( just not injected)
        if (!$scope.tags)  $scope.tags = $scope.$resolve.tags
        $scope.tagsNotEmpty = $scope.tags.length !== 0
        $scope.showEmptyTitle = !$scope.tagsNotEmpty
        $scope.useSelectPicker = $scope.tags.length >= 10
        // data
        $scope.selectedTags = []

        if (!$scope.tagsNotEmpty) {
          $timeout(() => $scope.$emit('tags-are-empty'))
        } else {
          //activate the toggles
          $timeout(() => angular.element('.checkbox-switch').bootstrapSwitch({ state: angular.element(this).is(':checked') }).trigger('change'), 200)
        }
        //When using select picker, find selected (subscribed) tags
        if ($scope.useSelectPicker) {
          $scope.tags.forEach(tag => {
            if (tag.subscribed) $scope.selectedTags.push(tag.id.toString())
          })
        }

        $timeout(() => $scope.show = true)

        // actions when using select-picker dropdown list
        $scope.$on('select-modified', (event, data) => {
          if (data.id === 'tags-selectpicker') {
            const tagid = data.selected.id

            if (data.removed) {
              $http.delete(`/api/user/me/tag/${tagid}`)
            } else {
              $http.put(`/api/user/me/tag/${tagid}`)
            }
            $scope.$emit('data-modified', 'tag')
          }
        })
      }
    ]
  )
