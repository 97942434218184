angular.module('mosaik.controllers')
  .controller('passportController',
    ['$scope', '$timeout', '$rootScope', 'setup', '$window', '$http', '$stateParams', '$translate', 'captchaHelper', 'locationHelper', 'notificationHelper', 'passportService',
      function ($scope, $timeout, $rootScope, setup, $window, $http, $stateParams, $translate, captchaHelper, locationHelper, notificationHelper, passportService) {
        $scope.socialStrategies = setup.passport.socialStrategies
        $scope.localStrategies = setup.passport.localStrategies
        $scope.strategyCount = {
          total: setup.passport.localStrategies.count + setup.passport.socialStrategies.count
        }
        $scope.signinWithStrategy = passportService.signinWithStrategy

        passportService.setShortcuts(setup.passport.socialStrategies)

        $scope.messageVisible = $window.moment().isBefore($translate.instant('MessageLoginVisibleUntilDateTime'))
        const deadline = $translate.instant('MessageLoginDeadlineDateTime')
        $scope.reminingTime = deadline !== '' ? $window.moment(deadline).fromNow() : ''

        $scope.credentials = {
          email: '',
          password: '',
          captcha: '',
          requestedState: $rootScope.requestedState ? JSON.stringify($rootScope.requestedState) : undefined
        }

        $scope.captchaRequired = false
        const captchaConfig = {
          'elementid': 'recaptcha-container',
          'callback': function (response) {
            $scope.credentials.captcha = response
          },
          'expired-callback': function () {
            $scope.credentials.captcha = null
          }
        }

        //show messages
        notificationHelper.notifyFromParams($stateParams)

        $scope.signin = function () {
          if ($scope.credentials.email === 'login with social') {
            return passportService.signinWithStrategy($scope.credentials.password)
          }
          $scope.loading = true

          return $http.put('/api/passport/login/local',
            $scope.credentials
          )
            .then(success => {
              const url = locationHelper.formatRedirectToPath(success.data.redirectState)
              $window.location = url
            })
            .catch(error => {
              notificationHelper.failureFrom(error)

              if (error.data.captchaRequired) {
                if (!$scope.captchaRequired) {
                  // first time we need captcha for this form: we need to load      
                  captchaHelper.load(captchaConfig, true)
                } else {
                  captchaHelper.reset()
                }
                $timeout(function () {
                  $scope.captchaRequired = true
                })
              }

              if (!error.data.captchaRequired) {
                captchaHelper.remove()
                $scope.captchaRequired = false
              }
            })
            .finally(() => {
              $scope.credentials.password = '' //clean password from memory
              $scope.loading = false
            })
        }

        $scope.$on('locale-changed', function (event, args) {
          if ($scope.captchaRequired) {
            captchaHelper.load(captchaConfig)
          }
        })
      }
    ]
  )
