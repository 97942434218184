angular.module('mosaik.controllers')
  .controller('validateCodeController',
    ['$scope', '$state', '$stateParams', 'sessionState', '$http', '$window', 'notificationHelper', 'accessCodeService', '$rootScope', '$translate', 'captchaHelper', '$timeout', 'utilService',
      function ($scope, $state, $stateParams, sessionState, $http, $window, notificationHelper, accessCodeService, $rootScope, $translate, captchaHelper, $timeout, utilService) {
        $scope.captchaRequired = $stateParams.captchaRequired || false
        $timeout(() => {
          $scope.show = true
          utilService.focusOnDesktop('redeem-code')
        }, 100)
        $scope.redeem = {
          captcha: '',
          accessCode: $stateParams.accessCode
        }
        $scope.loading = false

        //show messages
        notificationHelper.notifyFromParams($stateParams)

        const captchaConfig = {
          'elementid': 'recaptcha-container',
          'callback': response => $scope.redeem.captcha = response,
          'expired-callback': () => $scope.redeem.captcha = null
        }

        if ($scope.captchaRequired) {
          // load the captcha immediately since it was required by previous state      
          captchaHelper.load(captchaConfig, true)
        }

        // Scope function to perform actual access code redeem
        $scope.validateCode = () => {
          notificationHelper.removeAll()
          $scope.loading = true

          accessCodeService.validateCode($scope.redeem)
            .then(success => {
              $rootScope.$broadcast('index-state-reload')
              $state.go(success.data.redirectState.name, success.data.redirectState.params, { reload: 'secure' })
            })
            .catch(error => {
              var data = error.data || {}
              // unhandled error
              if (error.message) {
                return notificationHelper.error(error.message)
              }
              // handling recaptha on failure attempt
              // activate captcha if required by backend
              if (data.captchaRequired) {
                if (!$scope.captchaRequired) {
                  // first time we need captcha for this form: we need to load      
                  captchaHelper.load(captchaConfig, true)
                } else {
                  captchaHelper.reset()
                }
                $timeout(() => $scope.captchaRequired = true)
              }

              if (!data.captchaRequired) {
                captchaHelper.remove()
                $scope.captchaRequired = false
              }
            })
            .finally(response => {
              $timeout(() => {
                $scope.loading = false
                $scope.location = false
              })
            })
        }

        $scope.$on('locale-changed', (event, args) => {
          if ($scope.captchaRequired) captchaHelper.load(captchaConfig)
        })
      }
    ]
  )
