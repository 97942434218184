angular.module('mosaik.controllers')
  .controller('inviteStatusController',
    ['$window', '$scope', '$state', '$stateParams', 'sessionState', 'groups', 'roles', 'organizations', '$translate', '$timeout', 'datatablesHelper', '$interpolate', 'formService', '$http', 'utilService', 'inviteService', 'notificationHelper', 'userRoleService',
      function ($window, $scope, $state, $stateParams, sessionState, groups, roles, organizations, $translate, $timeout, datatablesHelper, $interpolate, formService, $http, utilService, inviteService, notificationHelper, userRoleService) {
        // access rights
        $scope.canAccessDashboard = sessionState.canAccessDashboard
        $scope.canInvite = sessionState.canInvite
        $scope.isRoot = sessionState.isRoot
        // parameters
        const tableid = '#datatables-invite'
        const tableElement = angular.element(tableid)
        const api = {
          url: '/admin-api/invite',
          readType: 'POST',
        }
        $scope.queryHidden = {
          orgid: sessionState.orgidSelector,
          loadRoles: 1
        }
        $scope.queryDefault = {
          state: 'inProgress'
        }
        $scope.query = {
          state: '',
          deliveryState: '',
          groups: [],
          roles: [],
          organizations: [],
          search: ''
        }
        // data
        $scope.groups = groups
        const groupsAsObjects = utilService.mapObjectsByKey(groups)
        $scope.roles = roles
        $scope.organizations = organizations
        $scope.deliveryStates = inviteService.getDeliveryStates()
        // editors
        let editorReactivateAndSend
        let editorReactivateAndDownload
        let editorRemove
        // DT table
        $scope.table

        $timeout(() => datatablesHelper.init($scope, tableid, api, '', initializeTable))

        // the custom editors
        editorReactivateAndSend = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams(), {
          fields: [{
            type: 'hidden',
            name: 'id'
          }, {
            type: 'hidden',
            name: 'orgid'
          }, {
            label: $translate.instant('Recipient'),
            name: 'to',
          }],
          ajax: {
            edit: {
              type: 'PUT',
              url: '/admin-api/invite/reactivateAndSend/_id_'
            }
          },
          table: tableElement
        }))
        editorReactivateAndDownload = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams(), {
          fields: [{
            type: 'hidden',
            name: 'id'
          }, {
            type: 'hidden',
            name: 'orgid'
          }, {
            label: $translate.instant('Recipient'),
            name: 'to',
          }],
          ajax: {
            edit: {
              type: 'PUT',
              url: '/admin-api/invite/reactivate/_id_'
            }
          },
          table: tableElement
        }))
        editorRemove = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams(), {
          fields: [{
            type: 'hidden',
            name: 'id'
          }],
          ajax: {
            remove: {
              type: 'DELETE',
              url: '/admin-api/invite/_id_',
              deleteBody: false
            }
          },
          table: tableElement
        }))

        // After invite update or demete, clear invites in cache
        editorReactivateAndSend.on('postSubmit', clearCacheInvites)
        editorReactivateAndDownload.on('postSubmit', clearCacheInvites)
        editorRemove.on('postSubmit', clearCacheInvites)

        function clearCacheInvites() {
          $scope.$emit('data-modified', 'invite')
        }

        // bottom custom edit buttons
        const extraButtons = [{
          extend: 'edit',
          className: 'invite-reactivate-send',
          editor: editorReactivateAndSend,
          text: $translate.instant('InviteReactivateAndSend'),
          action: (e, dt, node, config) => reactivateAndSend(dt.rows({ selected: true }))
        }, {
          extend: 'edit',
          className: 'invite-reactivate-download',
          editor: editorReactivateAndDownload,
          text: $translate.instant('InviteReactivateAndDownload'),
          action: (e, dt, node, config) => reactivateAndDownload(dt.rows({ selected: true }))
        }, {
          extend: 'remove',
          className: 'invite-delete',
          editor: editorRemove,
          text: $translate.instant('Delete'),
          action: (e, dt, node, config) => inviteService.remove(dt.rows({ selected: true }), editorRemove)
        }, {
          extend: 'edit',
          className: 'invite-show-users',
          text: $translate.instant('InviteShowUsers'),
          action: (e, dt, node, config) => showInvitationUsers(dt.rows({ selected: true }))
        }]

        const exportOptions = {
          columns: sessionState.isRoot ? [0, 1, 2, 3, 4, 5, 7, 8, 11] : [2, 3, 4, 5, 7, 8, 11]
        }
        const exportMessage = $translate.instant('DashboardInviteStatus')

        function initializeTable() {
          const datatableParams = angular.extend(datatablesHelper.getDatatablesParams({
            extraButtons,
            exportOptions,
            exportMessage,
            extraDTOptions: { serverSide: true },
            lengthMenu: [[10, 25, 50, 100, 200], [10, 25, 50, 100, 200]],
          }), {
            columns: [
              { // orgname and internalOrgid
                data: 'orgid',
                visible: sessionState.isRoot, className: (!sessionState.isRoot ? 'never' : ''),
                render: function renderOrgName(data, type, full, meta) {
                  if (!sessionState.isRoot || !full.orgid) {
                    return ''
                  }
                  return datatablesHelper.sanitizeEscape(full.orgName) + ' <small class="text-id">' + datatablesHelper.sanitizeEscape(full.internalOrgid) + '</small>'
                }
              },
              { // org groups
                data: 'groups',
                searchable: false,
                orderable: false,
                responsivePriority: 10001,
                visible: sessionState.isRoot,
                className: (!sessionState.isRoot ? 'never' : ''),
                render: datatablesHelper.renderGroups

              },
              { // state
                searchable: false,
                orderable: false,
                data: function renderState(row, type) {
                  if (inviteService.isUsed(row)) {
                    return '<div class="label-mosaik label-mosaik-warning">' + $translate.instant('Used') + '</div>'
                  }
                  if (inviteService.isDeactivated(row)) {
                    return '<div class="label-mosaik label-mosaik-default">' + $translate.instant('Deactivated') + '</div>'
                  }
                  if (inviteService.isExpired(row)) {
                    return '<div class="label-mosaik label-mosaik-danger">' + $translate.instant('Expired') + '</div>'
                  }
                  return '<div class="label-mosaik label-mosaik-success">' + $translate.instant('InProgress') + '</div>'
                }
              },
              {
                data: 'to',
                render: function (data, type, row) {
                  if (row.useMax === 1) {
                    return datatablesHelper.sanitizeEscape(data)
                  }
                  return datatablesHelper.sanitizeEscape(data) + '<br><small class="text-id">' + $translate.instant('UsedCodesOnRemain', { useCounter: row.useCounter, useMax: row.useMax }) + '</small></i>'
                }
              },
              {
                data: 'rolename',
                searchable: false,
                orderable: false,
                render: data => userRoleService.formatTranslate(data)
              },
              {
                data: 'tags',
                searchable: false,
                orderable: false,
                render: datatablesHelper.renderTags
              },
              {
                data: 'sendingAccepted',
                searchable: false,
                render: function (data, type, row) {
                  if (row.deliveryFailed === 1) {
                    const description = row.deliveryDiagnosticCode || row.deliveryDescription || row.sendingResponse || ''
                    return `<span class="label-mosaik label-mosaik-danger">${$translate.instant('DeliveryFailed')}</span><br>
                            <i><small class="text-muted">${description}</small></i>`
                  }
                  if (data === 1) {
                    if (row.deliveryStatus === 'deferred') {
                      return '<span class="label-mosaik label-mosaik-warning">' + $translate.instant('Deferred') + '</span>'
                    }
                    var opened = row.opened ? $translate.instant('Opened', { count: row.opened }) : ''
                    var clicked = row.clicked ? $translate.instant('clicked', { count: row.clicked }) : ''

                    return '<span class="label-mosaik label-mosaik-success">' + $translate.instant('Sent') + '</span>' +
                      (opened ? '<br><i><small class="text-id">' + opened + '</small></i>' : '') +
                      (clicked ? '<br><i><small class="text-id">' + clicked + '</small></i>' : '')
                  }
                  if (data == 0) {
                    return '<span class="label-mosaik label-mosaik-danger">' + $translate.instant('SentFailed') + '</span>'
                  }
                  return ''
                }
              },
              {
                data: 'priorityAutoFilter',
                searchable: false,
                render: datatablesHelper.renderYesNo
              },
              {
                data: 'expiresAt',
                searchable: false,
                render: datatablesHelper.renderDateFromNow
              },
              {
                data: 'publishedAt',
                searchable: false,
                render: datatablesHelper.renderDateTimeHuman
              },
              { // render action row menu
                searchable: false,
                orderable: false,
                data: (row, type) => {
                  const editDisabledClass = row.useMaxTopped === 1 || row.orgActive === 0 ? 'disabled' : ''
                  const showUsersDisabledClass = row.useCounter === 0 ? 'disabled' : ''
                  template = `
                    <div class="dt-buttons btn-group dt-button-actions dropdown">
                      <button type="button" class="btn btn-defaut dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-h"></i>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-right">
                        <li class="dt-button"><a class="invite-reactivate-send ${editDisabledClass}" data-id="${row.id}" data-to="${row.id}">{{"InviteReactivateAndSend" | translate}}</a></li>
                        <li class="dt-button"><a class="invite-reactivate-download ${editDisabledClass}" data-id="${row.id}">{{"InviteReactivateAndDownload" | translate}}</a></li>
                        <li class="dt-button"><a class="invite-show-code ${editDisabledClass}" data-id="${row.id}">{{"InviteShowCode" | translate}}</a></li>
                        <li class="dt-button"><a class="invite-delete ${editDisabledClass}" data-id="${row.id}">{{"Delete" | translate}}</a></li>
                        <li role="separator" class="divider"></li>
                        <li class="dt-button"><a class="invite-show-users ${showUsersDisabledClass}" data-id="${row.id}">{{"InviteShowUsers" | translate}}</a></li>
                      </ul>
                    </div>`
                  var compiled = ($interpolate(template)($scope))
                  return compiled
                }
              },
              // hidden code so it's included in text search
              {
                data: 'code',
                visible: false,
                className: 'never'
              },
              // hidden code human readable so it's included in text search
              {
                data: 'humancode',
                visible: false,
                className: 'never'
              }
            ],
            select: true,
            ordering: true,
            order: [[9, 'desc']]
          })
          datatablesHelper.createAndSetTable(datatableParams)

          $scope.table.on('select.dt.DT deselect.dt.DT', () => {
            const selected = $scope.table.rows({ selected: true })
            const data = selected.data().toArray()
            const hasSelected = data.length > 0
            const underLimitSelected = data.length <= 200
            const canEdit = !data.some(one => one.useMaxTopped === 1 || one.orgActive === 0)
            const canShowUsers = data.some(one => one.useCounter !== 0)

            $scope.table.buttons(['.invite-reactivate-send', '.invite-reactivate-download', '.invite-delete']).enable(hasSelected && canEdit && underLimitSelected)
            $scope.table.buttons(['.invite-show-users']).enable(hasSelected && canShowUsers && underLimitSelected)
          })
        }

        function reactivateAndSend(selected) {
          var indexes = selected.indexes()
          // multiple row editing
          if (indexes.length > 1) {
            $window.Swal.fire({
              title: $translate.instant('SendInfo'),
              text: $translate.instant('SendInfoMessage', { count: indexes.length }),
              icon: 'info',
              showConfirmButton: false,
              showCancelButton: false,
              showLoaderOnConfirm: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              preConfirm: () => {
                return new Promise(resolve => {
                  editorReactivateAndSend
                    .edit(selected.indexes(), false)
                    .submit(success => {
                      resolve(success)
                    }, error => {
                      resolve({ error: notificationHelper.errorMessageFrom(error), value: error })
                    })
                })
              }
            }).then(result => {
              if (!result.value) { // dismiss
                return
              }
              if (result.value.error) {
                return notificationHelper.swalError(result.value.error, result.value.value)
              }
              showProcessStatusMessage(result.value)
            })
            return $window.swal.clickConfirm() // start the request
          }

          // single row editing
          const inputValue = selected.row(indexes[0]).data().to
          $window.Swal.fire({
            title: $translate.instant('InviteReactivateAndSendTitle'),
            text: $translate.instant('InviteReactivateAndSendText'),
            icon: 'question',
            input: 'email',
            inputValue,
            inputValidator: function (value) {
              return formService.validateFromAttributes(value, this.inputAttributes, this.input)
            },
            inputAttributes: { autocomplete: "new-email", maxlength: 255, required: true },
            showCancelButton: true,
            confirmButtonText: $translate.instant('InviteAgain'),
            cancelButtonText: $translate.instant('Cancel'),
            showLoaderOnConfirm: true,
            preConfirm: email => {
              return new Promise(resolve => {
                editorReactivateAndSend
                  .edit(selected.indexes(), false)
                  .set('to', email)
                  .submit(success => {
                    resolve(success)
                  }, error => {
                    resolve({ error: notificationHelper.errorMessageFrom(error), value: error })
                  })
              })
            }
          }).then(result => {
            if (!result.value) { // dismiss
              return
            }
            if (result.value.error) {
              return notificationHelper.swalError(result.value.error, result.value.value)
            }
            showProcessStatusMessage(result.value)
          })
        }

        function reactivateAndDownload(selected) {
          const indexes = selected.indexes()
          $window.Swal.fire({
            title: $translate.instant('CreatePDFInfo'),
            text: $translate.instant('CreatePDFInfoMessage', { count: indexes.length }),
            icon: 'info',
            showConfirmButton: false,
            showCancelButton: false,
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            preConfirm: () => {
              return new Promise(resolve => {
                editorReactivateAndDownload
                  .edit(selected.indexes(), false)
                  .submit(success => {
                    if (!success.data || success.data.length === 0) {
                      return resolve(success)
                    }
                    // call for download
                    $http.put(`/admin-api/invite/downloadPDF/${utilService.ids(success.data).join()}`, null, { responseType: 'arraybuffer' })
                      .then(success => resolve(success), error => resolve(error))
                  }, error => {
                    resolve({ error: notificationHelper.errorMessageFrom(error), value: error })
                  })
              })
            }
          }).then(result => {
            if (!result.value) { // dismiss
              return
            }
            if (result.value.error) {
              return notificationHelper.swalError(result.value.error, result.value.value)
            }
            const response = result.value
            if (response.error || response.errorCount) {
              return showProcessStatusMessage(response)
            }
            inviteService.showDownload(response)
          })
          $window.swal.clickConfirm() // start the request
        }

        function showProcessStatusMessage(response) {
          const errorOccurred = response.error !== '' || response.errorCount
          const icon = errorOccurred ? 'warning' : 'success'
          let html = $translate.instant('ReactivateAndProcessResultTextSuccess', { successCount: response.successCount })
          if (response.errorCount) {
            html += '<br>' + $translate.instant('ReactivateAndProcessResultTextError', { errorCount: response.errorCount })
          }
          if (response.error) {
            html += '<br>' + response.error
          }
          $window.Swal.fire({
            title: $translate.instant('ReactivateAndProcessResultTitle'),
            html,
            icon
          })
        }

        function showInvitationCode(selected) {
          const indexes = selected.indexes()
          const humancode = selected.row(indexes[0]).data().humancode
          let clipboard

          $window.Swal.fire({
            title: $translate.instant('InviteShowCodeTitle'),
            html: `${$translate.instant('InviteShowCodeText')}<br>
                    <h3>
                      <span>${humancode}</span>
                      <button class="clipboard btn btn-link btn-xs" type="button" data-clipboard-text="${humancode}" aria-label="${$translate.instant('Copy')}"><i class="fa fa-clipboard"></i></button>
                    </h3>`,
            icon: 'info',
            didOpen: element => {
              clipboard = new $window.ClipboardJS('.clipboard')
              clipboard.on('success', (e) => notificationHelper.info($translate.instant('Copied')))
              clipboard.on('error', (e) => notificationHelper.error($translate.instant('CopyFailed')))
            },
            didClose: element => notificationHelper.removeAll()
          })
        }

        function showInvitationUsers(selected) {
          $state.go('dashboardUser', { search: `codeid:${ids(selected).join()}` }, { reload: 'secure' })
        }

        const ids = (selected, key = 'id') => utilService.ids(selected.data().toArray(), key)

        function doAction(scope, fnAction, editor) {
          datatablesHelper.selectClosestRow($scope.table, datatablesHelper.getParentDiv(scope))
          fnAction($scope.table.rows({ selected: true }), editor)
        }

        tableElement.on('click', 'a.invite-reactivate-send:not(.disabled)', e => doAction(e.target, reactivateAndSend))
        tableElement.on('click', 'a.invite-reactivate-download:not(.disabled)', e => doAction(e.target, reactivateAndDownload))
        tableElement.on('click', 'a.invite-show-code:not(.disabled)', e => doAction(e.target, showInvitationCode))
        tableElement.on('click', 'a.invite-delete:not(.disabled)', e => doAction(e.target, inviteService.remove, editorRemove))
        tableElement.on('click', 'a.invite-show-users:not(.disabled)', e => doAction(e.target, showInvitationUsers))
        $scope.gotoPreviousState = () => $state.go($stateParams.previousState ? $stateParams.previousState : 'dashboard', {}, { reload: 'secure' })
      }
    ])
