7/*
 Select data range picker

 Requires properties to be set:
  - start and end dates (start, end)

 Emits a select-modified event to the parents scopes
*/

angular.module('mosaik.directives')

  .directive('selectDateRangePicker',
    ['$window', '$timeout', '$translate', 'timeService', '$location',
      function ($window, $timeout, $translate, timeService, $location) {

        function updateRange(element, start, end) {
          const dateRangePicker = element.data('daterangepicker')
          if (start) {
            dateRangePicker.setStartDate(start)
          }
          if (end) {
            dateRangePicker.setEndDate(end)
          }
        }

        function updateLabel(element, start, end, prefix, singleDate) {
          const icon = '<i class="glyphicon glyphicon-calendar fa fa-calendar"></i>&nbsp; '
          const caret = '&nbsp; <b class="caret"></b>'
          const label = '<span>' + (start && end && start.isValid() && end.isValid() ? start.format('MMM D, YYYY') + (singleDate ? '' : '-' + end.format('MMM D, YYYY')) : $translate.instant('FromStart')) + '</span>'
          const labelPrefix = prefix ? '<div class="prefix-label">' + prefix + '</div> ' : ''

          angular.element(element).html(labelPrefix + icon + label + caret)
        }

        function updateLocation(start, end) {
          $location.search('dateRange.start', start.isValid() ? start.format('YYYY-MM-DD') : null)
          $location.search('dateRange.end', end.isValid() ? end.format('YYYY-MM-DD') : null)
          $location.replace()
        }

        return {
          scope: {
            range: '=',
            ranges: '<',
            default: '<',
            prefix: '@',
            singleDate: '@'
          },
          link: function (scope, element, attrs) {
            // Try init from query param first, else use default
            const defaultRange = getDefaultRange()
            const searchStart = $location.search()['dateRange.start']
            const searchEnd = $location.search()['dateRange.end']
            const singleDatePicker = scope.singleDate === 'true'

            scope.range.start = searchStart ? $window.moment(searchStart) : defaultRange.start
            scope.range.end = searchEnd ? $window.moment(searchEnd) : searchStart ? null : defaultRange.end

            updateLabel(element, scope.range.start, scope.range.end, scope.prefix, singleDatePicker)

            // listen to state change from filter or params
            scope.$on('filter-param-loaded', filterParamChangeEvent)
            scope.$on('filter-param-clear', filterParamChangeEvent)

            function filterParamChangeEvent(event, args) {
              if (args.key === 'dateRange') {
                const dateRange = args.value || {}
                const searchStart = dateRange.start
                const searchEnd = dateRange.end

                // if cleared, set default
                $timeout(() => {
                  const defaultRange = getDefaultRange()

                  scope.range.start = searchStart ? $window.moment(searchStart) : defaultRange.start
                  scope.range.end = searchEnd ? $window.moment(searchEnd) : searchStart ? null : defaultRange.end

                  updateRange(element, scope.range.start, scope.range.end)
                  updateLabel(element, scope.range.start, scope.range.end, scope.prefix, singleDatePicker)
                })
              }
            }

            function getDefaultRange() {
              const range = timeService.last30Days()
              if (scope.default && scope.default.start) {
                range.start = scope.default.start
              }
              if (scope.default && scope.default.end) {
                range.end = scope.default.end
              }
              return range
            }

            // Creating and initializing Date Range Picker Object
            element.daterangepicker({
              startDate: scope.range.start,
              endDate: scope.range.end,
              singleDatePicker,
              dateLimit: {
                'months': 60
              },
              ranges: scope.ranges || timeService.defaultRanges(),
              locale: {
                'applyLabel': $translate.instant('Apply'),
                'cancelLabel': $translate.instant('Cancel'),
                'customRangeLabel': $translate.instant('Custom')
              }
            }, function onChange(start, end) {
              $timeout(function () {
                scope.range.start = start
                scope.range.end = end
                updateLabel(element, start, end, scope.prefix, singleDatePicker)
                updateLocation(start, end)
                scope.$emit('select-modified', { id: element.attr('id'), selected: { start: start, end: end }, value: element.val() })
              })
            })
          }
        }
      }
    ])
