angular.module('mosaik.services')
  .factory('asyncService',
    [function () {
      const self = {
        /**
         * Run the promise function with the params array, with concurrency limit
         * @param {Integer} limit >= 1
         * @param {*} params Array of the param to pass to the promise fonction with a key identifier. Format: [{ key: 'promise1', param: 'value2' }, { key: 'promise2', param: 'value2' }]
         * @param {*} promiseFn The promise function
         */
        promiseAllLimit: (limit, params, promiseFn) => {
          // create the array of promises to call
          const promises = params.map(paramObj => {
            return () => {
              const p = promiseFn(paramObj.param)
              p._key = paramObj.key
              return p
            }
          })

          const results = []
          let started = 0

          // recurse continue until the promises array is empty
          const recurse = () => {
            const i = started++
            const promise = promises.shift()
            return !promise ? null : Promise.allSettled([promise()])
              .then(result => {
                // store current promise's result sorted in the results array
                results[i] = {
                  key: params[i].key,
                  data: result[0].value
                }
                return recurse()
              })
          }
          // Start, in parallel, "limit" x the "recurse" function 
          return Promise.all(Array.from({ length: limit }, recurse))
            .then(() => results) // return the sorted results array; cannot use the Promise.all "result", results were already stored by the recurse fn
        }
      }

      return self
    }]
  )