// register the Service Worker
window.onload = function () {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js').then(function (registration) {
    })
  }
}

// Placeholder to detect for prompt PWA install event
window.installApp = {
  deferredPrompt: null
}

// Create frontend module and specify dependencies for that
var mosaikApp = angular.module('mosaik',
  ['mosaik.services',
    'mosaik.controllers',
    'mosaik.directives',
    'mosaik.filters',
    'mosaik.providers',
    'ui.router',
    'ui.router.state.events',
    'pascalprecht.translate',
    'ngAnimate',
    'ngIntlTelInput',
    'ngSanitize',
    'ui.bootstrap'])

// Initialize used frontend modules
angular.module('mosaik.services', [])
angular.module('mosaik.controllers', [])
angular.module('mosaik.directives', [])
angular.module('mosaik.filters', [])
angular.module('mosaik.providers', [])

// Set a global app config constant
mosaikApp.value('appConfig', {
  theme: 'dark-classic'
})

mosaikApp.config(
  ['$stateProvider', '$urlRouterProvider', '$translateProvider', 'ngIntlTelInputProvider', '$urlMatcherFactoryProvider', 'configProvider', '$httpProvider',
    function ($stateProvider, $urlRouterProvider, $translateProvider, ngIntlTelInputProvider, $urlMatcherFactoryProvider, configProvider, $httpProvider) {
      // Set VEX popup library class (vex is loaded globally)
      vex.defaultOptions.className = configProvider.vexThemeClassName

      initLanguage()
      setupTelephoneInputService()
      setupRoutes()

      function initLanguage() {
        $translateProvider.useMessageFormatInterpolation()
          .useStaticFilesLoader(configProvider.locale.fileNameConvention)
          .registerAvailableLanguageKeys(configProvider.locale.locales, configProvider.locale.localesMap)
          .determinePreferredLanguage()
          .fallbackLanguage(configProvider.locale.default)
          .useSanitizeValueStrategy('sceParameters')
      }

      function setupTelephoneInputService() {
        ngIntlTelInputProvider.set(configProvider.intlTelInput)
      }

      function setupRoutes() {
        $urlMatcherFactoryProvider.strictMode(false) // url trailing slash is optional
        // For any unmatched url, redirect to...
        $urlRouterProvider.otherwise('/landing')

        // Routes that are accessible
        $stateProvider
          // unsecured states: no authentication required
          // --------------------------------------------
          // Abstract parent state for all unsecured states
          .state('unsecure', {
            abstract: true,
            template: '<div ui-view />',
            resolve: {
              locale: ['translationService', (translationService) => translationService.initialize()]
            }
          })
          .state('landing', {
            parent: 'unsecure',
            location: false,
            url: '/landing/:moduleName/:stateName/:stateParams?locale',
            templateUrl: '/public/views/landing.html',
            controller: 'landingController',
            params: {
              moduleName: { value: null, squash: true },
              stateName: { value: null, squash: true },
              stateParams: { value: null, squash: true },
              locale: { value: null }
            }
          })

          .state('recoverPassword', {
            parent: 'unsecure',
            url: '/recoverPassword',
            templateUrl: '/public/views/recoverPassword.html',
            controller: 'recoverPasswordController',
            meta: {
              title: 'user.recoverPassword'
            }
          })

          .state('tos', {
            parent: 'unsecure',
            url: '/tos?locale',
            templateUrl: '/public/views/tos.html',
            controller: 'tosController',
            params: {
              locale: { value: null }
            },
            meta: {
              title: 'TosAndPolicy'
            }
          })

          .state('help', {
            parent: 'unsecure',
            url: '/help?locale&open',
            templateProvider: ['$stateParams', '$templateRequest', 'sessionState',
              function ($stateParams, $templateRequest, sessionState) {
                const helpPage = sessionState.connected ? (sessionState.isAdmin ? 'helpAdmin' : 'helpUser') : 'helpDefault'
                const templateUrl = '/public/views/help/' + helpPage + '.html'
                return $templateRequest(templateUrl)
              }
            ],
            resolve: {
              sessionState: ['sessionStateService', (sessionStateService) => sessionStateService.session()]
            },
            controller: 'helpController',
            params: {
              locale: { value: null }
            },
            meta: {
              title: 'Help'
            }
          })

          .state('kb', {
            parent: 'unsecure',
            url: '/help/kb?kbarticleid',
            templateUrl: '/public/views/help/kb.html',
            controller: 'kbController',
            params: {
              kbarticleid: { value: null, squash: true }
            },
            resolve: {
              sessionState: ['sessionStateService', sessionStateService => sessionStateService.lastSession()],
              topics: ['kbTopicService', kbTopicService => kbTopicService.topics()],
              articles: ['kbArticleService', kbArticleService => kbArticleService.articles()]
            },
            reloadOnSearch: false,
            meta: {
              title: 'KnowledgeBase'
            }
          })

          // register state  closes the current session if a valide access code is given
          // 
          // -------------------------------------------------
          .state('register', {
            parent: 'unsecure',
            url: '/register/:accessCode?errorMessage&warningMessage',
            templateUrl: '/public/views/register.html',
            controller: 'registerController',
            params: {
              accessCode: { value: null, squash: true },
              errorMessage: { value: null }
            },
            resolve: {
              setup: ['passportService', (passportService) => passportService.setup()],
              validAccessCode: ['$state', 'accessCodeService', '$stateParams', 'sessionStateService',
                function accessCodeGiven($state, accessCodeService, $stateParams, sessionStateService) {
                  return accessCodeService.validateCode($stateParams, true)
                    .then(
                      (success) => sessionStateService.terminateSession().then(() => success.data.accessCode),
                      (error) => {
                        const data = error.data || {}
                        return sessionStateService.terminateSession()
                          .then(() => {
                            const redirectState = data.redirectState ? data.redirectState.name : 'validateCode'
                            const stateParams = {
                              captchaRequired: data.captchaRequired
                            }

                            if (data.redirectState && data.redirectState.stateParams) {
                              stateParams.accessCode = data.redirectState.stateParams.accessCode ? data.redirectState.stateParams.accessCode : data
                            }

                            return $state.go(redirectState, stateParams)
                          })
                      })
                }]
            },
            meta: {
              title: 'Register'
            }
          })

          // States that explicitly require to be disconnected
          // -------------------------------------------------
          // Abstract parent state for all explicitly unsecured states
          .state('explicit-unsecure', {
            abstract: true,
            template: '<div ui-view />',
            resolve: {
              sessionState: ['sessionStateService', (sessionStateService) => sessionStateService.goHomeOnValidSession()],
              locale: ['translationService', (translationService) => translationService.initialize()]
            }
          })

          .state('passport', {
            parent: 'explicit-unsecure',
            url: '/?errorMessage&warningMessage',
            templateUrl: '/public/views/passport.html',
            controller: 'passportController',
            resolve: {
              setup: ['passportService', (passportService) => passportService.setup()]
            },
            params: {
              errorMessage: { value: null, squash: true },
              warningMessage: { value: null, squash: true }
            },
            meta: {
              title: 'Signin'
            }
          })

          .state('validateCode', {
            parent: 'explicit-unsecure',
            url: '/validateCode/:accessCode?errorMessage',
            templateUrl: '/public/views/validateCode.html',
            controller: 'validateCodeController',
            params: {
              accessCode: { value: null, squash: true },
              captchaRequired: { value: null, squash: true },
              errorMessage: { value: null }
            },
            meta: {
              title: 'EnterOrgAccessCode'
            }
          })

          // Secured states: authentication is required for accessible
          // ---------------------------------------------------------
          // Abstract parent state for all secured states
          .state('secure', {
            abstract: true,
            template: '<div ui-view />',
            resolve: {
              sessionState: ['sessionStateService', (sessionStateService) => sessionStateService.validSessionOrRedirect()],
              locale: ['translationService', (translationService) => translationService.initialize()]
            }
          })

          .state('settings', {
            parent: 'secure',
            url: '/settings',
            templateUrl: '/public/views/settings/settings.html',
            controller: 'settingsController',
            resolve: {
              tags: ['tagService', tagService => tagService.mySubscribableTags()]
            },
            meta: {
              title: 'PersonalSettings'
            }
          })

          .state('changeTemporaryPassword', {
            parent: 'secure',
            url: '/changeTemporaryPassword',
            templateUrl: '/public/views/changeTemporaryPassword.html',
            controller: 'changeTemporaryPasswordController',
            meta: {
              title: 'ChoosePassword'
            }
          })

          .state('library', {
            parent: 'secure',
            url: '/library',
            abstract: true,
            templateUrl: '/public/views/library/library.html',
            controller: 'libraryController',
            resolve: {
              startLoader: ['$rootScope', $rootScope => {
                return $rootScope.$emit('start-loader')
              }]
            }
          })

          .state('myContents', {
            parent: 'library',
            url: '/myContents/:cid?contentid&activityid&warningMessage',
            templateUrl: '/public/views/library/myContents.html',
            controller: 'myContentsController',
            resolve: {
              sessionState: ['sessionStateService', (sessionStateService) => sessionStateService.validSessionOrRedirect()],
              myTags: ['tagService', (tagService) => tagService.myTags()],
              contents: ['contentSessionService', (contentSessionService) => {
                performance.mark('mycontents-resolve-start')
                return contentSessionService.myContents({ excludeSubContents: false })
              }],
              categories: ['categoryService', (categoryService) => categoryService.categories()]
            },
            params: {
              cid: { value: null, squash: true, dynamic: true },
              contentid: { value: null, dynamic: true },
              activityid: { value: null, dynamic: true },
              warningMessage: { value: null, dynamic: true }
            },
            meta: {
              title: 'MyContents'
            }
          })

          .state('memos', {
            parent: 'secure',
            url: '/memos',
            templateUrl: '/public/views/memo/memos.html',
            controller: 'memosController',
            resolve: {
              memos: ['memoService', (memoService) => memoService.memos()]
            },
            meta: {
              title: 'Memos'
            }
          })

          .state('memo', {
            parent: 'secure',
            url: '/memo/:memoid',
            templateUrl: '/public/views/memo/memo.html',
            controller: 'memoController',
            params: {
              memoid: { value: 'null' }
            },
            meta: {
              title: 'Memo'
            }
          })

          .state('redeem', {
            parent: 'secure',
            url: '/redeem?errorMessage&warningMessage',
            templateUrl: '/public/views/redeem.html',
            controller: 'redeemController',
            params: {
              errorMessage: { value: null }
            },
            meta: {
              title: 'Redeem'
            }
          })

          .state('mobile', {
            parent: 'secure',
            url: '/mobile',
            templateUrl: '/public/views/mobile.html',
            controller: 'mobileController',
            meta: {
              title: 'ChangeMobile'
            }
          })

          .state('tags', {
            parent: 'secure',
            url: '/tags',
            templateUrl: '/public/views/tags.html',
            controller: 'tagsController',
            resolve: {
              tags: ['tagService', tagService => tagService.mySubscribableTags()]
            },
            meta: {
              title: 'TagsTitle'
            }
          })

          .state('dashboard', {
            parent: 'secure',
            url: '/dashboard',
            templateUrl: '/public/views/dashboard/dashboard.html',
            controller: 'dashboardController',
            resolve: {
              startLoader: ['$rootScope', $rootScope => {
                return $rootScope.$emit('start-loader')
              }],
              setup: ['dashboardService', (dashboardService) => dashboardService.setup()]
            },
            meta: {
              title: 'Dashboard'
            }
          })

          .state('dashboardOrganization', {
            parent: 'secure',
            resolve: {
              groups: ['groupService', (groupService) => groupService.groups()]
            },
            url: '/dashboard/organization?state&search&groups',
            reloadOnSearch: false,
            templateUrl: '/public/views/dashboard/organization.html',
            controller: 'dashboardOrganizationController',
            meta: {
              title: 'OrganizationsTitle'
            }
          })

          .state('dashboardUser', {
            parent: 'secure',
            resolve: {
              groups: ['groupService', (groupService) => groupService.groups()],
              tags: ['tagService', (tagService) => tagService.tags()],
              roles: ['userRoleService', (userRoleService) => userRoleService.rolesAccessible()],
              organizations: ['organizationService', (organizationService) => organizationService.organizations()]
            },
            url: '/dashboard/user?previousState&search&state&organizations',
            reloadOnSearch: false,
            templateUrl: '/public/views/dashboard/user.html',
            controller: 'dashboardUserController',
            meta: {
              title: 'Users'
            }
          })

          .state('dashboardTag', {
            parent: 'secure',
            resolve: {
              organizations: ['organizationService', (organizationService) => organizationService.organizations()]
            },
            url: '/dashboard/tag',
            reloadOnSearch: false,
            templateUrl: '/public/views/dashboard/tag.html',
            controller: 'dashboardTagController',
            meta: {
              title: 'TagsTitle'
            }
          })

          .state('dashboardSessionDailyTimeLog', {
            parent: 'secure',
            resolve: {
              groups: ['groupService', (groupService) => groupService.groups()],
              tags: ['tagService', (tagService) => tagService.tags()],
              organizations: ['organizationService', (organizationService) => organizationService.organizations()],
              contents: ['contentService', (contentService) => contentService.contentsForList()],
              categories: ['categoryService', (categoryService) => categoryService.categories()]
            },
            url: '/dashboard/session-daily-time-log?previousState&organizations&search',
            reloadOnSearch: false,
            templateUrl: '/public/views/dashboard/sessionDailyTimeLog.html',
            controller: 'dashboardSessionDailyTimeLogController',
            meta: {
              title: 'TimeUsage'
            }
          })

          .state('dashboardContent', {
            parent: 'secure',
            resolve: {
              organizations: ['organizationService', (organizationService) => organizationService.organizations()],
              categories: ['categoryService', (categoryService) => categoryService.categories()]
            },
            url: '/dashboard/content',
            reloadOnSearch: false,
            templateUrl: '/public/views/dashboard/content.html',
            controller: 'dashboardContentController',
            meta: {
              title: 'ContentsTitle'
            }
          })

          .state('dashboardSessionCompleted', {
            parent: 'secure',
            resolve: {
              groups: ['groupService', (groupService) => groupService.groups()],
              tags: ['tagService', (tagService) => tagService.tags()],
              organizations: ['organizationService', (organizationService) => organizationService.organizations()],
              roles: ['userRoleService', (userRoleService) => userRoleService.rolesAccessible()],
              contents: ['contentService', (contentService) => contentService.contentsForList()],
              categories: ['categoryService', (categoryService) => categoryService.categories()]
            },
            url: '/dashboard/session/completed?previousState&organizations&search',
            reloadOnSearch: false,
            templateUrl: '/public/views/dashboard/sessionCompleted.html',
            controller: 'sessionCompletedController',
            meta: {
              title: 'CompletedContent'
            }
          })

          .state('dashboardSessionInProgress', {
            parent: 'secure',
            resolve: {
              groups: ['groupService', (groupService) => groupService.groups()],
              tags: ['tagService', (tagService) => tagService.tags()],
              organizations: ['organizationService', (organizationService) => organizationService.organizations()],
              roles: ['userRoleService', (userRoleService) => userRoleService.rolesAccessible()],
              contents: ['contentService', (contentService) => contentService.contentsForList({ activeState: 'active', visible: true })],
              categories: ['categoryService', (categoryService) => categoryService.categories()]
            },
            url: '/dashboard/session/inprogress?previousState&organizations&search',
            reloadOnSearch: false,
            templateUrl: '/public/views/dashboard/sessionInProgress.html',
            controller: 'sessionInProgressController',
            meta: {
              title: 'InprogressContent'
            }
          })

          .state('dashboardInteraction', {
            parent: 'secure',
            url: '/dashboard/session/interaction/:sessionid?previousState&title&displayName&date',
            reloadOnSearch: false,
            templateUrl: '/public/views/dashboard/sessionInteraction.html',
            controller: 'sessionInteractionController',
            params: {
              sessionid: { value: '' },
              title: { value: '' },
              displayName: { value: '' }
            },
            meta: {
              title: 'InteractionData'
            }
          })

          .state('dashboardContentAverageScore', {
            parent: 'secure',
            resolve: {
              groups: ['groupService', (groupService) => groupService.groups()],
              organizations: ['organizationService', (organizationService) => organizationService.organizations()],
              contents: ['contentService', (contentService) => contentService.contentsForList()],
              categories: ['categoryService', (categoryService) => categoryService.categories()]
            },
            url: '/dashboard/content/score',
            reloadOnSearch: false,
            templateUrl: '/public/views/dashboard/contentAverageScore.html',
            controller: 'contentAverageScoreController',
            meta: {
              title: 'AverageScore'
            }
          })

          .state('dashboardContentCoverage', {
            parent: 'secure',
            resolve: {
              organizations: ['organizationService', (organizationService) => organizationService.organizations()]
            },
            url: '/dashboard/content/coverage?search',
            reloadOnSearch: false,
            templateUrl: '/public/views/dashboard/contentCoverage.html',
            controller: 'contentCoverageController',
            meta: {
              title: 'ContentsCoverage'
            }
          })

          .state('dashboardContentValue', {
            parent: 'secure',
            url: '/dashboard/content/value?organizations',
            reloadOnSearch: false,
            resolve: {
              organizations: ['organizationService', (organizationService) => organizationService.organizations()]
            },
            templateUrl: '/public/views/dashboard/contentValue/indexContentValue.html',
            controller: 'indexContentValueController',
            meta: {
              title: 'UsageValue'
            }
          })

          .state('dashboardOrganizationHourlyRate', {
            parent: 'secure',
            url: '/dashboard/organization/hourlyRate?organizations',
            reloadOnSearch: false,
            resolve: {
              organizations: ['organizationService', (organizationService) => organizationService.organizations()]
            },
            templateUrl: '/public/views/dashboard/contentValue/organizationHourlyRate.html',
            controller: 'organizationHourlyRateController',
            meta: {
              title: 'AverageHourlyRate'
            }
          })

          .state('dashboardMemoConsultation', {
            parent: 'secure',
            url: '/dashboard/memo/consultation',
            reloadOnSearch: false,
            templateUrl: '/public/views/dashboard/memoConsultation.html',
            controller: 'memoConsultationController',
            meta: {
              title: 'DashboardMemoConsultation'
            }
          })

          .state('dashboardInviteStatus', {
            parent: 'secure',
            resolve: {
              groups: ['groupService', (groupService) => groupService.groups()],
              roles: ['userRoleService', (userRoleService) => userRoleService.rolesAccessible()],
              organizations: ['organizationService', (organizationService) => organizationService.organizations()]
            },
            url: '/dashboard/invite/status?previousState&organizations&search&state',
            reloadOnSearch: false,
            templateUrl: '/public/views/dashboard/inviteStatus.html',
            controller: 'inviteStatusController',
            meta: {
              title: 'Invitations'
            }
          })

          .state('invite', {
            parent: 'secure',
            url: '/invite',
            templateUrl: '/public/views/invite/invite.html',
            controller: 'inviteController',
            meta: {
              title: 'Invite'
            }
          })
          .state('inviteEmail', {
            parent: 'secure',
            resolve: {
              setup: ['inviteService', inviteService => inviteService.setup()],
              organizations: ['organizationService', (organizationService) => organizationService.organizations()]
            },
            url: '/inviteEmail',
            templateUrl: '/public/views/invite/inviteEmail.html',
            controller: 'inviteEmailController',
            meta: {
              title: 'InviteByEmail'
            }
          })
          .state('invitePDF', {
            parent: 'secure',
            resolve: {
              setup: ['inviteService', inviteService => inviteService.setup()],
              organizations: ['organizationService', (organizationService) => organizationService.organizations()]
            },
            url: '/invitePDF',
            templateUrl: '/public/views/invite/invitePDF.html',
            controller: 'invitePDFController',
            meta: {
              title: 'InviteByPDF'
            }
          })

          .state('stateRedirect', {
            parent: 'secure',
            location: false,
            url: '/state/:stateName',
            controller: 'stateRedirectorController'
          })
      }
    }
  ]
)

mosaikApp.run([
  'moduleService',
  (moduleService) => {
    moduleService.listenForInstallPrompt()
    moduleService.setCurrentStateRetainer()
    moduleService.listenForStateTransitions('MainTitle')
    moduleService.loadLanguages()
  }])
