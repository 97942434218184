angular.module('mosaik.controllers')
  .controller('mobileSettingsController',
    ['$scope', '$http', '$translate', 'ngIntlTelInput', 'notificationHelper',
      function ($scope, $http, $translate, ngIntlTelInput, notificationHelper) {
        const sessionState = $scope.$parent.sessionState

        $scope.mobile = {}

        if (sessionState.mobilePhone) {
          $scope.mobile.mobilePhone = sessionState.mobilePhone
        }

        $scope.setMobilePhoneForPersonalSettings = () => {
          $scope.loading = true

          $http.put('/api/user/me/mobilePhone', $scope.mobile)
            .then(success => notificationHelper.successFrom(success))
            .catch(error => notificationHelper.failureFrom(error))
            .finally(() => $scope.loading = false)
        }
      }
    ]
  )
