angular.module('mosaik.directives', [])
  .directive('valueDifferent', [function () {
    return {
      require: 'ngModel',
      link: function (scope, elem, attrs, ctrl) {
        var firstValue = '#' + attrs.valueDifferent
        elem.add(firstValue).on('keyup', function () {
          scope.$apply(function () {
            var v = elem.val() !== $(firstValue).val()
            ctrl.$setValidity('valueDifferent', v)
          })
        })
      }
    }
  }])

angular.module('mosaik.controllers')
  .controller('registerController',
    ['$window', '$scope', '$state', '$http', 'setup', '$stateParams', 'translationService', 'notificationHelper', 'validAccessCode', 'accessCodeService', '$rootScope', '$translate', 'captchaHelper', '$timeout', 'passportService', 'utilService', 'passwordService',
      function ($window, $scope, $state, $http, setup, $stateParams, translationService, notificationHelper, validAccessCode, accessCodeService, $rootScope, $translate, captchaHelper, $timeout, passportService, utilService, passwordService) {
        $scope.socialStrategies = setup.passport.socialStrategies
        $scope.localStrategies = setup.passport.localStrategies
        $scope.canRegisterCount = setup.passport.socialStrategies.canRegisterCount

        $scope.captchaRequired = false
        $scope.loading = false

        $timeout(() => $scope.show = true, 100)

        $scope.emailRegex = utilService.getEmailRegex()
        $scope.user = {
          captcha: '',
          accessCode: validAccessCode
        }
        $scope.constraints = {
          minLength: passwordService.getMinLength()
        }
        $scope.languages = translationService.getLanguages()
        $scope.selectedLanguage = translationService.getCurrentLanguage().code

        const captchaConfig = {
          'elementid': 'recaptcha-container',
          'callback': response => $scope.user.captcha = response,
          'expired-callback': () => $scope.user.captcha = null
        }

        $scope.togglePassword = () => $scope.showPassword = !$scope.showPassword

        // Scope function to perform actual login request to server
        $scope.register = function register() {
          notificationHelper.removeAll()
          $scope.loading = true
          $scope.user.locale = $scope.selectedLanguage
          // split up displayname
          var splittedName = $scope.user.name.displayName.split(' ')
          $scope.user.name.givenName = splittedName[0]
          splittedName.shift()
          $scope.user.name.familyName = splittedName.join(' ')

          $http.put('/api/user/register', $scope.user)
            .then(success => {
              $rootScope.$broadcast('index-state-reload')
              accessCodeService.deleteCode()
              if (!success.data.success) {
                notificationHelper.error(success.data.message)
              }
              $state.go(success.data.redirectState.name, success.data.redirectState.params, { reload: 'secure' })
            })
            .catch(error => {
              // on user already exists, propose what to do to the user
              if (error.data.userAlreadyExists) {
                $window.Swal.fire({
                  icon: 'warning',
                  title: error.data.message,
                  html: $translate.instant('YouShouldConnect') + (!error.data.userIsActive ? '<br><i><small>' + $translate.instant('InviteCodeWillBeAsked') + '</small></i>' : ''),
                  showCancelButton: true,
                  showCloseButton: true,
                  confirmButtonText: $translate.instant('PreferToConnect'),
                  cancelButtonText: $translate.instant('PreferToRegister')
                }).then(
                  function confirm(result) {
                    if (result.isConfirmed) {
                      return $state.go('passport', {}, { reload: true })
                    }
                  })
              } else {
                // show toast notification based on returned error
                notificationHelper.failureFrom(error)
              }

              // activate captcha if required by backend
              if (error.data.captchaRequired) {
                if (!$scope.captchaRequired) {
                  // first time we need captcha for this form: we need to load      
                  captchaHelper.load(captchaConfig, true)
                } else {
                  captchaHelper.reset()
                }
                $timeout(() => {
                  $scope.captchaRequired = true
                })
              }

              if (!error.data.captchaRequired) {
                captchaHelper.remove()
                $scope.captchaRequired = false
              }
            })
            .finally(() => {
              $scope.loading = false
              $scope.location = false
            })
        }

        $scope.signinWithStrategy = passportService.signinWithStrategy

        $scope.$on('select-modified', function (event, data) {
          if (data.id === 'languages-selectpicker') {
            $scope.selectedLanguage = data.selected.code // todo: bug, this line only is normally unnecessary, suppose to be done by the select picker directive...
            // Change the page language
            translationService.change(data.selected.code)
          }
        })

        $scope.$on('locale-changed', function onLocaleChanged(event, args) {
          captchaHelper.load(captchaConfig)
        })
      }
    ]
  )
