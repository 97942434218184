angular.module('mosaik.controllers')
  .controller('sessionInteractionController',
    ['$scope', '$window', '$translate', 'sessionState', 'datatablesHelper', '$stateParams', '$state', '$timeout', 'datatablesHelper',
      function ($scope, $window, $translate, sessionState, datatablesHelper, $stateParams, $state, $timeout, datatablesHelper) {
        // access rights
        $scope.canAccessDashboard = sessionState.canAccessDashboard
        // params
        const sessionid = $stateParams.sessionid,
              title =  $stateParams.title,
              displayName = $stateParams.displayName,
              previousState = $stateParams.previousState,
              date = datatablesHelper.renderDateTimeHuman($stateParams.date)
        // Report custom title
        $scope.translationData = { sessionid, title, displayName, date  }
        // parameters
        const tableid = '#datatables-session-interaction'
        const tableElement = angular.element(tableid)
        const api = '/admin-api/session-interaction'
        // data
        // DT table
        $scope.table

        $timeout(() => datatablesHelper.init($scope, tableid, api, sessionid, initializeTable))

        function initializeTable () {
          const exportOptions = {
            columns: [0,1,2,3,4,5,6]
          }
          const exportMessage = $translate.instant('InteractionTitle', $scope.translationData) + ' - ' +  $translate.instant('InteractionSubtitle', $scope.translationData)
          const datatableParams = angular.extend(datatablesHelper.getDatatablesParams({ 
            exportOptions,
            exportMessage,
            filteringInput: false,
          }), {
            columns: [
              { data: 'index' },
              { 
                data: 'interactionid',
                render: (data) => '<div style="max-width: 250px; overflow-wrap: break-word;">' + decodeURIComponent(data || '') + '</div>'
              },
              { 
                data: 'latencyInMillisecond',
                render: (data) => {
                  const duration = $window.moment.duration(data)
                  if (!data || !duration.isValid()) {
                    return ''
                  } 
                  let hours = duration.hours(),
                        minutes = duration.minutes(),
                        seconds = duration.seconds(),
                        milliseconds = duration.milliseconds()
                  if (milliseconds >= 950) {
                    milliseconds = 0
                    seconds++
                  }
                  return  `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${Math.round(milliseconds / 100)}`
                }
              },
              { // latencyISO8601 for report
                data: 'latencyInMillisecond',
                visible: false,
                className: 'never',
                searchable: false,
                render: (data) => data ? $window.moment.duration(data).toString() : ''
              },
              { 
                data: 'response',
                render: (data) => '<div style="max-width: 250px; overflow-wrap: break-word;">' + decodeURIComponent(data || '') + '</div>'
              },
              { 
                data: 'result',
                render: (data, type, full) => {
                  if (full.response && (data === 1 || data === 0)) {
                    return datatablesHelper.renderCorrectIncorrect(data)
                  }
                  const parsed = parseFloat(data)
                  return !data || parsed === NaN || parsed > 1 ? data : (parsed * 100) + '%'
                }
              },
              { 
                data: 'createdAt',
                render: datatablesHelper.renderDateTimeReportSec
              },
            ],
            order: [[6, 'asc']]
          })
          datatablesHelper.createAndSetTable(datatableParams)
        }

        $scope.gotoPreviousState = () => $state.go(previousState || 'dashboard', {}, { reload: 'secure' })
      }
    ]
)
