angular.module('mosaik.controllers')
  .controller('organizationHourlyRateController',
    ['$window', '$scope', 'sessionState', 'organizations', '$translate', '$timeout', '$filter', '$stateParams', 'datatablesHelper', 'dashboardService',
      function ($window, $scope, sessionState, organizations, $translate, $timeout, $filter, $stateParams, datatablesHelper, dashboardService) {
        // access rights
        $scope.canAccessDashboard = sessionState.canAccessDashboard
        $scope.isRoot = sessionState.isRoot
        // parameters
        var tableid = '#datatables-organization'
        var tableElement = angular.element(tableid)
        var api = '/admin-api/organizationHourlyRate'
        $scope.queryHidden = {
          orgid: sessionState.orgidSelector
        }
        $scope.query = {
          search: '',
          organizations: []
        }
        $scope.defaultOrgid = $stateParams.organizations
        // data
        $scope.organizations = organizations
        // DT table
        $scope.table
        //editor
        var editor

        $timeout(function () {
          datatablesHelper.init($scope, tableid, api, '', initializeTable)
        })

        function initializeTable() {
          editor = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams(), {
            fields: [
              { name: 'id' },
              {
                label: $translate.instant('InternalOrgid'),
                name: 'internalOrgid'
              },
              {
                label: $translate.instant('Name'),
                name: 'name'
              },
              {
                label: $translate.instant('AverageHourlyRate'),
                name: 'averageHourlyRate'
              }
            ]
          }))

          // After rate update, clear calculated content value from cache
          editor.on('postSubmit', function () {
            $scope.$emit('data-modified', 'contentValue')
          })

          angular.element(tableid)
            .on('click', 'tbody tr', function editOnClick(e) {
              var fieldToEdit = angular.element(this).find('td.editable')[0]
              if (fieldToEdit) {
                editor.inline(fieldToEdit, {
                  submit: 'allIfChanged',
                  onBlur: 'submit'
                })
              }
            })
            .on('key-focus', function editOnKeyFocus(e, datatable, cell) {
              editor.inline(cell.index(), {
                submit: 'allIfChanged',
                onBlur: 'submit'
              })
            })

          var printMessage = $translate.instant('AverageHourlyRate')

          var datatableParams = angular.extend(datatablesHelper.getDatatablesParams({ editor: editor, hiddenButtons: ['create', 'edit', 'remove'], exportMessage: printMessage, extraDTOptions: { serverSide: true } }), {
            stateSave: false,
            columns: [
              { data: 'id', visible: true, className: '' },
              { 
                data: 'internalOrgid', 
                visible: false, 
                className: 'never',
                render: datatablesHelper.sanitizeEscape
              },
              { 
                data: 'name',
                render: datatablesHelper.sanitizeEscape
              },
              {
                data: 'averageHourlyRate',
                width: '25%',
                render: function (data, type, full, meta) {
                  return $window.numeral(data).format('0.00 $') + '/hr'
                },
                className: 'editable all'
              }
            ],
            keys: {
              columns: [3],
              keys: [9]
            },
            ordering: false,
            select: false,
            autoWidth: false
          })
          datatablesHelper.createAndSetTable(datatableParams)
        }
      }
    ])
