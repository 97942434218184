angular.module('mosaik.controllers')
  .controller('profileController',
    ['$scope', '$rootScope', '$timeout', 'indexService', '$http', '$translate', 'translationService', 'notificationHelper', 'utilService',
      function ($scope, $rootScope, $timeout, indexService, $http, $translate, translationService, notificationHelper, utilService) {
        const sessionState = $scope.$parent.sessionState
        
        $scope.user = {}
        $scope.isRoot = false
        $scope.languages = translationService.getLanguages()
        $scope.emailRegex = utilService.getEmailRegex()

        indexService.setup()
          .then(setup => {
            $scope.strategyData = {
              strategyName: setup.strategyName,
              strategyLogoUrl: setup.strategyLogoUrl
            }
          })

        $scope.user.orgName = sessionState.orgName
        $scope.user.email = sessionState.email
        $scope.user.displayName = sessionState.displayName
        $scope.user.mobilePhone = sessionState.mobilePhone
        $scope.user.locale = sessionState.userLocale
        $scope.user.staySignedIn = sessionState.staySignedIn || false
        $scope.user.priorityAutoFilter = sessionState.priorityAutoFilter || false

        $scope.isRoot = sessionState.isRoot

        //activate the toggles
        $timeout(() => {
          // staySignedIn
          const staySignedIn = angular.element('.checkbox-switch[name=checkbox-stay-signed-in]')
          staySignedIn.on('switchChange.bootstrapSwitch', (event, state) => $scope.user.staySignedIn = state)
          staySignedIn.bootstrapSwitch('state', $scope.user.staySignedIn)

          // priorityAutoFilter
          const priorityAutoFilter = angular.element('.checkbox-switch[name=checkbox-priority-auto-filter]')
          priorityAutoFilter.on('switchChange.bootstrapSwitch', (event, state) => $scope.user.priorityAutoFilter = state)
          priorityAutoFilter.bootstrapSwitch('state', $scope.user.priorityAutoFilter)
        }, 200)

        $scope.updateProfile = () => {
          $scope.loading = true

          // split up displayname
          var splittedName = $scope.user.displayName.split(' ')
          $scope.user.firstName = splittedName[0]
          splittedName.shift()
          $scope.user.lastName = splittedName.join(' ')

          $http.put('/api/user/me/profile',
            $scope.user
          )
            .then(success => {
              $rootScope.$broadcast('index-state-reload')
              notificationHelper.successFrom(success)
            })
            .catch(error => notificationHelper.failureFrom(error))
            .finally(() => {
              $scope.loading = false
              translationService.change($scope.user.locale)
            })
        }
      }
    ])
