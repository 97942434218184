angular.module('mosaik.services')
  .factory('browserService',
    ['$window',
      function ($window) {
        const browser = $window.bowser.getParser($window.navigator.userAgent)

        return {
          browserName: () => browser.parsedResult.browser.name + browser.parsedResult.browser.version,
          browserInfo: () => {
            return {
              browserName: browser.parsedResult.browser.name,
              browserVersion: browser.parsedResult.browser.version,
              osName: browser.parsedResult.os.name,
              osVersion: browser.parsedResult.os.version || ''
            }
          },
          isMobile: () => browser.parsedResult.platform.type === 'mobile'
        }
      }]
  )
