/**
 * This directive broadcast the nav-changed event,
 * telling the nav bar which element should be selected
 */

angular.module('mosaik.directives')
  .directive('navChangedTrigger',
    ['$rootScope',
      $rootScope => {
        return {
          restrict: 'A',
          link: (scope, element, attrs) => $rootScope.$broadcast('nav-changed', attrs.nav)
        }
      }
    ])