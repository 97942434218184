angular.module('mosaik.services')
  .factory('categoryService',
    ['sessionStateService', 'labelService', 'httpService',
      function (sessionStateService, labelService, httpService) {
        const self = {
          categories: function () {
            return sessionStateService.lastSession()
              .then(function (currentState) {
                return httpService.get('/api/category', { dataSubKey: 'data' })
              })
          },

          renderLabel: function (label, value, i, row) {
            return self.format(row)
          },

          format: function (cat) {
            return '<span class="label-mosaik label-mosaik-default" style="' + labelService.getLabelCustomStyleAsText(cat.color, cat.isTextColorDefault) + '">' +
              cat.name + '</span>' + 
              (cat.visibleOnlyByInternalOrgid ? '&nbsp;&nbsp;<small class=\'text-id\'>' + cat.visibleOnlyByInternalOrgid + '</small>' : '')
          },

          formatAsText: function (cat) {
            return cat.name
          }
        }

        return self
      }]
  )