angular.module('mosaik.services')
  .factory('dashboardService',
    ['$http', 'httpService',
      function ($http, httpService) {
        const _store = {}
        const self = {
          setup: () => httpService.get('/admin-api/dashboard/setup'),
          get: url => httpService.get(url),

          formatQuery: function (queryParams, paramsOnce) {
            var tags, groups, categories, contents, roles
            var query = ''
            //format orgid filter
            if (queryParams.orgid) {
              query = '?'
              query += 'orgid=' + queryParams.orgid
            }
            //format state filter
            if (queryParams.state) {
              query = query ? query + '&' : '?'
              query += 'state=' + queryParams.state
            }
            //format activeState filter
            if (queryParams.activeState) {
              query = query ? query + '&' : '?'
              query += 'activeState=' + queryParams.activeState
            }
            //format priorityState filter
            if (queryParams.priorityState) {
              query = query ? query + '&' : '?'
              query += 'priorityState=' + queryParams.priorityState
            }
            //format tags filter
            if (queryParams.tags && queryParams.tags.length) {
              query = query ? query + '&' : '?'
              tags = queryParams.tags.map(function (tag) {
                return tag.id
              }).join(',')
              query += 'tags=' + tags
            }
            // format groups filter
            if (queryParams.groups && queryParams.groups.length) {
              query = query ? query + '&' : '?'
              groups = queryParams.groups.map(function (group) {
                return group.id
              }).join(',')
              query += 'groups=' + groups
            }
            // format organizations filter
            if (queryParams.organizations && queryParams.organizations.length) {
              query = query ? query + '&' : '?'
              orgs = queryParams.organizations.map(function (org) {
                return org.id
              }).join(',')
              query += 'organizations=' + orgs
            }
            //format roles filter
            if (queryParams.roles && queryParams.roles.length) {
              query = query ? query + '&' : '?'
              roles = queryParams.roles.map(function (role) {
                return role.id
              }).join(',')
              query += 'roles=' + roles
            }
            // format categories filter
            if (queryParams.categories && queryParams.categories.length) {
              query = query ? query + '&' : '?'
              categories = queryParams.categories.map(function (cat) {
                return cat.id
              }).join(',')
              query += 'categories=' + categories
            }
            // format contents filter
            if (queryParams.contents && queryParams.contents.length) {
              query = query ? query + '&' : '?'
              contents = queryParams.contents.map(function (content) {
                return content.id
              }).join(',')
              query += 'contents=' + contents
            }
            //format date range filter
            if (queryParams.dateRange) {
              //format startDate filter
              if (queryParams.dateRange.start.isValid()) {
                query = query ? query + '&' : '?'
                query += 'startDate=' + queryParams.dateRange.start.format('YYYY-MM-DD')
              }
              //format endDate filter
              if (queryParams.dateRange.end.isValid()) {
                query = query ? query + '&' : '?'
                query += 'endDate=' + queryParams.dateRange.end.format('YYYY-MM-DD')
              }
            }
            // format content filtering specific params
            if (queryParams.withGroupContents != null) {
              query = query ? query + '&' : '?'
              query += 'withGroupContents=' + (queryParams.withGroupContents === true ? '1' : '0')
            }
            if (queryParams.withNonGroupContents != null) {
              query = query ? query + '&' : '?'
              query += 'withNonGroupContents=' + (queryParams.withNonGroupContents === true ? '1' : '0')
            }
            // visible param
            if (queryParams.visible != null) {
              query = query ? query + '&' : '?'
              query += 'visible=' + (queryParams.visible === true ? '1' : '0')
            }
            // format data format type
            if (queryParams.format) {
              query = query ? query + '&' : '?'
              query += 'format=' + queryParams.format
            }
            // format additional required options query
            if (queryParams.others) {
              query = query ? query + '&' : '?'
              query += queryParams.others
            }
            // format additional query params for once
            if (paramsOnce) {
              query = query ? query + '&' : '?'
              query += paramsOnce
            }
            return query
          },

          ajaxUrl: function (api, queryParams) {
            return api + self.formatQuery(queryParams)
          },

          getVal: function (key) {
            return _store[key]
          },
          
          setVal: function (key, val) {
            _store[key] = val
          }
        }
        return self
      }]
  )