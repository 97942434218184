angular.module('mosaik.services')
  .factory('httpService',
    ['$http', 'cacheService',
      function ($http, cacheService) {
        const self = {
          /**
           * Get cached or remote data from url
           * @param {String} url
           * @param {Object} params Optional
           * @param {Boolean} params.noCache default false, set to true to bypass the cache
           * @param {Boolean} params.noCacheOnEmpty default false, set to true to bypass the cache when the response data array is empty 
           * @param {String} params.dataSubKey default none. Set to the key name used in response.data to store the actual data: response.data[key]
           * @param {function} params.dataParser default none. function to parse the data before setting into cache
           * @param {Boolean} params.forceRefresh default false. Ignore cached data and reload from remote.
           * @param {Boolean} params.createOptions default false. Create <option> html tag array from data.
           * @param {Function} params.renderOption default null. Render callback to create the <option> tag.
           * @returns 
           */
          get: (url, params = {}) => {
            return new Promise((resolve, reject) => {
              if (!params.noCache && !params.forceRefresh) {
                const cached = cacheService.get(url)
                if (cached) {
                  return resolve(cached)
                }
              }

              $http.get(url)
                .then(success => {
                  let data = params.dataSubKey ? success.data[params.dataSubKey] : success.data
                  data = typeof params.dataParser === 'function' ? params.dataParser(data) : data
                  if (params.createOptions) {
                    data.forEach(element => element.option = params.renderOption(element))
                  }
                  resolve(data)
                  // cache or not
                  if (params.noCache || (params.noCacheOnEmpty && data.length === 0)) {
                    return
                  }
                  // cache
                  cacheService.set(url, data)
                }, error => reject(error.data || error))
            })
          },

          put: (url, body) => {
            return new Promise((resolve, reject) => {
              $http.put(url, body)
                .then(success => resolve(success.data), error => reject(error.data || error))
            })
          },

          post: (url, body) => {
            return new Promise((resolve, reject) => {
              $http.post(url, body)
                .then(success => resolve(success.data), error => reject(error.data || error))
            })
          },

          custom: config => $http(config)
        }

        return self
      }]
  )