angular.module('mosaik.services')
  .factory('localeService',
    ['$http',
      function ($http) {
        var _locales
        var _localesLabelValueFormat
        var _localesCodeFormat

        var self = {
          _localesRemote: function () {
            return $http.get('/api/i18n/locales')
              .then(function (response) {
                _locales = response.data
                _localesLabelValueFormat = []
                _localesCodeFormat = []
                _locales.forEach(function (locale, index) {
                  _localesLabelValueFormat.push({ label: locale.id, value: locale.name })
                  _localesCodeFormat.push({ id: index, code: locale.id, name: locale.name, isDefault: locale.isDefault || false })
                })
                return _locales
              })
          },

          localesLabelValue: function () {
            if (!_localesLabelValueFormat) {// load the locales if none yet
              return self._localesRemote()
                .then(function(value) {
                  return _localesLabelValueFormat
                }) 
            }
            // return a promise which resolve to the current loaded _locales object
            return new Promise.resolve(_localesLabelValueFormat)
          },

          localesCodeFormat: function () {
            if (!_localesCodeFormat) { // load the locales if none yet
              return self._localesRemote()
                .then(function(value) {
                  return _localesCodeFormat
                }) 
            }
            // return a promise which resolve to the current loaded _locales object
            return new Promise.resolve(_localesCodeFormat)
          },

          locales: function () {
            if (!locales) {
              return self._localesRemote() // load the locales if none yet
            }
            // return a promise which resolve to the current loaded _locales object
            return new Promise.resolve(_locales)
          }
        }
        return self
      }
    ]
  )