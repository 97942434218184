angular.module('mosaik.services')
  .factory('captchaHelper',
    ['$timeout', '$window', '$http', 'translationService',
      function ($timeout, $window, $http, translationService) {
        var _captchaScript
        var _configLoaded = false
        var _baseurl = ''
        var _sitekey = ''

        function _loadScript(url, callback) {
          // Adding the script tag to the head as suggested before
          var head = document.getElementsByTagName('head')[0]
          var script = document.createElement('script')
          script.type = 'text/javascript'
          script.src = url

          // Then bind the event to the callback function.
          // There are several events for cross browser compatibility.
          script.onreadystatechange = callback
          script.onload = callback

          // Fire the loading
          head.appendChild(script)

          // modification so we can remove this after
          _captchaScript = script

          self.scriptLoaded = true
        }

        function _cleanup() {
          // Delete oldscript
          if (_captchaScript) {
            angular.element(_captchaScript).remove()
            self.scriptLoaded = false
          }
          $window.grecaptcha = undefined
          angular.element(self.widget).empty()
        }

        $window.captchaAutoRenderCallback = function () {
          try {
            $window.grecaptcha.render(self.widget, self.conf)
          } catch (error) {
            console.log(error)
          }
        }

        function _getBaseurlWithLanguage(hlocale) {
          if (hlocale) {
            return _baseurl + '&hl=' + hlocale
          }
          return _baseurl
        }

        function _getSetupConfig() {
          return $http.get('/api/captcha/setup')
            .then(function (response) {
              _configLoaded = true
              _baseurl = response.data.baseurl
              _sitekey = response.data.sitekey
            })
        }

        var self = {
          widget: null,
          widgetid: null,
          url: null,
          locale: translationService.getLocaleCaptcha(),
          scriptLoaded: false,
          conf: null,

          load: function (conf, forceReload) {
            if (forceReload) {
              self.scriptLoaded = false
            }
            if ((self.widgetid === conf.elementid) && (self.locale === translationService.getLocaleCaptcha()) && self.scriptLoaded) {
              // already setup, don't reload
              return
            }
            _cleanup()

            if (!_configLoaded) {
              _getSetupConfig().then(_doSetup)
            } else {
              _doSetup()
            }

            function _doSetup() {
              self.url = _getBaseurlWithLanguage(translationService.getLocaleCaptcha())
              self.widgetid = conf.elementid
              self.widget = document.getElementById(conf.elementid)
              self.conf = conf
              self.conf.sitekey = _sitekey
              self.conf.hl = translationService.getLocaleCaptcha()

              _loadScript(self.url, function () {
                self.locale = translationService.getLocaleCaptcha()
              })
            }
          },

          reset: function () {
            if ($window.grecaptcha) {
              $window.grecaptcha.reset()
            }
          },

          remove: function () {
            _cleanup()
          },
        }

        _getSetupConfig().then(function () { })
        return self
      }
    ]
  )
