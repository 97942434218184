angular.module('mosaik.services')
  .factory('datatablesExportService',
    ['$window',
      function ($window) {

        /** Copied from datatables button.html5.js
           * Create an XML node and add any children, attributes, etc without needing to
           * be verbose in the DOM.
           *
           * @param  {object} doc      XML document
           * @param  {string} nodeName Node name
           * @param  {object} opts     Options - can be `attr` (attributes), `children`
           *   (child nodes) and `text` (text content)
           * @return {node}            Created node
           */
        function _createNode(doc, nodeName, opts) {
          var tempNode = doc.createElement(nodeName);

          if (opts) {
            if (opts.attr) {
              $(tempNode).attr(opts.attr);
            }

            if (opts.children) {
              $.each(opts.children, function (key, value) {
                tempNode.appendChild(value);
              });
            }

            if (opts.text !== null && opts.text !== undefined) {
              tempNode.appendChild(doc.createTextNode(opts.text));
            }
          }

          return tempNode;
        }

        return {
          /**
           * Force string format on the given columns letters
           * @param {[string]} cols List of Excel column letters to force format to string
           * @param {function} customizeCallback Optional customize callback for Excel button. See DT doc.
           * @returns 
           */
          customizeForceStrOnCols: (cols, customizeCallback) => {
            // create a callback for excel customization
            return xlsx => {
              const sheet = xlsx.xl.worksheets['sheet1.xml']
              // force text format on internalUserid column
              sheet.querySelectorAll('row').forEach(row => {
                cols.forEach(col => {
                  const oldCellNode = row.querySelector(`c[r^="${col}"]`)

                if (oldCellNode && !isNaN(oldCellNode.textContent)) {
                  const newCellNode = _createNode(sheet, 'c', {
                    attr: {
                      t: 'inlineStr',
                      r: oldCellNode.getAttribute('r')
                    },
                    children: {
                      row: _createNode(sheet, 'is', {
                        children: {
                          row: _createNode(sheet, 't', {
                            text: (oldCellNode.textContent || '').replace(/[\x00-\x09\x0B\x0C\x0E-\x1F\x7F-\x9F]/g, ''),
                            attr: {
                              'xml:space': 'preserve'
                            }
                          })
                        }
                      })
                    }
                  })
                  row.replaceChild(newCellNode, oldCellNode)
                }
                })
              })

              // callback the customize if any
              if (customizeCallback) customizeCallback(xlsx)
            }
          }
        }
      }
    ]
  )