angular.module('mosaik.directives')
  .directive('repeatContentRefs',
    ['locationHelper', 'contentSessionService',
      function (locationHelper, contentSessionService) {
        return {
          restrict: 'A',
          scope: {
            ref: '=',
            content: '='
          },
          link: (scope, element, attrs) => {
            var href = ''
            // set reference's url
            if (typeof scope.ref.href === 'string') {
              href = scope.ref.href
            } else {
              href = scope.ref.href[scope.content.locale] || scope.ref.href[scope.content.defaultLocale]
            }

            if (href.indexOf('http') === 0) {
              scope.ref.url = href
            } else {
              scope.ref.url = contentSessionService.formatContentPath(scope.content) + href
            }
            // set reference's name
            if (typeof scope.ref.name === 'string') {
              scope.ref.localizedName = scope.ref.name
            } else {
              scope.ref.localizedName = scope.ref.name[scope.content.locale] || scope.ref.name[scope.content.defaultLocale]
            }
          }
        }
      }])