angular.module('mosaik.services')
  .factory('locationHelper',
    [
      '$location', '$state',
      function ($location, $state) {
        return {
          getHostname: function () {
            var port = ([80, 443].indexOf($location.port()) != -1 ? '' : ':' + $location.port())
            return $location.protocol() + '://' + $location.host() + port
          },

          formatRedirectToPath: function (redirectState) {
            var state = $state.get(redirectState.name)
            var href = $state.href(redirectState.name, redirectState.params)

            var path = '/api/redirect/to/' +
            encodeURIComponent(encodeURIComponent(redirectState.base)) + '/' + // note: double encoded cause may contains "/"
            redirectState.name + '/' +
            encodeURIComponent(encodeURIComponent(href)) + 
            (redirectState.params ? '/' + encodeURI(JSON.stringify(redirectState.params)) : '')
            return path
          }
        }
      }
    ]
)
