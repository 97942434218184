angular.module('mosaik.services')
  .factory('groupService',
    ['httpService',
      function (httpService) {
        const renderSpan = group => {
          const ids = []
          if (group.masterInternalOrgid) ids.push(group.masterInternalOrgid)
          if (group.internalGroupid) ids.push(group.internalGroupid)
          const classes = group.masterOrgid ? 'label-mosaik-default' : 'label-mosaik-primary'
          const masterorgid = group.masterOrgid ? `<i class='fa fa-industry fa-fw'></i>&nbsp;&nbsp;` : ''
          const isVisible = !group.isVisible ? `&nbsp;<i class='fa fa-eye-slash fa-fw'></i>` : ''
          const idsHtml = ids.reduce((result, id, idx) => {
            result += result === '' ? '<small class="text-id">' : ', '
            return result + id + (idx === ids.length -1 ? '</small>' : '')
          }, '')
          return `<span class='label-mosaik ${classes}'>${masterorgid}${group.name}</span>${isVisible}${idsHtml}`
        }
        const renderOption = group => `<option value="${group.id}" data-content="${renderSpan(group)}"></option>`

        return {
          groups: options => {
            options = options ? options : { loadLibraries: false, loadMasterOrganization: false, loadOrganizationCount: false }
            const url = '/admin-api/group?loadLibraries=' + 
              `loadLibraries=${options.loadLibraries ? '1' : '0'}` +
              `&loadMasterOrganization=${options.loadMasterOrganization ? '1' : '0'}` +
              `&loadOrganizationCount=${options.loadOrganizationCount ? '1' : '0'}`
            return httpService.get(url, { dataSubKey: 'data', createOptions: true, renderOption })
          },
          format: group => renderSpan(group)
        }
      }]
  )