angular.module('mosaik.services')
  .factory('kbArticleService',
    ['httpService',
      function (httpService) {
        return {
          articles: search => {
            const url = `/api/kb-article${(search ? '?search=' + search : '')}`
            return httpService.get(url, {
              dataSubKey: 'data',
              noCache: !!search // no cache when there is a search input value
            })
          },
          status: kbarticleid => {
            const url = `/api/kb-article/status/${kbarticleid}`
            return httpService.get(url)
          }
        }
      }]
  )