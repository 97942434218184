angular.module('mosaik.services')
  .factory('logEventService',
    ['$http',
      function ($http) {
        var self = {
          events: function (eventType) {
            return $http.get('/admin-api/log-event/' + eventType)
              .then(function (response) {
                return response.data
              })
          }
        }
        return self
      }]
  )