angular.module('mosaik.controllers')
  .controller('changeMyPasswordController',
    ['$scope', '$translate', '$timeout', 'utilService', 'passwordService', 'captchaHelper',
      function ($scope, $translate, $timeout, utilService, passwordService, captchaHelper) {
        $timeout(() => {
          $scope.show = true
          utilService.focusOnDesktop('current-password')
        }, 100)

        passwordService.validateStrategyAccessOrRedirect($scope)

        $scope.model = {
          currentPassword: '',
          password: '',
          confirmPassword: ''
        }

        $scope.constraints = {
          minLength: passwordService.getMinLength()
        }
  
        $scope.togglePassword = () => $scope.showPassword = !$scope.showPassword
        $scope.toggleConfirmPassword = () => $scope.showConfirmPassword = !$scope.showConfirmPassword

        $scope.captchaRequired = false
        const captchaConfig = {
          'elementid': 'recaptcha-container',
          'callback': response => $scope.model.captcha = response,
          'expired-callback': () => $scope.model.captcha = null
        }

        // Scope function to perform actual login request to server
        $scope.change = (gotoRedirectState) => {
          const options = {
            gotoRedirectState: false,
            scope: $scope,
            model: $scope.model,
            api: '/api/password/change/me',
            captchaConfig
          }
          passwordService.changeForMe(options)
        }

        $scope.$on('locale-changed', (event, args) => {
          if ($scope.captchaRequired) {
            captchaHelper.load(captchaConfig)
          }
        })
      }
    ])
