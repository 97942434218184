angular.module('mosaik.controllers')
  .controller('indexContentValueController',
    ['$window', '$scope', 'sessionState', '$stateParams', 'organizations', '$http', '$translate', '$filter', '$timeout', '$compile',
      function ($window, $scope, sessionState, $stateParams, organizations, $http, $translate, $filter, $timeout, $compile) {
        $scope.organizations = organizations
        $scope.selectedOrg = organizations.find(org => $stateParams.organizations == org.id) || organizations[0]
        $scope.query = {
          organizations: []
        }
        $scope.averageHourlyRateTranslation = {
          value: ''
        }
        $scope.totalContentValueTranslation = {
          value: ''
        }
        $scope.currencySymbol = $window.numeral.localeData().currency.symbol
        $scope.canAccessDashboard = sessionState.canAccessDashboard

        const getOrgAverageHourlyRate = (orgid) => {
          $http.get(`/admin-api/dashboard/averageHourlyRate?orgid=${orgid}`)
            .then(response => {
              $scope.averageHourlyRateTranslation.value = $window.numeral(response.data.averageHourlyRate).format('0.00 $')
            })
        }

        const getOrgTotalContentValue = (orgid) => {
          $http.get(`/admin-api/dashboard/contentValue?orgid=${orgid}`)
            .then(response => {
              const value = $window.numeral(response.data.contentValue).format('0,0')
              $scope.totalContentValueTranslation.value = value === '0' ? ` ${value}` : value // bug fix, include space to force string type
            })
        }

        // init details value
        getOrgAverageHourlyRate($scope.selectedOrg.id)
        getOrgTotalContentValue($scope.selectedOrg.id)

        // show the report
        $timeout(() => $scope.show = true)

        const compiledOrganizationsPickerContent = $compile(`
            <h3 class="text-left"><i class="fa fa-industry"></i>&nbsp;&nbsp;${$translate.instant('DashboardContentValueChangeOrganization')}</h3>
            <div class="row text-center"> 
             <div class="col-sm-12 col-md-8 col-md-offset-2">
              <div class="form-group">
               <div class="spacer-15px-bottom"></div>
               <select id="organizations-selectpicker" class="selectpicker form-control show-tick show-menu-arrow" data-style="btn-default" 
                  data-dropdown-align-right="auto" select-picker-change selection="query.organizations" dataset="organizations" options-included="true">
               </select>
               <div class="spacer-15px-bottom"></div>
              </div>
             </div>
            </div>`
        )($scope)
        $scope.changeCurrentOrganization = () => {
          $window.vex.open({
            afterOpen: function () {
              angular.element(this.contentEl).append(compiledOrganizationsPickerContent)
            }
          })
        }

        $scope.$on('select-modified', (event, data) => {
          $timeout(() => {
            $scope.selectedOrg = data.selected
            getOrgAverageHourlyRate($scope.selectedOrg.id)
            getOrgTotalContentValue($scope.selectedOrg.id)
          })
        })
      }
    ]
  )
