.directive('labelFormatTag',
  ['$compile', 'labelService',
    ($compile, labelService) => {
      return {
        restrict: 'A',
        link: (scope, element) => {
          const template = '<span class="label-mosaik label-mosaik-default" style="' + labelService.getLabelCustomStyleAsText(scope.tag.color, scope.tag.isTextColorDefault) + '">' + scope.tag.name + '</span>'
          element.html(template);
          $compile(element.contents())(scope)
        }
      }
    }])