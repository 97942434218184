angular.module('mosaik.controllers')
  .controller('tosController',
    ['$scope', '$state', '$stateParams', '$translate', '$timeout', 'translationService',
      function ($scope, $stat, $stateParams, $translate, $timeout, translationService) {
        // check for locale change request
        const changeLocale = new Promise((resolve) => $stateParams.locale ? translationService.change($stateParams.locale, resolve) : resolve())
        changeLocale.then(() => {
          $timeout(() => {
            $scope.show = true
            $scope.showAddressLogo = $translate.instant('TechnicalAddressLogo') !== ""
          })
        })
      }
    ])
