angular.module('mosaik.services')
  .factory('contentSessionService',
    ['httpService',
      function (httpService) {
        return {
          /**
           * Load my contents for the "library" grid
           * @param {*} params Object with optional boolean properties: excludeSubContents
           * @returns {[*]} Array of contents
           */
          myContents: (params = {}) => httpService.get('/api/content-session/myContents' + (params.excludeSubContents ? '?excludeSubContents=1' : '')),
          /**
           * Load group content's sub-contents
           * @param {*} params Object with optional boolean properties: excludeSubContents
           * @returns {[*]} Array of contents
           */
          subContents: (params = {}) => {
            return httpService.get('/api/content-session/subContents/' + (params.groupContentid ? params.groupContentid : ''), {
              forceRefresh: params.forceRefresh,
              dataParser: data => data.map(item => { return { ...item } })
            })
          },
          /**
           * Load contents for the group training library
           * @param {*} params Object with optional boolean properties: excludeSubContents, forceRefresh
           * @returns {[*]} Array of contents
           */
          groupContents: (params = {}) => httpService.get('/admin-api/content-session/groupContents', { forceRefresh: params.forceRefresh }),

          formatContentPath: content => {
            let configPath = content.configPath || '/'
            if (configPath.slice(0, 1) !== '/') configPath = '/' + configPath // add prefix slash if none
            if (configPath.slice(-1) !== '/') configPath += '/' // add sufix slash if none
            return '/contents/' + encodeURIComponent(content.packageName) + configPath
          }
        }
      }]
  )