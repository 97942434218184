angular.module('mosaik.controllers')
  .controller('contentAverageScoreController',
    ['$scope', 'sessionState', '$translate', '$timeout', '$filter', 'organizations', 'categories', 'groups', 'contents', 'datatablesHelper', 'dashboardService',
      function ($scope, sessionState, $translate, $timeout, $filter, organizations, categories, groups, contents, datatablesHelper, dashboardService) {
        // access rights
        $scope.canAccessDashboard = sessionState.canAccessDashboard
        $scope.isRoot = sessionState.isRoot
        // parameters
        const tableid = '#datatables-content-average-score'
        const tableElement = angular.element(tableid)
        const api = {
          url: '/admin-api/contentAverageScore',
          readType: 'POST'
        }
        $scope.queryHidden = {
          orgid: sessionState.orgidSelector
        }
        $scope.queryDefault = {
          organizations: [],
        }
        $scope.query = {
          organizations: [],
          groups: [],
          contents: [],
          categories: []
        }
        // data
        $scope.organizations = organizations
        $scope.categories = categories
        $scope.groups = groups
        $scope.contents = contents
        // editors
        // DT table
        $scope.table

        $timeout(() => {
          datatablesHelper.init($scope, tableid, api, '', initializeTable)
        })


        const exportOptions = {
          columns: [0, 1, 2, 3, 4]
        }
        const exportMessage = $translate.instant('DashboardContentAverageScoreInYourOrg')

        function initializeTable() {
          const datatableParams = angular.extend(datatablesHelper.getDatatablesParams({ 
            exportOptions,
            exportMessage,
            extraDTOptions: { serverSide: true }
          }), {
            columns: [
              { 
                data: 'titleText', 
                searchable: false, 
                orderable: true
              },
              { 
                data: 'active', 
                searchable: false, 
                orderable: false,
                render: datatablesHelper.renderActiveInactive
               },
               { 
                data: 'completedWithScoreCount', 
                searchable: false, 
                orderable: true
              },
              { 
                data: 'cumulativeAverageScoreScaled', 
                searchable: false, 
                orderable: true,
                render: data => data !== null ? Math.round(data * 100) : ''
              },
              { 
                data: 'cumulativeAverageScoreScaledGlobal', 
                searchable: false, 
                orderable: false,
                render: data => data !== null ? Math.round(data * 100) : ''
              },
              { // écart
                searchable: false,
                orderable: false,
                data: (row, type) => {
                  const diff = Math.round(row.cumulativeAverageScoreScaled * 100) - Math.round(row.cumulativeAverageScoreScaledGlobal * 100)
                  if (!diff) {
                    return ''
                  }
                  let sign, label
                  if (diff > 0) {
                    sign = '+'
                    label = 'success'
                  } else {
                    sign = ''
                    label = 'warning'
                  }
                  return '<div class="label-mosaik label-mosaik-' + label + '">' + sign + diff + '%' + '</div>'
                }
              },
            ],
            select: false
          })
          datatablesHelper.createAndSetTable(datatableParams)
        }
      }
    ]
  )
