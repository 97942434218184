angular.module('mosaik.controllers')
  .controller('memoController',
  ['$scope', '$timeout', '$state', '$compile', 'sessionState', '$http', '$sce', '$rootScope', '$stateParams', '$translate', 'translationService', 'redactorService',
    function ($scope, $timeout, $state, $compile, sessionState, $http, $sce, $rootScope, $stateParams, $translate, translationService, redactorService) {
      $scope.canAccessMemo = sessionState.canAccessMemo
      $scope.user = sessionState
      $scope.showEmptyTitle = false
      $scope.memo = {}

      getData()

      function getData() {        
        $http.get('/api/memo/' + $stateParams.memoid)
          .then(function (response) {
            $scope.memo = response.data
            $scope.showEmptyTitle = (!response.data.id)
            
            var videoContainerElement = ($compile('<div>' + response.data.body + '</div>')($scope))
            var memoElement = videoContainerElement[0]

            redactorService.activateVideoAutoplay(memoElement)
              
            if (response.data.id) {
              markAsRead(response.data.id)
            }

            // call event on first load
            $timeout(function () {
              redactorService.setVideoSize(angular.element('.grid-memo-item-body'), videoContainerElement)
              $scope.body = $sce.trustAsHtml(memoElement.outerHTML)
            })
          })
      }

      function markAsRead(memoid) {
        $http.put('/api/memo/' + $stateParams.memoid + '/viewed')
          .then(function (response) {
            $rootScope.$broadcast('memo-count-reload')
          })
      }

      $scope.gotoMemos = function () {
        $state.go('memos', {}, {reload: 'secure'})
      }

      $scope.$on('locale-changed', function (event, args) {
        getData()
      })

      $scope.dateToHuman = function (date) {
        return translationService.dateToHumanLongFormat(date)
      }
    }
  ]
  )
