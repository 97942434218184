angular.module('mosaik.directives')
  .directive('weakPassword',
    ['$window', '$timeout', 'passwordService',
      ($window, $timeout, passwordService) => {
        return {
          restrict: 'A',
          //scope: 'ngModel',
          require: 'ngModel',
          link: (scope, element, attr, ngModel) => {

            const minLength = passwordService.getMinLength()
  
            ngModel.$validators.weakPassword = (modelValue, viewValue) => {
              if (!modelValue || modelValue.length < minLength) {
                scope.passwordScore = 0
                scope.passwordWarning = ''
                scope.passwordSuggestions = []
                return true // not validating yet
              }
              
              const result = passwordService.analyze(modelValue)
              const notWeak = result.score > 1
              // add warning and suggestions
              scope.passwordScore = result.score
              scope.passwordWarning = result.feedback.warning
              scope.passwordSuggestions = result.feedback.suggestions // array
              // return the validation result
              return notWeak
            }
          }
        }
      }])
