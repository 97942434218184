.directive('shakeCertificate',
  ['animateService',
    function (animateService) {
      return {
        restrict: 'A',
        link: (scope, element, attrs) => {
          element.on('click', () => {
            animateService.animateCss('.modal-content-document-certificat-icon', 'animate__flash', () => animateService.animateCss('.modal-content-btn-start', 'animate__pulse'))
          })
        }
      }
    }])