angular.module('mosaik.controllers')
  .controller('dashboardContentController',
    ['$scope', '$window', 'sessionState', '$translate', '$timeout', 'datatablesHelper', 'organizations', 'categories', 'utilService', '$interpolate', 'tagService', 'contentService', 'notificationHelper', 'fileService', 'translationService',
      function ($scope, $window, sessionState, $translate, $timeout, datatablesHelper, organizations, categories, utilService, $interpolate, tagService, contentService, notificationHelper, fileService, translationService) {
        // access rights
        $scope.canAccessDashboard = sessionState.canAccessDashboard
        $scope.isRoot = sessionState.isRoot
        // parameters
        const tableid = '#datatables-content'
        const tableElement = angular.element(tableid)
        const api = {
          url: '/admin-api/organization-contents',
          readType: 'POST'
        }
        $scope.queryHidden = {
          orgid: sessionState.orgidSelector
        }
        $scope.queryDefault = {
          specialState: 'all',
          activeState: 'active',
          organization: sessionState.isRoot ? sessionState.orgid : undefined,
          tagFilterMethod: 'or'
        }
        $scope.query = {
          tagFilterMethod: '',
          tags: [],
          organization: '',
          categories: [],
          specialState: 'all',
          activeState: 'active'
        }
        // data
        $scope.organizations = organizations
        $scope.categories = categories
        $scope.tags = [] // Only load tags accessible for the selected organization
        const organizationsAsObjects = utilService.mapObjectsByKey(organizations)
        const categoriesAsObjects = utilService.mapObjectsByKey(categories)
        let tagsAsObjects = {}
        // editors
        let editorVisibility
        let editorPriority
        let editorRecurrence
        // DT table
        $scope.table
        // single row selection tracker
        let selectionParentDiv

        $timeout(() => datatablesHelper.init($scope, tableid, api, '', () => {
          loadTags().then(() => initializeTable())
        }))

        $scope.$on('filter-param-loaded', (event, data) => {
          if (data.key === 'organization') {
            loadTags()
          }
        })

        function initializeTable() {
          // editor for visibility state
          editorVisibility = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              name: 'titleDivider',
              type: 'title'
            }, {
              type: 'hidden',
              name: 'id'
            }, {
              type: 'hidden',
              name: 'orgid'
            }, {
              label: $translate.instant('ContentIsVisible'),
              name: 'isHidden',
              type: 'radio',
              options: datatablesHelper.getYesNoOptionsLabelsReverse($translate.instant('ShowContent'),  $translate.instant('HideContent')),
              default: 0
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: '/admin-api/organization-contents/visibility/_id_'
              }
            }
          }))

          editorVisibility.on('postSubmit', (e, json, data, action) => $scope.$emit('data-modified', 'content'))


          // editor for priority state
          editorPriority = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              name: 'titleDivider',
              type: 'title'
            }, {
              type: 'hidden',
              name: 'id'
            }, {
              type: 'hidden',
              name: 'orgid'
            }, {
              label: $translate.instant('ShowAsPriority'),
              name: 'isPriority',
              type: 'radio',
              options: datatablesHelper.getYesNoOptionsLabels(),
              default: 0
            }, {
              label: $translate.instant('PriorityAppliesToAllTags'),
              name: 'isPriorityAppliedToAllTags',
              type: 'radio',
              options: datatablesHelper.getYesNoOptionsLabels(),
              default: 1,
              data: (data, type, set) => !data.tags || data.tags.length === 0 ? 1 : 0,
            }, {
              label: $translate.instant('PriorityOnlyAppliesToTags'),
              name: 'tags[].tagid',
              type: 'selectPicker',
              className: 'with-label',
              multiple: true,
              attr: {
                'data-multiple-separator': ' ',
                required: true,
                name: 'tags[].tagid'
              },
              optionsPair: {
                label: 'name',
                value: 'id'
              },
              renderLabel: tagService.renderLabel
            }, {
              name: 'xDivider',
              type: 'divider'
            }, {
              label: '',
              name: 'editRecurringRule',
              type: 'checkboxFlex',
              options: [{ label: $translate.instant('EditRecurringRuleAfter'), value: true }],
              unselectedValue: false,
              separator: ''
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: '/admin-api/organization-contents/priority/_id_'
              }
            }
          }))

          editorPriority.dependent('isPriority', (val, data) => {
            if (val) {
              const fields = ['isPriorityAppliedToAllTags']
              if (!data.values.isPriorityAppliedToAllTags) {
                fields.push('tags[].tagid')
              }
              return {
                show: fields,
                enable: fields
              }
            } else {
              const fields = ['isPriorityAppliedToAllTags', 'tags[].tagid']
              return {
                hide: fields,
                disable: fields
              }
            }
          })

          editorPriority.dependent('isPriorityAppliedToAllTags', (val, data) => {
            const field = ['tags[].tagid']
            if (!val && data.values.isPriority) {
              return {
                show: field,
                enable: field
              }
            }
            return {
              hide: field,
              disable: field
            }
          })

          editorPriority.on('preSubmit', (e, data, action) => {
            const contentids = Object.keys(data.data)
            // use first edited content to check for the editRecurringRule 
            editorPriority.editRecurringRule = data.data[contentids[0]].editRecurringRule
            return datatablesHelper.onPreSubmit(action, editorPriority)
          })
          editorPriority.on('submitSuccess', (e, json, data, action) => {
            if (editorPriority.editRecurringRule) {
              // make sure there is a row(s) selection
              let selection = $scope.table.rows({ selected: true })
              if (selection[0].length === 0) {
                datatablesHelper.selectClosestRow($scope.table, selectionParentDiv)
                selection = $scope.table.rows({ selected: true })
              }
              $timeout(() => editRecurrence(selection), 400)
            }
          })
          editorPriority.on('postSubmit', (e, json, data, action) => $scope.$emit('data-modified', 'content'))


          // editor for recurring state
          editorRecurrence = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), { // onCompletedBefore onEveryInMonth
            fields: [{
              name: 'titleDivider',
              type: 'title'
            }, {
              type: 'hidden',
              name: 'id'
            }, {
              type: 'hidden',
              name: 'orgid'
            }, {
              label: $translate.instant('RecurringMethod'),
              name: 'recurringMethod',
              data: (row, type) => {
                if (row.toRewatchOnCompletedBefore) return 1
                if (row.toRewatchOnEveryInMonth) return 2
                return 0
              },
              type: 'radio',
              options: [
                { label: datatablesHelper.radioLabel('RecurringChoiceNone', ''), value: 0 },
                { label: datatablesHelper.radioLabel('RecurringChoiceOnce', ''), value: 1 },
                { label: datatablesHelper.radioLabel('RecurringChoiceMany', ''), value: 2 }
              ],
              default: 0
            }, {
              label: $translate.instant('RecurringOnCompletedBefore'),
              name: 'onCompletedBefore',
              type: 'datetime',
              displayFormat: 'D MMM YYYY',
              wireFormat: 'YYYY-MM-DD',
              opts: {
                momentLocale: $window.moment.locale()
              },
              default: () => new Date(),
              data: data => data.toRewatchOnCompletedBefore ? new Date(data.toRewatchOnCompletedBefore) : new Date(),
              attr: {
                required: true,
                name: 'onCompletedBefore'
              },
              fieldInfo: $translate.instant('RecurringOnCompletedBeforeHint')
            }, {
              label: $translate.instant('RecurringOnEveryInMonth'),
              name: 'onEveryInMonth',
              type: 'numberLabel',
              innerLabel: $translate.instant('Months'),
              attr: {
                min: '1',
                required: true,
                name: 'onEveryInMonth'
              },
              data: data => data.toRewatchOnEveryInMonth ? data.toRewatchOnEveryInMonth : 12,
              default: 12,
              fieldInfo: $translate.instant('RecurringOnEveryInMonthHint')
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: '/admin-api/organization-contents/recurrence/_id_'
              }
            }
          }))

          editorRecurrence.dependent('recurringMethod', (val, data) => {
            const onCompletedBefore = 'onCompletedBefore'
            const onEveryInMonth = 'onEveryInMonth'

            if (val === 0) {
              return {
                hide: [onCompletedBefore, onEveryInMonth],
                disable: [onCompletedBefore, onEveryInMonth]
              }
            } else if (val === 1) {
              return {
                show: [onCompletedBefore],
                enable: [onCompletedBefore],
                hide: [onEveryInMonth],
                disable: [onEveryInMonth]
              }
            } else {
              return {
                show: [onEveryInMonth],
                enable: [onEveryInMonth],
                hide: [onCompletedBefore],
                disable: [onCompletedBefore]
              }
            }
          })

          editorRecurrence.on('preSubmit', (e, data, action) => {
            const ignores = new Set()
            for (let id in data.data) {
              let row = data.data[id]
              // skip one the rule field based on selected recurring method
              if (row.recurringMethod === 0 || row.recurringMethod === 1) {
                delete row.onEveryInMonth
                ignores.add('onEveryInMonth')
              }
              if (row.recurringMethod === 0 || row.recurringMethod === 2) {
                delete row.onCompletedBefore
                ignores.add('onCompletedBefore')
              }
            }
            return datatablesHelper.onPreSubmit(action, editorRecurrence, [...ignores])
          })

          editorRecurrence.on('postSubmit', (e, json, data, action) => $scope.$emit('data-modified', 'content'))

          const extraButtons = [{
            extend: 'edit',
            editor: editorPriority,
            text: $translate.instant('ModifyPriorityState'),
            action: (e, dt) => editPriority(dt.rows({ selected: true }))
          }, {
            extend: 'edit',
            editor: editorRecurrence,
            text: $translate.instant('ModifyRecurringState'),
            action: (e, dt) => editRecurrence(dt.rows({ selected: true }))
          }, {
            extend: 'edit',
            text: $translate.instant('ShowQuiz'),
            action: (e, dt) => showQuiz(dt.rows({ selected: true }))
          }]

          if (sessionState.canHideContent) {
            extraButtons.push({
              extend: 'edit',
              editor: editorPriority,
              text: $translate.instant('ModifyVisibility'),
              action: (e, dt) => editVisibility(dt.rows({ selected: true }))
            })
          }

          const exportOptions = {
            columns: [0,1,2,3,5,6,7,8,9]
          }
          const exportMessage = $translate.instant('ContentsTitle')

          const datatableParams = angular.extend(datatablesHelper.getDatatablesParams({
            editor: editorPriority,
            exportOptions,
            hiddenButtons: ['create', 'edit', 'remove'],
            extraButtons,
            exportMessage,
            extraDTOptions: { serverSide: true }
          }), {
            columns: [
              {
                data: 'id',
                searchable: false,
              }, {
                data: 'titleText',
                render: datatablesHelper.renderContentTitle
              }, {
                data: 'durationInMinutes',
                render: data => data || ''
              }, {
                data: 'categories',
                orderable: false,
                searchable: false,
                render: (data, type, full, meta) => datatablesHelper.renderCategories(data, type, full, meta, categoriesAsObjects)
              }, {
                data: 'isHidden',
                visible: false,
                className: 'never',
                orderable: false,
                searchable: false
              }, {
                data: 'tagsVisibility',
                orderable: false,
                searchable: false,
                render: (data, type, full, meta) => {
                  if (full.isHidden) return `<small class="text-sub"><span class=\"fa fa-fw fa-eye-slash\"></span>&nbsp;${$translate.instant('Hidden')}</small>`
                  if (!data || !data.length) return `<small class="text-sub">${$translate.instant('VisibleForAll')}</small>`
                  let result = `<small class="text-sub">${$translate.instant('VisibleForMembersOf')}</small>&nbsp;<br>`
                  return result + datatablesHelper.renderTags(data, type, full, meta, tagsAsObjects)
                }
              }, {
                data: 'isPriority',
                searchable: false,
                render: (data, type, full, meta) => datatablesHelper.renderYesNo(data, type, full, meta, undefined, undefined, 'flag-checkered')
              }, {
                data: 'tags',
                orderable: false,
                searchable: false,
                render: (data, type, full, meta) => {
                  if (!full.isPriority) {
                    return ''
                  }
                  if (!data || !data.length) {
                    return '<small class="text-sub">' + $translate.instant('AllTags') + '</small>'
                  }
                  return datatablesHelper.renderTags(data, type, full, meta, tagsAsObjects)
                }
              }, {
                data: 'isRecurrent',
                searchable: false,
                render: (data, type, full, meta) => datatablesHelper.renderYesNo(data, type, full, meta, undefined, undefined, 'repeat')
              }, { // recurring rule
                orderable: false,
                searchable: false,
                data: (row, type) => {
                  if (row.toRewatchOnCompletedBefore) {
                    return $translate.instant('RecurringOnce', { humanDate: translationService.dateToHumanLongFormat(row.toRewatchOnCompletedBefore) })
                  }
                  if (row.toRewatchOnEveryInMonth) {
                    return $translate.instant('RecurringEveryInMonth', { count: row.toRewatchOnEveryInMonth })
                  }
                  return ''
                }
              }, { // render actions menu
                searchable: false,
                orderable: false,
                data: (row, type) => {
                  const org = organizationsAsObjects[row.orgid]
                  const template = `
                    <div class="dt-buttons btn-group dt-button-actions dropdown">
                      <button type="button" class="btn btn-defaut btn-icon btn-left dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-h"></i> 
                      </button> 
                      <ul class="dropdown-menu dropdown-menu-right">
                        <li class="dt-button"><a id="edit-priority" data-id="${row.id}">{{"ModifyPriorityState" | translate}}</a></li>
                        <li class="dt-button"><a id="edit-reccurence" data-id="${row.id}">{{"ModifyRecurringState" | translate}}</a></li>
                        ${sessionState.canHideContent ? `
                        <li class="dt-button"><a id="edit-visibility" data-id="${row.id}">{{"ModifyVisibility" | translate}}</a></li>` : ''}
                        <li role="separator" class="divider"></li>
                        <li class="dt-button"><a id="show-quiz" data-id="${row.id}">{{"ShowQuiz" | translate}}</a></li>
                        ${org.canAccessXApi ? `
                        <li role="separator" class="divider"></li>
                        <li class="dt-button"><a id="download-xapi" data-id="${row.id}">{{"DownloadXApiPackage" | translate}}</a></li>` : ''}
                      </ul>
                    </div>`
                  return ($interpolate(template)($scope))
                }
              }
            ],
            order: [[1]],
            select: true,
            rowId: 'id'
          })
          datatablesHelper.createAndSetTable(datatableParams)

          // On table select
          $scope.table.on('select.dt.DT deselect.dt.DT', () => {
            const data = $scope.table.rows({ selected: true }).data()
            const selected = data.any()
            const singleSelected = data.length === 1

            // disable the "show quiz" button if more than 1 row is selected
            $scope.table.buttons([2]).enable(selected && singleSelected)
          })
        }

        function loadTags() {
          const orgid = $scope.query.organization || sessionState.orgid
          return tagService.tags(orgid, 'all')
            .then((tags) => {
              $scope.tags = tags
              tagsAsObjects = utilService.mapObjectsByKey(tags)
              $timeout(() => $scope.$broadcast('picker-dataset-change', 'tags'))
            })
        }

        $scope.$on('select-modified', (event, data) => {
          if (data.id === 'organizations-selectpicker') {
            loadTags()
          }
        })
        $scope.$on('filter-param-clear', (event, data) => {
          if (data.key === 'organization') {
            loadTags()
          }
        })

        function editVisibility(selectedRows) {
          const selectedData = selectedRows.data()
          const title = selectedData.length === 1 ? selectedData[0].titleText : $translate.instant('EditContentMultiple', { count: selectedData.length })

          editorVisibility.title($translate.instant('EditContentVisibility', { count: selectedData.length }))
          editorVisibility.field('titleDivider').label(title)
          editorVisibility.edit(selectedRows.indexes(), {
            buttons: $translate.instant('ChangeContentVisibility')
          })
          editorVisibility.open()
        }

        function editPriority(selectedRows) {
          const selectedData = selectedRows.data()
          const title = selectedData.length === 1 ? selectedData[0].titleText : $translate.instant('EditContentMultiple', { count: selectedData.length })

          editorPriority.title($translate.instant('EditContentPriorityState', { count: selectedData.length }))
          editorPriority.field('titleDivider').label(title)
          editorPriority.field('tags[].tagid').update($scope.tags)
          editorPriority.edit(selectedRows.indexes(), {
            buttons: $translate.instant('ChangeContentPriorityState')
          })
          editorPriority.open()
        }

        function editRecurrence(selectedRows) {
          const selectedData = selectedRows.data()
          const title = selectedData.length === 1 ? selectedData[0].titleText : $translate.instant('EditContentMultiple', { count: selectedData.length })

          editorRecurrence.title($translate.instant('EditContentRecurringRule', { count: selectedData.length }))
          editorRecurrence.field('titleDivider').label(title)
          editorRecurrence.edit(selectedRows.indexes(), {
            buttons: $translate.instant('ChangeContentRecurringRule')
          })
          editorRecurrence.open()
        }

        function showQuiz(selectedRows) {
          const data = selectedRows.data()[0]

          contentService.setQuizViewer(data.id, data.locale)
            .then(response => {
              if (!response.success) {
                return $window.Swal.fire({
                  title: $translate.instant('NoQuiz'),
                  icon: 'warning'
                })
              }
              // open the viewer
              contentService.showQuizViewer()
            })
        }

        function downloadXApiPackage(selectedRows) {
          const data = selectedRows.data()[0]
          contentService.getXapiPackage(data.id, data.orgid)
            .then(response => {
              if (!response) return
              fileService.showDownload(response, 'XAPIDownload', 'zip')
            })
            .catch(error => {
              notificationHelper.failureFromBlob(error)
            })
        }

        function doAction(scope, fnAction) {
          selectionParentDiv = datatablesHelper.getParentDiv(scope)
          datatablesHelper.selectClosestRow($scope.table, selectionParentDiv)
          fnAction($scope.table.rows({ selected: true }))
        }

        tableElement.on('click', '#edit-visibility:not(.disabled)', (event) => doAction(event.target, editVisibility))
        tableElement.on('click', '#edit-priority:not(.disabled)', (event) => doAction(event.target, editPriority))
        tableElement.on('click', '#edit-reccurence:not(.disabled)', (event) => doAction(event.target, editRecurrence))
        tableElement.on('click', '#show-quiz:not(.disabled)', (event) => doAction(event.target, showQuiz))
        tableElement.on('click', '#download-xapi:not(.disabled)', (event) => doAction(event.target, downloadXApiPackage))
      }
    ])
