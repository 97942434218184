angular.module('mosaik.directives')
  .directive('getMemoCount',
    ['$http', '$timeout', 'animateService', 'translationService',
      function ($http, $timeout, animateService, translationService) {
        return {
          restrict: 'A',
          scope: {
            count: '=',
            canAccess: '@'
          },
          link: function (scope, element, attrs) {
            var currentLocale = null
            scope.$on('memo-count-reload', function (event, args) {
              loadMemoCount(translationService.getLocaleShort())
            })
            scope.$on('locale-changed', function (event, args) {
              if (currentLocale !== args.locale) { // don't load if already loaded in correct locale   
                loadMemoCount(args.locale)
              }
            })

            loadMemoCount()

            function loadMemoCount (locale) {
              if (!scope.canAccess) {
                return
              }
              currentLocale = locale
              $http.get('/api/memo/count')
                .then(function (response) {
                  var previousCount = scope.count

                  if (scope.count !== response.data.count) {
                    scope.count = response.data.count

                    $timeout(function () {
                      // animate the memo count 
                      if (previousCount === 0 && response.data.count > 0) {
                        animateService.animateCss(element, 'animate__fadeIn', function () {
                          $timeout(function () {
                            pulse()
                          }, 200)
                        })
                        return
                      }
                      // hide the memo count if 0
                      if (response.data.count === 0) {
                        animateService.animateCss(element, 'animate__fadeOut')
                        return
                      }
                      pulse()
                    })

                    function pulse () {
                      animateService.animateCss(element, 'animate__bounceIn')
                    }
                  }
                })
            }
          }
        }
      }])
