angular.module('mosaik.services')
  .factory('indexService',
    ['$http', '$window', 'config',
      function ($http, $window, config) {
        return {
          setup: function () {
            return $http.get('/api/index/setup')
              .then(function (response) {
                return response.data
              })
          },
          canInstallApp: function () {
            return $window.installApp.deferredPrompt != null && config.serviceWorker.active && config.serviceWorker.showInstallApp
          },
          promptInstallApp: function () {
            return new Promise(function (resolve) {
              if ($window.installApp.deferredPrompt) {
                try {
                  $window.installApp.deferredPrompt.prompt()
                }
                catch(err) {
                  console.error(err)
                  console.log('Cannot prompt')
                  $window.installApp.deferredPrompt = null
                  return resolve()
                }
                // Wait for the user to respond to the prompt
                $window.installApp.deferredPrompt.userChoice.then(function (choiceResult) {
                  var accepted = choiceResult.outcome === 'accepted'
                  if (accepted) {
                    $window.installApp.deferredPrompt = null
                  }
                  resolve(accepted)
                })
              } else {
                resolve()
              }
            })
          }
        }
      }
    ]
  )