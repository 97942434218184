angular.module('mosaik.controllers')

  .controller('inviteController',
    ['$scope', 'sessionState', '$state', '$window', '$translate', 'sessionStateService', '$timeout',
      function ($scope, sessionState, $state, $window, $translate, sessionStateService, $timeout) {
        if (!sessionState.canInvite) {
          return $state.go('landing', {}, {reload: 'secure'})
        }

        $timeout(function () {
          $scope.show = true
        })
      }
    ])
