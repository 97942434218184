angular.module('mosaik.controllers')
  .controller('translationController',
  ['$scope', '$translate', 'translationService', 'sessionStateService',
    function ($scope, $translate, translationService, sessionStateService) {
      var languages = translationService.getLanguagesAsMap()
      $scope.localeShort = translationService.getLocaleShort()
      $scope.showFR = languages['fr'] != null
      $scope.showEN = languages['en'] != null
      $scope.showES = languages['es'] != null

      $scope.changeLanguage = function (langkey) {
        translationService.change(langkey, function (langkey) {
          $scope.localeShort = langkey
        })
      }

      $scope.keyIsEmpty = function (key) {
        var value = $translate.instant(key)
        return value === '' || value === key
      }
    }
  ])
