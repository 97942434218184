angular.module('mosaik.controllers')

  .controller('indexController',
    ['$scope', '$rootScope', '$state', 'sessionStateService', 'indexService', '$window', '$timeout', '$http', '$translate', 'notificationHelper', 'logoService',
      function ($scope, $rootScope, $state, sessionStateService, indexService, $window, $timeout, $http, $translate, notificationHelper, logoService) {
        $scope.sessionState = {}
        $scope.setup = {}
        $scope.memoCount = 0
        $scope.showLoader = false
        let loaderStopped = false

        $rootScope.$on('start-loader', () => {
          loaderStopped = false
          $timeout(() => {
            $scope.showLoader = !loaderStopped
          }, 150)
        })

        $rootScope.$on('stop-loader', () => {
          loaderStopped = true
          $scope.showLoader = false
        })

        $scope.showInstallApp = false
        $scope.promptInstallApp = () => {
          const userChoice = indexService.promptInstallApp()
          userChoice.then(accepted => $scope.showInstallApp = indexService.canInstallApp())
        }

        const sessionState = () => {
          sessionStateService.session().then(sessionState => {
            $scope.sessionState = sessionState
            if ($scope.sessionState.connected) $rootScope.$broadcast('memo-count-reload')
          })
        }
        const indexSetup = () => indexService.setup().then(setup => $scope.setup = setup)

        sessionState()
        indexSetup()

        $scope.$on('beforeinstallprompt', (event, args) => $scope.showInstallApp = indexService.canInstallApp())

        $scope.$on('index-state-reload', (event, args) => {
          sessionState()
          indexSetup()
        })

        $scope.$on('locale-changed', (event, args) => logoService.setLogo())

        $scope.$on('nav-changed', (event, args) => {
          // set focus on current nav item
          // remove current focus state
          angular.element('ul.nav a').removeClass('nav-selected')
          // set focus state to current
          angular.element('ul.nav #nav-' + args).addClass('nav-selected')
        })



        $scope.redeem = () => {
          if (!$scope.sessionState.orgid) return $state.go('redeem', {}, { reload: true })

          $window.Swal.fire({
            icon: 'warning',
            title: $translate.instant('RedeemButRegistered'),
            html: $translate.instant('RedeemButRegisteredHint'),
            showCancelButton: true,
            confirmButtonText: $translate.instant('Yes'),
            cancelButtonText: $translate.instant('MaybeNot')
          }).then(result => result.value ? $state.go('redeem', {}, { reload: true }) : null)
        }

        $scope.signout = () => {
          notificationHelper.removeAll()
          sessionStateService.terminateSession()
            .catch(error => notificationHelper.failureFrom(error))
            .finally(() => {
              const logoutUrl = $scope.setup.logoutUrl
              const strategyLogoUrl = $scope.setup.strategyLogoUrl
              const strategyName = $scope.setup.strategyName
              // clean
              $scope.loading = false
              $rootScope.requestedState = {}
              $scope.sessionState = {}
              $scope.setup = {}

              if (!logoutUrl) return $state.go('passport', {}, { reload: true }) // bc local strategy

              // In social strategy, we ask for complete logout
              $window.Swal.fire({
                imageUrl: strategyLogoUrl,
                imageWidth: 100,
                title: $translate.instant('PassportAlsoLogoutFromStrategy', { strategyName: strategyName }),
                html: $translate.instant('PassportAlsoLogoutFromStrategyHint', { strategyName: strategyName }),
                showCancelButton: true,
                confirmButtonText: $translate.instant('Yes'),
                cancelButtonText: $translate.instant('No')
              }).then(result => result.value ? $window.location.href = logoutUrl : $state.go('passport', {}, { reload: true }))
            })
        }
      }
    ])
