angular.module('mosaik.controllers')
  .directive('navTabs',
    ['$location', '$timeout',
      function ($location, $timeout) {
        return function (scope, element, attrs) {
          var defaultTab = element.find('.active a').attr('data-target').substring(1)
          $timeout(function () {
            // load requested tab or default
            var tab = $location.search().tab
            if (tab) {
              // go to tab
              element.find('a[data-target="#' + tab + '"]').click()
            }
          })

          // listen to search change event
          scope.$on('$locationChangeSuccess', function () {
            $timeout(function () {
              var targetTab = $location.search().tab || defaultTab
              var selectedTab = element.find('.active a').attr('data-target').substring(1)
              // only click if the tab change did not occur (means tab change was not from a click)
              if (targetTab !== selectedTab) {
                element.find('a[data-target="#' + targetTab + '"]').click()
              }
            })
          })
        }
      }
    ])
  .directive('navTab',
    ['$location', '$timeout',
      function ($location, $timeout) {
        return function (scope, element, attrs) {
          element.click(function (e) {
            e.preventDefault()
            element.tab('show')
            $timeout(function () {
              $location.search('tab', attrs.target.substring(1))
              scope.$broadcast('nav-tab-change', { tab: attrs.target.substring(1) })
            })
          })
        }
      }
    ])