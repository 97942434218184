angular.module('mosaik.services')
  .factory('userRoleService',
    ['httpService', '$translate',
      function (httpService, $translate) {
        const self = {
          roles: () => httpService.get('/console-api/user-role'),
          rolesAccessible: () => httpService.get('/admin-api/user-role/accessible'),
          format: role => `<span class="label-mosaik label-mosaik-default">${role.name || role}</span>`,
          formatTranslate: role => `<span class="label-mosaik label-mosaik-default">${$translate.instant(role.name || role)}</span>`,
          formatAsText: role => role.name
        }
        return self
      }]
  )