angular.module('mosaik.directives')
  .directive('stateFilterField',
    ['$location', '$timeout',
      function ($location, $timeout) {
        // shared scope with parent, because ng-change and ng-model use
        // Parent scope should have a search property and and a search callback function.
        return {
          scope: {
            state: '=',
            paramName: '@'
          },
          link: function (scope, element, attrs) {
            var input = element.find('input')
            if (!scope.paramName) {
              scope.paramName = 'state' // default param name
            }

            $timeout(function () {
              var queryValue = $location.search()[scope.paramName]
              // get and set the query value if any
              if (queryValue) {
                scope.state = queryValue
              }
              // set default value if none provided
              if (input.attr('checked') && !scope.state) {
                scope.state = attrs.value
              }
              setLocation()
              setElementValue()

              // listeners for onClick
              element.bind("click", function (event) {
                scope.state = attrs.value
                $timeout(setLocation)
                scope.$emit('state-modified', { key: scope.paramName, value: scope.state })
              })
            })

            // listen to state change from filter or params
            scope.$on('filter-param-loaded', filterParamChangeEvent)
            scope.$on('filter-param-clear', filterParamChangeEvent)

            function filterParamChangeEvent(event, args) {
              if (args.key === scope.paramName) {
                // if cleared, set default
                $timeout(function () {
                  scope.state = args.value
                  $location.search(scope.paramName, scope.state)
                  $location.replace()
                  setElementValue()
                })
              }
            }

            function setLocation() {
              if (attrs.doNotTrack != null) {
                return
              }
              $location.search(scope.paramName, scope.state ? scope.state : null)
              $location.replace()
            }

            function setElementValue() {
              if (scope.state === attrs.value) {
                input.attr('checked', 'checked')
                element.addClass('active')
              } else {
                input.removeAttr('checked')
                element.removeClass('active')
              }
            }
          }
        }
      }
    ])

angular.module('mosaik.directives')
  .directive('stateFilterFieldGroupUpdate',
    ['$location', '$timeout',
      function ($location, $timeout) {
        // use on parent "group" element wrapper for a state radio buttons group.
        // It manually updates the group buttons and input classe and visual state
        // Usefull if case the click event proagation on a group button was stop 
        // Ex: a group buttons in a dropdown menu, in that case, use this directive on the parent btn-group div
        return {
          link: function (scope, element, attrs) {
            var input = element.find('input')

            scope.$on('state-modified', function (event, stateObj) {
              $timeout(function () {
                element.find('label').each(function () {
                  var label = angular.element(this)
                  var labelParamName = label.attr('param-name') || 'state'
                  var input = label.find('input')

                  if (labelParamName !== stateObj.key) {
                    return
                  }
                  // Force update the buttons' state
                  if (label.attr('value') === stateObj.value) {
                    input.attr('checked', 'checked')
                    label.addClass('active')
                  } else {
                    input.removeAttr('checked')
                    label.removeClass('active')
                  }
                })
              })
            })
          }
        }
      }
    ])