angular.module('mosaik.services')
  .factory('moduleService',
  ['$window', '$rootScope', '$timeout', '$transitions', '$translate', 'sessionStateService', 'translationService',
    function ($window, $rootScope, $timeout, $transitions, $translate, sessionStateService, translationService) {

      var self = {
        listenForInstallPrompt: () => {
          $window.addEventListener('beforeinstallprompt', event => {
            $window.installApp.deferredPrompt = event
            $rootScope.$broadcast('beforeinstallprompt', event)
          })
        },

        setCurrentStateRetainer: baseHref => {
          $rootScope.baseHref = baseHref || '/'
          $transitions.onBefore({}, transition => {
            sessionStateService.retainCurrentState(transition.to().name, transition.params())
          })
        },

        listenForStateTransitions: appNameKey => {
          // Set the document title on state change
          $transitions.onSuccess({}, transition => {
            const to = transition.to()
            const meta = to.meta
            const pageTitle = $translate.instant(appNameKey) + (meta && meta.title ? ' - ' + $translate.instant(meta.title) : '')

            $timeout(() => { // Needed to ensure the title is changed *after* the url so that history entries are correct.
              $window.document.title = pageTitle
              // special events, some services may need to know when a state is sucessfully entered :
              //   myContents state
              if (to.name === 'myContents') $rootScope.$broadcast('state-success-' + to.name)
            })
          })
          $transitions.onError({}, transition => {
            const data = transition._error.detail ? transition._error.detail.data : {}
            const status = transition._error.detail ? transition._error.detail.status : data.status

            // send back to home page on trying to access a restricted or invalid resource
            if (status === 401 || status === 404) {
              sessionStateService.goHome()
            }
          })
        },

        loadLanguages: () => {
          // load the languages async
          translationService.initialize().then(langkey => { })
        }
      }
      return self
    }]
  )