angular.module('mosaik.directives')
  .directive("selectPickerSetContentAttrRole",
    ['userRoleService', userRoleService => {
      return {
        link: (scope, element, attr) => {
          attr.$set("data-content", userRoleService.format(scope.role))
          element.text(userRoleService.formatAsText(scope.role))
        }
      }
    }])

  .directive("selectPickerSetContentAttrCategory",
    ['categoryService', categoryService => {
      return {
        link: (scope, element, attr) => {
          attr.$set("data-content", categoryService.format(scope.cat))
          element.text(categoryService.formatAsText(scope.cat))
        }
      }
    }])

  .directive("selectPickerSetContentAttrContent",
    ['contentService', contentService => {
      return {
        link: (scope, element, attr) => {
          attr.$set("data-content", contentService.format(scope.content))
          element.text(contentService.formatAsText(scope.content))
        }
      }
    }])

    .directive("selectPickerSetContentAttrState",
    ['stateService', stateService => {
      return {
        link: (scope, element, attr) => {
          attr.$set("data-content", stateService.format(scope.state.key))
          element.text(stateService.formatAsText(scope.state.key))
        }
      }
    }])