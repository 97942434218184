angular.module('mosaik.services')
  .factory('fileService',
    ['$window', '$translate',
      function ($window, $translate) {
        const acceptedTypes = ['zip', 'pdf', 'xlsx', 'csv', 'txt']
        const getIconClass = fileType => {
          switch (fileType) {
            case 'zip':
              return 'fa-file-archive-o'
            case 'pdf':
              return 'fa-file-pdf-o'
            case 'xlsx':
              return 'fa-file-excel-o'
            case 'csv':
            case 'txt':
              return 'fa-file-text-o'
            default:
              return 'fa-file-o'
          }
        }
        const getDefaultFileName = (fileType, filename = '') => {
          filename = filename || (fileType === 'zip' ? 'archive' : 'document')
          return `${filename}.${fileType}`
        }
        const getContentDisposition = (response, defaultFilename) => response.headers('content-disposition') || `;filename=${defaultFilename}`
        const extractFilename = contentDisposition => contentDisposition.split(';')[1].split('=')[1].replace(/"/g, '')

        return {
          /**
           * Open a file from the response. NB: responseType must already be set to 'blob'
           * @param {*} response 
           * @param {String} filetype 'zip', 'pdf', 'xlsx', 'csv', 'txt'
           * @param {string} filename Optional name for the file without extension.
           */
          open: function (response, filetype, filename = '') {
            const url = $window.URL.createObjectURL(response.data)
            const a = document.createElement('a')
            const defaultFilename = getDefaultFileName(filetype, filename)
            const contentDisposition = getContentDisposition(response, defaultFilename)
            filename = extractFilename(contentDisposition)

            a.href = url
            a.download = filename
            a.click()

            URL.revokeObjectURL(url)
          },

          /**
           * Show a messsage with a file to open from a blob response
           * @param {*} response 
           * @param {string} messageKey 
           * @param {string} filetype filetype 'zip', 'pdf', 'xlsx', 'csv', 'txt'
           * @param {string} filename Optional name for the file without extension.
           * @returns 
           */
          showDownload: function (response, messageKey, filetype, filename = '') {
            const url = $window.URL.createObjectURL(response.data)
            const a = document.createElement('a')
            const defaultFilename = getDefaultFileName(filetype, filename)
            const contentDisposition = getContentDisposition(response, defaultFilename)
            filename = extractFilename(contentDisposition)

            const icon = getIconClass(filetype)
            const link = `<p><a href="${url}" download="${filename}"><h4><i class="fa ${icon} fa-3x"></i><br><br>${filename}</h4></a></p>`
            const html = $translate.instant(messageKey, { link })

            return $window.Swal.fire({
              title: $translate.instant('Success'),
              html,
              icon: 'success',
              showConfirmButton: false,
              showCloseButton: true
            }).then(result => {})
          }
        }
      }
    ]
  )
