angular.module('mosaik.services')
  .factory('cacheService',
    ['$rootScope', 'utilService',
      ($rootScope, utilService) => {
        const cache = {}
        const timeouts = {}
        const expiresInMilliseconds = 300000 // 5 minutes

        $rootScope.$on('clear-session-state', () => self.clearAll())
        $rootScope.$on('data-modified', (event, filters) => utilService.toArray(filters).forEach(self.clearContaining))
        $rootScope.$on('locale-changed', (event) => self.clearAll())

        const self = {
          set: (key, value) => {
            cache[key] = value
            clearTimeout(timeouts[key])
            delete timeouts[key]
            timeouts[key] = setTimeout(self.clear, expiresInMilliseconds, key)
          },

          get: key => cache[key],
          clear: key => delete cache[key],
          clearContaining: (filter) => {
            // find key that contains the filter and delete them (don't search past the query if there is one)
            for (const key in cache) {
              const regex = new RegExp(filter + (key.indexOf('?') !== -1 ? '.*\\?' : ''), 'i');
              if (key.match(regex)) {
                self.clear(key)
              }
            }
          },
          clearAll: () => Object.keys(cache).forEach(key => self.clear(key))
        }

        return self
      }]
  )