angular.module('mosaik.services')
  .factory('sessionStateService',
  ['$http', '$rootScope', '$state', '$translate', 'chatService', 'notificationHelper',
    function ($http, $rootScope, $state, $translate, chatService, notificationHelper) {
      let _currentSession

      const self = {
        validSessionOrRedirect: () => {
          return $http.get('/api/passport/connected')
            .then((response) => {
              _currentSession = response.data
              if (response.data && response.data.connected) {
                return _currentSession
              }
              // expired session
              $rootScope.$broadcast('clear-session-state')
              $rootScope.$broadcast('index-state-reload')
              self.goLoginSessionExpired()
            })
        },

        goLoginSessionExpired: () => $state.go('passport', {warningMessage: $translate.instant('SessionExpired')}, { reload: true }),
        goLogin: () => $state.go('passport', {}, { reload: true }),
        goHome: () => $state.go('landing', {}, { reload: true }),
        goHomeOnValidSession: () => {
          return $http.get('/api/passport/connected')
            .then(function (response) {
              _currentSession = response.data
              if (response.data && response.data.connected) {
                self.goHome()
              }
              return _currentSession       
            })
        },
        getAndGo: (url) => {
          $http.get(url)
          .then((response) => {
            $state.go(response.data.name, response.data.params || {}, { reload: 'secure' })
          }, (error) => {
            if (error.status === 401) {
              if (error.data && error.data.sessionStatus === 'unknown') {
                return self.goLogin() // in case of access via the landing route, don't suppose the session has expired, there could be simply no session at all yet
              }
              return self.goLoginSessionExpired()
            }
            notificationHelper.failureFrom(error)
          })
        },

        currentSessionSync: () => _currentSession || {},

        session: () => {
          return $http.get('/api/passport/connected')
            .then((response) => {
              _currentSession = response.data
              return _currentSession
            })
        },

        /**
         * Get the last session state loaded or load a new state
         * @returns current session state
         */
        lastSession: () => {
          if (!_currentSession) {
            return self.session() // load the state if none yet
          }
          // return a promise which resolve to the current loaded session state
          return new Promise((resolve) => resolve(_currentSession))
        },

        retainCurrentState: (stateName, stateParams) => {
          if (stateName !== 'passport') { // Do not override current state in case of session failure
            $rootScope.requestedState = {
              base: $rootScope.baseHref,
              name: stateName,
              params: $.extend({}, stateParams)
            }
            // remove unwanted default params
            delete $rootScope.requestedState.params['#']
          }
        },

        terminateSession: () => {
          chatService.destroy()
          return $http.put('/api/passport/signout')
            .then((response) => {
              // session close
              $rootScope.$broadcast('clear-session-state')
              $rootScope.$broadcast('index-state-reload')
              _currentSession = null
              return response.data
            })
        },
      }
      return self
    }]
  )