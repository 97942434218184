angular.module('mosaik.services')
  .factory('timeService', ['$window', '$translate',
    function ($window, $translate) {
      const fromStart = $window.moment('2000-01-01')
      const getFormatedMoment = momentDate => $window.moment(momentDate.format(self.defaultFormat))

      const self = {
        defaultFormat: 'YYYY-MM-DD',
        now: () => getFormatedMoment($window.moment()),
        last30Days: () => ({
          start: getFormatedMoment($window.moment().subtract(29, 'days')),
          end: self.now()
        }),
        last12Months: () => ({
          start: $window.moment().subtract(12, 'month').startOf('month'),
          end: self.now()
        }),
        fromStartFormatted: () => fromStart.format(self.defaultFormat),
        formatHoursMinutes: (milliseconds) => {
          const seconds = (milliseconds || 0) / 1000
          const hh = Math.floor(seconds / 3600)
          const mm = Math.floor((seconds % 3600) / 60)
          const hhFormatted = $window.numeral(Math.round(hh)).format('0,0') || ''
          return (hhFormatted ? hhFormatted + 'h' : '') + (hh <= 9999 ? mm + 'm' : '')
        },

        humanizeDuration: (value, measurement = 'milliseconds') => {
          return $window.moment.duration(value, measurement).humanize() // a minute
        },

        fortyEightHours: 172800000,

        // set Session specific ranges
        sessionRanges: () => {
          const ranges = {}
          const now = self.now()
          ranges[$translate.instant('Today')] = [now, now]
          ranges[$translate.instant('Yesterday')] = [$window.moment().subtract(1, 'days'), $window.moment().subtract(1, 'days')]
          ranges[$translate.instant('Last7Days')] = [$window.moment().subtract(6, 'days'), $window.moment()]
          ranges[$translate.instant('Last30Days')] = [getFormatedMoment($window.moment().subtract(29, 'days')), now]
          ranges[$translate.instant('ThisMonth')] = [getFormatedMoment($window.moment().startOf('month')), getFormatedMoment($window.moment().endOf('month'))]
          ranges[$translate.instant('LastMonth')] = [$window.moment().subtract(1, 'month').startOf('month'), $window.moment().subtract(1, 'month').endOf('month')]
          ranges[$translate.instant('ThisYear')] = [$window.moment().startOf('year'), now]
          ranges[$translate.instant('LastYear')] = [$window.moment().subtract(1, 'year').startOf('year'), $window.moment().subtract(1, 'year').endOf('year')]
          ranges[$translate.instant('FromStart')] = [fromStart, null]
          return ranges
        },
        // set time-log specific ranges
        timeLogRanges: () => {
          const ranges = {}
          const now = self.now()
          ranges[$translate.instant('Today')] = [now, now]
          ranges[$translate.instant('Yesterday')] = [$window.moment().subtract(1, 'days'), $window.moment().subtract(1, 'days')]
          ranges[$translate.instant('Last7Days')] = [$window.moment().subtract(6, 'days'), $window.moment()]
          ranges[$translate.instant('Last30Days')] = [getFormatedMoment($window.moment().subtract(29, 'days')), now]
          ranges[$translate.instant('ThisMonth')] = [getFormatedMoment($window.moment().startOf('month')), getFormatedMoment($window.moment().endOf('month'))]
          ranges[$translate.instant('LastMonth')] = [$window.moment().subtract(1, 'month').startOf('month'), $window.moment().subtract(1, 'month').endOf('month')]
          ranges[$translate.instant('ThisYear')] = [$window.moment().startOf('year'), now]
          ranges[$translate.instant('LastYear')] = [$window.moment().subtract(1, 'year').startOf('year'), $window.moment().subtract(1, 'year').endOf('year')]
          return ranges
        },
        // set log specific ranges
        logRanges: () => {
          const ranges = {}
          const now = self.now()
          ranges[$translate.instant('Last30Days')] = [getFormatedMoment($window.moment().subtract(29, 'days')), now]
          ranges[$translate.instant('Last6Month')] = [$window.moment().subtract(6, 'month').startOf('month'), now]
          ranges[$translate.instant('ThisYear')] = [$window.moment().startOf('year'), now]
          ranges[$translate.instant('LastYear')] = [$window.moment().subtract(1, 'year').startOf('year'), $window.moment().subtract(1, 'year').endOf('year')]
          ranges[$translate.instant('Last2Year')] = [$window.moment().subtract(2, 'year').startOf('year'), now]
          ranges[$translate.instant('FromStart')] = [fromStart, null]
          return ranges
        },
        // set active users specific ranges
        userActiveRanges: () => {
          const ranges = {}
          const now = self.now()
          ranges[$translate.instant('Last30Days')] = [getFormatedMoment($window.moment().subtract(29, 'days')), now]
          ranges[$translate.instant('Last6Month')] = [$window.moment().subtract(6, 'month').startOf('month'), now]
          ranges[$translate.instant('Last12Month')] = [$window.moment().subtract(12, 'month').startOf('month'), now]
          ranges[$translate.instant('ThisYear')] = [$window.moment().startOf('year'), now]
          ranges[$translate.instant('FromStart')] = [fromStart, null]
          return ranges
        },
        // default ranges
        defaultRanges: () => self.sessionRanges()

      }
      return self
    }])