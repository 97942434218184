angular.module('mosaik.controllers')
  .controller('invitePDFController',
    ['$scope', 'sessionState', '$state', 'setup', 'organizations', 'tagService', '$window', '$translate', 'sessionStateService', '$timeout', 'translationService','inviteService',
      function ($scope, sessionState, $state, setup, organizations, tagService, $window, $translate, sessionStateService, $timeout, translationService, inviteService) {
        if (!sessionState.canInvite) {
          return $state.go('landing', {}, { reload: 'secure' })
        }

        $scope.isRoot = sessionState.isRoot
        $scope.tags = []
        $scope.organizations = organizations
        $scope.selectedTags = []
        $scope.selectedOrg = $scope.defaultOrgid = sessionState.orgid
        $scope.languages = translationService.getLanguages()
        $scope.selectedLanguage = translationService.getCurrentLanguage().code
        $scope.priorityAutoFilters = [{
          value: '0',
          name: $translate.instant('ShowAll')
        }, {
          value: '1',
          name: $translate.instant('Priorities')
        }]



        setFormDefaultValue()
        loadTags()

        function setFormDefaultValue() {
          $scope.user = ''
          $scope.users = []
          $scope.stats = {
            count: 0
          }
          $scope.selectedTags = []
          $scope.isAdmin = false
          $scope.addMessage = false
          $scope.message = null
          $scope.priorityAutoFilter = '0',
          $scope.expiredInDays = setup.defaultExpiredInDays
        }

        function resetFormData() {
          $timeout(() => {
            setFormDefaultValue()
            validateInputsState()
            angular.element('#tags-selectpicker').selectpicker('deselectAll')
            angular.element('#advancedOptions').collapse('hide')
            inviteService.inputSwitchOff()
            setFocusOnUser()
          })
        }

        angular.element('#advancedOptions').on('hide.bs.collapse', () => {
          $timeout(() => {
            $scope.priorityAutoFilter = '0'
            $scope.expiredInDays = setup.defaultExpiredInDays
          })
        })

        $timeout(() => {
          $scope.show = true
          validateInputsState()
          setFocusOnUser()
        })

        function setFocusOnUser() {
          $timeout(() => angular.element('#user').focus(), 150)
        }

        $scope.addUser = () => {
          const users = $scope.user.split(',')

          for (let i = 0; i < users.length; i++) {
            let aUser = users[i].trim()

            if ($scope.users.indexOf(aUser) === -1 && aUser !== '') {
              $scope.users.push(aUser)
              $scope.stats.count++
            }
          }
          validateInputsState()
          $scope.user = ''
        }

        $scope.removeUser = user => {
          const idx = $scope.users.indexOf(user)
          if (idx !== -1) {
            $scope.users.splice(idx, 1)
            $scope.stats.count--
            validateInputsState()
          }
        }

        $scope.createPDF = () => {
          // tell server to create the invitations file!
          const data = {
            tags: $scope.selectedTags,
            users: $scope.users,
            isAdmin: $scope.isAdmin,
            orgid: $scope.selectedOrg,
            locale: $scope.selectedLanguage,
            message: $scope.addMessage ? $scope.message : null,
            priorityAutoFilter: $scope.priorityAutoFilter,
            expiredInDays: $scope.expiredInDays
          }

          $window.Swal.fire({
            title: $translate.instant('CreatePDFInfo'),
            text: $translate.instant('CreatePDFInfoMessage', { count: $scope.stats.count }),
            icon: 'info',
            showConfirmButton: false,
            showCancelButton: false,
            showLoaderOnConfirm: true,
            allowEscapeKey: false,
            preConfirm: () => {
              return new Promise(resolve => inviteService.create('pdf', data, resolve))
            }
          }).then(success => {
              if (!success.value) return
              inviteService.showDownload(success.value, () => resetFormData())
              // clean cache
              $scope.$emit('data-modified', 'invite')
            }
          )
          // start the request
          $window.swal.clickConfirm()
        }

        function validateInputsState() {
          const disabled = ($scope.stats.count === 0)
          validateSwitchesState(disabled)
          validateSelectState(disabled)
        }

        function validateSwitchesState(disabled) {
          const switches = $('input.checkbox-switch')
          switches.bootstrapSwitch('disabled', disabled)
        }

        function validateSelectState(disabled) {
          const select = $('.selects')
          select.prop('disabled', disabled)
          select.selectpicker('refresh')
        }

        function loadTags() {
          tagService.tags($scope.selectedOrg)
            .then(tags => {
              $scope.tags = tags
              $scope.selectedTags = []
              $timeout(() => $scope.$broadcast('picker-dataset-change', 'selectedTags'))
            })
        }

        $scope.$on('select-modified', (event, data) => {
          if (data.id === 'organizations-selectpicker') loadTags()
        })

        $scope.$on('invite-is-admin', (event, state) => {
          $scope.isAdmin = state
        })

        $scope.$on('invite-add-message', (event, state) => {
          $scope.addMessage = state
          angular.element('#custom-message').collapse(state ? 'show' : 'hide')
          if (state) {
            return angular.element('#message-area').focus()
          }
          setFocusOnUser()
        })
      }
    ]
  )
