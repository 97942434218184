angular.module('mosaik.services')
  .factory('formService',
  ['$translate',
    function ($translate) {
      return {
        errorMessage: function(validityState, element) {
          // validate with angular validity state

          if (validityState.valid) {
            return
          }
          if (validityState.valueMissing) {
            return $translate.instant('valueMissing')
          }
          if (validityState.tooLong) {
            return $translate.instant('tooLong', {max: element.getAttribute('maxlength')})
          }
          if (validityState.tooShort) {
            return $translate.instant('tooShort', {min: element.getAttribute('minlength')})
          }
          if (validityState.rangeOverflow) {
            return $translate.instant('rangeOverflow')
          }
          if (validityState.rangeUnderflow) {
            return $translate.instant('rangeUnderflow')
          }
          if (validityState.patternMismatch) {
            return $translate.instant('patternMismatch')
          }
          if (validityState.stepMismatch) {
            return $translate.instant('stepMismatch')
          }
          if (validityState.typeMismatch) {
            if (element.type === 'email') {
              return $translate.instant('typeMismatchEmail')
            }
            if (element.type === 'tel') {
              return $translate.instant('typeMismatchPhone')
            }
            return $translate.instant('typeMismatch')
          } else {
            // generic error message
            return $translate.instant('CannotValidateValue')
          }
        },

        validateFromAttributes: function (value, attributes, inputType) {
          const required = attributes.required != null
          const max = attributes.maxlength
          const min = attributes.minlength
          const pattern = attributes.pattern

          if (!value && required) {
            return $translate.instant('valueMissing')
          }
          if (value.length > max) {
            return $translate.instant('tooLong', {max: max})
          }
          if (value.length < min) {
            return $translate.instant('tooShort', {min: min})
          }
          if (inputType === 'email') {
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (!regex.test(value)) {
              return $translate.instant('EmailValidationHint')
            }
          }
          //check pattern
          if (value && pattern) {
            const regex = new RegExp(`${pattern.includes('^') ? '' : '̀^'}${pattern}${pattern.includes('$') ? '' : '̀$'}`)
            if (!regex.test(value)) {
              return $translate.instant('patternMismatch')
            }
          }

          return
        }
      }
    }
  ])
