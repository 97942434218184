angular.module('mosaik.directives')
  .directive('repeatContent',
    ['$window', '$timeout',
      function ($window, $timeout) {
        return {
          restrict: 'A',
          scope: {
            content: '=',
            last: '='
          },
          link: (scope, element, attrs) => {
            const content = scope.content
            const completedAt = content.completedAt ? $window.moment(content.completedAt) : null

            // format some values in content element
            content.scoreInPercent = (content.scoreScaled !== null ? Math.round(content.scoreScaled * 100) : undefined)
            content.completedAtReport = completedAt !== null ? completedAt.format('llll') : null
            content.completedAtHuman = completedAt !== null ? completedAt.fromNow() : null
            if (content.updatedAt != null) {
              content.updatedAtHuman = $window.moment(content.updatedAt).fromNow()
            } else {
              content.updatedAtHuman = null
            }
            // wait for the last one
            if (!scope.last) return

            $timeout(() => scope.$emit('repeat-done'))
          }
        }
      }])