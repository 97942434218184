.directive('clickShowContentDetail',
  ['$window', '$http', '$sce', '$templateRequest', '$compile', 'sessionStateService', 'contentSessionService', '$location', '$timeout', 'certificateService',
    function ($window, $http, $sce, $templateRequest, $compile, sessionStateService, contentSessionService, $location, $timeout, certificateService) {
      return {
        restrict: 'A',
        scope: {
          content: '='
        },
        link: (scope, element, attrs) => {
          element.on('click', () => {
            if (scope.vexInstance && scope.vexInstance.isOpen) {
              return // don't show twice
            }

            $templateRequest('/public/views/library/contentDetail.html').then(template => {
              // check the session is still valid
              sessionStateService.validSessionOrRedirect()
                .then(sessionState => {
                  if (!sessionState || !sessionState.connected) {
                    return
                  }
                  let contentData
                  scope.isAdmin = sessionState.isAdmin

                  $http.get(contentSessionService.formatContentPath(scope.content) + 'config.json/' + scope.content.id)
                    .then(response => {
                      const contentLocale = scope.content.locale
                      const defaultLocale = response.data.defaultLocale || response.data.locale || contentLocale
                      const supportedLocales = response.data.supportedLocales || [defaultLocale]

                      contentData = {
                        description: getTrustedStringFromProperty(response.data, 'description', contentLocale, defaultLocale, supportedLocales),
                        configSubtitle: getTrustedStringFromProperty(response.data, 'subtitle', contentLocale, defaultLocale, supportedLocales),
                        evaluationCriteria: getTrustedStringFromProperty(response.data, 'evaluationCriteria', contentLocale, defaultLocale, supportedLocales),
                        showDuration: response.data.durationInMinutes != null,
                        durationInMinutes: response.data.durationInMinutes,
                        references: response.data.references,
                        entryPoint: response.data.entryPoint,
                        target: response.data.target,
                        locale: contentLocale,
                        defaultLocale,
                        startable: response.data.startable,
                        external: response.data.external
                      }
                    }, error => {
                      contentData = {
                        description: 'The description is <b>empty</b>.',
                        configSubtitle: 'No subtitle',
                        evaluationCriteria: 'No evaluation criteria',
                        durationInMinutes: '15',
                        references: [],
                        entryPoint: undefined,
                        target: undefined,
                        locale: 'en',
                        defaultLocale: 'en',
                        startable: false,
                        external: false
                      }
                    })
                    .finally(() => {
                      angular.extend(scope.content, contentData)
                      showContentVexContainer($compile(template)(scope), scope.content)
                    })
                })
            })
          })

          function getTrustedStringFromProperty(data, propertyName, contentLocale, defaultLocale, acceptedLocales) {
            let value
            if (!data[propertyName]) {
              return ''
            }
            if (acceptedLocales.includes(contentLocale) && data[propertyName][contentLocale] != null) {
              value = data[propertyName][contentLocale]
            } else if (data[propertyName][defaultLocale] != null) {
              value = data[propertyName][defaultLocale]
            } else {
              value = data[propertyName] || ''
            }

            if (typeof value !== 'string') {
              return ''
            }
            return $sce.trustAsHtml(value)
          }

          function showContentVexContainer(compiledContent, content) {
            $window.vex.open({
              contentClassName: 'vex-mosaik-content',
              afterOpen: function () {
                scope.vexInstance = this
                angular.element(this.contentEl).append(compiledContent)
              },
              beforeClose: () => scope.$emit('content-detail-closing', content)
            })
          }

          function closeModal() {
            if (scope.vexInstance) $window.vex.closeAll()
          }

          scope.$on('close-content-popup', closeModal)

          scope.getCertificate = sessionid => {
            closeModal()
            certificateService.get('me', sessionid)
          }
        }
      }
    }])