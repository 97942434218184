angular.module('mosaik.controllers')
  .controller('dashboardController',
    ['$window', '$scope', 'sessionState', 'setup', '$translate', '$timeout', 'timeService', 'dashboardService', 'asyncService',
      function ($window, $scope, sessionState, setup, $translate, $timeout, timeService, dashboardService, asyncService) {
        $timeout(() => {
          $scope.setup = setup
          $scope.show = false
          $scope.canAccessDashboard = sessionState.canAccessDashboard
          $scope.isRoot = sessionState.isRoot
        })

        const orgidSelector = sessionState.orgidSelector
        $scope.orgid = sessionState.orgid
        $scope.canAccessMemo = sessionState.canAccessMemo
        $scope.report = {
          organizations: {},
          invites: {},
          users: {},
          tags: {
            count: 0
          },
          contents: {
            value: ''

          },
          sessions: {},
          memo: {}
        }
        $scope.currencySymbol = $window.numeral.localeData().currency.symbol
        // urls map
        const urls = {
          'org': {
            url: '/admin-api/dashboard/activeOrganizationCount?orgid=' + orgidSelector,
            fn: data => {
              $scope.report.organizations.subscribed = data.subscribed
              $scope.report.organizations.unsubscribed = data.unsubscribed
              $scope.report.organizations.subscribedFormatted = $window.numeral(data.subscribed).format('0,0')
              $scope.report.organizations.unsubscribedFormatted = $window.numeral(data.unsubscribed).format('0,0')
            }
          },
          'invite': {
            url: '/admin-api/dashboard/inviteCount?orgid=' + orgidSelector,
            fn: data => {
              $scope.report.invites.active = data.active
              $scope.report.invites.expired = data.expired
              $scope.report.invites.activeFormatted = $window.numeral(data.active).format('0,0')
              $scope.report.invites.expiredFormatted = $window.numeral(data.expired).format('0,0')
            }
          },
          'user': {
            url: '/admin-api/dashboard/activeUserCount?orgid=' + orgidSelector,
            fn: data => {
              $scope.report.users.active = data.active
              $scope.report.users.activeLast30Days = data.activeLast30Days
              $scope.report.users.activeFormatted = $window.numeral(data.active).format('0,0')
              $scope.report.users.activeLast30DaysFormatted = $window.numeral(data.activeLast30Days).format('0,0')
            }
          },
          'content': {
            url: '/admin-api/dashboard/activeContentCount?orgid=' + orgidSelector,
            fn: data => {
              $scope.report.contents.active = data.active
              $scope.report.contents.priority = data.priority
              $scope.report.contents.activeFormatted = $window.numeral(data.active).format('0,0')
              $scope.report.contents.priorityFormatted = $window.numeral(data.priority).format('0,0')
              $scope.report.contents.recurrentFormatted = $window.numeral(data.recurrent).format('0,0')
            }
          },
          'tag': {
            url: '/admin-api/dashboard/tagCount?orgid=' + orgidSelector,
            fn: data => {
              $scope.report.tags.count = data.count
              $scope.report.tags.private = data.private
              $scope.report.tags.countFormatted = $window.numeral(data.count).format('0,0')
              $scope.report.tags.privateFormatted = $window.numeral(data.private).format('0,0')
            }
          },
          'completed': {
            url: '/admin-api/dashboard/completedSessionCount?orgid=' + orgidSelector,
            fn: data => {
              $scope.report.sessions.completed = data.completed
              $scope.report.sessions.completedLast30Days = data.completedLast30Days
              $scope.report.sessions.completedFormatted = $window.numeral(data.completed).format('0,0')
              $scope.report.sessions.completedLast30DaysFormatted = $window.numeral(data.completedLast30Days).format('0,0')
            }
          },
          'inprogress': {
            url: '/admin-api/dashboard/inprogressSessionCount?orgid=' + orgidSelector,
            fn: data => {
              $scope.report.sessions.inprogress = data.inprogress
              $scope.report.sessions.inprogressLast30Days = data.inprogressLast30Days
              $scope.report.sessions.inprogressFormatted = $window.numeral(data.inprogress).format('0,0')
              $scope.report.sessions.inprogressLast30DaysFormatted = $window.numeral(data.inprogressLast30Days).format('0,0')
            }
          }
        }
        // optional values
        if (setup.usageValue.visible) {
          urls.value = {
            url: '/admin-api/dashboard/contentValue?orgid=' + orgidSelector,
            fn: data => {
              $scope.report.contents.value = $window.numeral(data.contentValue).format('0,0')
              $scope.report.contents.valueLast30Days = $window.numeral(data.contentValueLast30Days).format('0,0')
            }
          }
        }
        if (sessionState.canAccessMemo) {
          urls.memo = {
            url: '/admin-api/dashboard/memoConsultation?orgid=' + orgidSelector,
            fn: data => {
              $scope.report.memo = data
              $scope.report.memo.consultationFormatted = (data.consultationScaled ? Math.round(data.consultationScaled * 100) : '')
            }
          }
        }
        if (setup.coverage.visible) {
          urls.coverage = {
            url: '/admin-api/dashboard/contentCoverage?orgid=' + orgidSelector + '&startDate=' + timeService.last12Months().start.format('YYYY-MM-DD'),
            fn: data => {
              $scope.report.contents.coverageTotal = data.total
              $scope.report.contents.coverageCompleted = data.completed
              $scope.report.contents.coverageScaled = (data.total ? Math.round((data.completed / data.total) * 100) : undefined)
            }
          }
        }

        // slower urls do not wait for
        const urlsNoWait = {
          'totalTime': {
            url: '/admin-api/dashboard/contentTime?orgid=' + orgidSelector,
            fn: data => {
              const sumTimeInMillisecond = data.totalTime.sumTimeInMillisecond
              $scope.report.contents.timeHumanized = sumTimeInMillisecond >= timeService.fortyEightHours ? timeService.humanizeDuration(sumTimeInMillisecond) : ''
              $scope.report.contents.hoursFormatted = timeService.formatHoursMinutes(sumTimeInMillisecond)
              $scope.report.contents.hoursLast30DaysFormatted = timeService.formatHoursMinutes(data.totalTimeLast30Days.sumTimeInMillisecond)
            }
          },
          'averageScore': {
            url: '/admin-api/dashboard/contentScore?orgid=' + orgidSelector,
            fn: data => {
              $scope.report.contents.averageScore = data.cumulativeAverageScoreScaled ? Math.round(data.cumulativeAverageScoreScaled * 100) : ''
            }
          }
        }

        // async limit execution
        const urlsArray = Object.keys(urls).map(key => {
          return { key, param: urls[key].url }
        })
        const urlsNoWaitArray = Object.keys(urlsNoWait).map(key => {
          return { key, param: urlsNoWait[key].url }
        })

        // run wait urls
        asyncService.promiseAllLimit(4, urlsArray, dashboardService.get)
          .then(results => {
            $timeout(() => {
              results.forEach(result => {
                urls[result.key].fn(result.data)
              })
              $scope.$emit('stop-loader')
              $scope.show = true
            })
          })

        // run no wait urls
        asyncService.promiseAllLimit(2, urlsNoWaitArray, dashboardService.get)
          .then(results => {
            $timeout(() => {
              results.forEach(result => {
                urlsNoWait[result.key].fn(result.data)
              })
            })
          })
      }
    ])
