angular.module('mosaik.services')
  .factory('tutorialService',
    ['$window', '$http', '$translate', '$timeout',
      function ($window, $http, $translate, $timeout) {
        return {
          showIfFirstConnect: function (sessionState, next) {
            if (sessionState.firstConnect && !sessionState.skipTutorial) {
              var message = '<div class="text-left">'
              message += sessionState.canAccessMemo ? $translate.instant('HelpTutoMemo') : ''
              message += $translate.instant('HelpTutoLibrary')
              message += sessionState.canAccessDashboard ? $translate.instant('HelpTutoDashboard') : ''
              message += sessionState.canInvite ? $translate.instant('HelpTutoInvite') : ''
              message += $translate.instant('HelpTutoPersonal')
              message += $translate.instant('HelpTutoHelp')
              message += '<div>'

              $window.Swal.fire({
                title: $translate.instant('HelpTutoTitle'),
                html: message,
                icon: 'question',
                padding: 25,
                customClass: 'tutorial',
                showClass: {
                  popup: 'animate__animated animate__zoomIn animate__faster'
                },
                hideClass: {
                  popup: 'animate__animated animate__zoomOut animate__faster'
                },
                confirmButtonText: $translate.instant('HelpTutoClose')
              }).then(
                function confirm() {
                  _tutorialRead()
                  next()
                })

            } else {
              next()
            }

            function _tutorialRead() {
              $http.put('/api/passport/skipTutorial')
            }
          }
        }
      }]
  )