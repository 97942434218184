angular.module('mosaik.controllers')
  .controller('kbController',
    ['$scope', '$window', '$compile', '$state', '$stateParams', 'sessionState', 'topics', 'articles', '$translate', '$timeout', 'utilService', 'sessionStateService', 'kbArticleService', 'redactorService',
      function ($scope, $window, $compile, $state, $stateParams, sessionState, topics, articles, $translate, $timeout, utilService, sessionStateService, kbArticleService, redactorService) {
        const gridOptions = {
          masonry: {
            columnWidth: null,
            fitWidth: true
          },
          initLayout: false
        }
        const kbarticleid = $stateParams.kbarticleid
        let articleViewMinimumTop = 0
        let articleViewElement
        $scope.search = ''

        buildGrid()
        validateAccessOnArticleid()

        function buildGrid() {
          $scope.showEmptyTitle = (articles.length === 0)
          $scope.gridItems = []

          // fill the grid items
          // topics array is already ordered correctly
          // show a topic separator before articles that belong to that topic
          // distribute all articles in their parent topic object

          // reset topic articles array
          topics.forEach(topic => topic.articles = [])

          // do not show topics if there was a search. Articles are already sorted by relevance (score)
          // ----
          if ($scope.search) {
            // all all belonging articles
            return $scope.gridItems = articles
          }

          // No search query, sort by topic and show topic separators
          // ----
          // convert topics array to a map
          const topicsAsObjects = utilService.mapObjectsByKey(topics)

          articles.forEach(article => topicsAsObjects[article.kbtopicid].articles.push(article))

          // scan topics[].articles[] and insert into the grid items array
          topics.forEach(topic => {
            if (topic.articles.length) {
              // add topic separator first
              $scope.gridItems.push({
                isSeparator: true,
                title: topic.title
              })
              // all all belonging articles
              topic.articles.forEach(article => $scope.gridItems.push(article))
            }
          })
        }

        // validate access to article first if not logged in.
        function validateAccessOnArticleid() {
          if (kbarticleid && !sessionState.connected) {
            kbArticleService.status(kbarticleid).then(status => {
              // redirect if requested article is restricted to connected users
              if (status.connectionRequired) {
                sessionStateService.retainCurrentState('kb', $stateParams)
                $state.go('passport', {warningMessage: $translate.instant('KbLoginToConsult')}, { reload: true })
              }
            })
          }
        }

        // Initialize the grid
        // wait for the repeat to be completed
        $scope.$on('repeat-done', () => {
          // initialize
          isotopeGrid = angular.element('.grid').isotope(gridOptions)
          // bind events
          isotopeGrid.one('arrangeComplete', () => $scope.$broadcast('animate-grid-once'))
          $scope.showFadeIn = true
          // show the grid
          $timeout(() => {
            // Make sure to load all items and relayout
            isotopeGrid.isotope('reloadItems')
            isotopeGrid.isotope()

            // check for article id as params
            if (kbarticleid) {
              $timeout(() => angular.element('#item-' + kbarticleid).click(), 350)
            }
          })
        })

        $scope.showArticle = article => {
          $window.Swal.fire({
            title: article.title,
            icon: 'question',
            iconHtml: '<i class="fa ' + article.iconClass + '" aria-hidden="true"></i>',
            showConfirmButton: false,
            showCloseButton: true,
            showClass: {
              popup: 'animate__animated animate__fadeIn animate__faster',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOut animate__faster'
            },
            customClass: {
              container: 'kb-view-swal2-container',
              popup: 'kb-view-swal2-popup-full-width',
              content: 'kb-view-swal2-content-full-width',
              confirmButton: 'swal2-button-fix-width',
              cancelButton: 'swal2-button-fix-width',
              denyButton: 'swal2-button-fix-width'
            },
            willOpen: element => {
              const template = '<div id="kb-view-article" class="kb-view-article">' + article.body + '</div>'
              const compiled = ($compile(template)($scope))
              const articleElement = compiled[0]

              redactorService.activateVideoAutoplay(articleElement)
              element.append(articleElement)
            },
            didOpen: element => {
              articleViewElement = angular.element('#kb-view-article')
              // bind event on resize window
              $window.addEventListener("resize", onWindowResize)
              // call event on first load
              onWindowResize()

              $state.go('kb', {kbarticleid: article.id}, { notify: false })
            },
            willClose: element => {
              $window.removeEventListener("resize", onWindowResize)
              articleViewElement = undefined
            },
            didClose: () => {
              // reformat url
              $timeout(() => $state.go('kb', {kbarticleid: null}, { notify: false }))
            }
          })
        }

        function onWindowResize() {
          setPopupVideoSize()
          setPopupPosition()
        }

        function setPopupVideoSize() {
          redactorService.setVideoSize(articleViewElement)
        }

        function setPopupPosition() {
          if (!articleViewElement) {
            return
          }
          // adjust popup position
          const viewOffset = articleViewElement.parent().offset()
          const currentViewOffsetTop = viewOffset.top
          const newViewTop = $window.scrollY + ($window.innerHeight / 2) - (articleViewElement.parent().outerHeight() / 2)

          if (!articleViewMinimumTop) {
            articleViewMinimumTop = viewOffset.top
          }
          if (newViewTop < currentViewOffsetTop) {
            viewOffset.top = currentViewOffsetTop
          } else {
            viewOffset.top = newViewTop < articleViewMinimumTop ? articleViewMinimumTop : newViewTop
          }
          articleViewElement.parent().offset(viewOffset)
        }

        function reloadArticles(search) {
          $scope.search = search
          kbArticleService.articles(search).then(results => {
            articles = results
            $timeout(() => buildGrid())
          })
        }

        $scope.$on('search-modified', (event, data) => reloadArticles(data))
        $scope.$on('locale-changed', (event, args) => $state.go('kb', {}, { reload: true }))
        $scope.$on('filter-reset', (event, args) => {
          reloadArticles('')
          $scope.$broadcast('filter-param-clear', { key: 'search', value: '' })
        })
      }
    ]
  )
