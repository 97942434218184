angular.module('mosaik.controllers')
  .controller('sessionInProgressController',
    ['$scope', '$window', '$state', 'sessionState', 'groups', 'roles', 'tags', 'organizations', 'contents', 'categories', '$translate', '$timeout', '$interpolate', 'datatablesHelper', 'utilService', 'timeService', 'contentService',
      function ($scope, $window, $state, sessionState, groups, roles, tags, organizations, contents, categories, $translate, $timeout, $interpolate, datatablesHelper, utilService, timeService, contentService) {
        // access rights
        $scope.canAccessDashboard = sessionState.canAccessDashboard
        $scope.isRoot = sessionState.isRoot
        // parameters
        const tableid = '#datatables-session-inprogress'
        const tableElement = angular.element(tableid)
        const api = {
          url: '/admin-api/session',
          readType: 'POST'
        }
        $scope.queryHidden = {
          orgid: sessionState.orgidSelector
        }
        $scope.queryDefault = {
          dateRange: timeService.last30Days(),
          state: 'inprogress',
          tagFilterMethod: 'or'
        }
        $scope.query = {
          dateRange: {
            start: null,
            end: null
          },
          tagFilterMethod: '',
          tags: [],
          organizations: [],
          categories: []
        }
        // data
        $scope.ranges = timeService.sessionRanges()
        $scope.tags = tags
        $scope.groups = groups
        const groupsAsObjects = utilService.mapObjectsByKey(groups)
        $scope.roles = roles
        $scope.organizations = organizations
        const organizationsAsObjects = utilService.mapObjectsByKey(organizations)
        $scope.contents = contents
        const contentsAsObjects = utilService.mapObjectsByKey(contents)
        $scope.categories = categories
        const categoriesAsObjects = utilService.mapObjectsByKey(categories)
        const tagsAsObjects = utilService.mapObjectsByKey(tags)
        // DT table
        $scope.table

        $timeout(() => datatablesHelper.init($scope, tableid, api, '', initializeTable))

        // Editor
        const editorDelete = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams(), {
          fields: [{
            type: 'hidden',
            name: 'id'
          }],
          ajax: {
            remove: {
              type: 'DELETE',
              url: '/admin-api/session/_id_',
              deleteBody: false
            }
          },
          table: tableElement
        }))

        function initializeTable() {
          const extraButtons = [{
            extend: 'edit',
            text: $translate.instant('ShowInteractions'),
            action: (e, dt) => showInteractions(dt.rows({ selected: true }))
          }, {
            extend: 'edit',
            text: $translate.instant('ShowQuiz'),
            action: (e, dt) => showQuiz(dt.rows({ selected: true }))
          }, {
            extend: 'edit',
            text: $translate.instant('Delete'),
            action: (e, dt, node, config) => deleteSession(dt.rows({ selected: true }))
          }]
          const exportOptions = {
            columns: sessionState.isRoot ? [0,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20] : [0,2,3,4,9,10,11,12,13,14,15,16,17,18,19,20]
          }
          const exportMessage = $translate.instant('InprogressContent')
          const datatableParams = angular.extend(datatablesHelper.getDatatablesParams({
            exportOptions,
            exportMessage,
            filteringInput: false,
            extraButtons,
            extraDTOptions: {
              pageLength: 10, // force default page length to reset to smallest possible
              serverSide: true
            },
            lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
            excelForceStrCols: sessionState.isRoot ? ['F', 'G', 'L'] : ['H']
          }), {
            columns: [
              { // 0
                data: 'updatedAt',
                orderData: 1,
                render: datatablesHelper.renderDateFromNow
              },
              { // 1
                data: 'updatedAt',
                visible: false,
                className: 'never',
                searchable: false,
                render: datatablesHelper.renderDateTimeReport
              },
              { // 2
                data: 'startedAt',
                render: datatablesHelper.renderDateTimeHuman
              },
              { // 3 completedAt: empty completed at, to match "sessionCompleted" report format
                data: () => '',
                visible: false,
                className: 'never',
                searchable: false
              },
              { // 4 completed state, always no, to match "sessionCompleted" report format
                data: () => $translate.instant('No'),
                visible: false,
                className: 'never',
                searchable: false
              },
              { // 5 REPORT-VISIBLE (root)
                data: 'sessionOrgName',
                visible: sessionState.isRoot,
                className: (!sessionState.isRoot ? 'never' : ''),
                render: (data, type, full) => {
                  if (!sessionState.isRoot) return ''
                  return type === 'export' ? data : datatablesHelper.sanitizeEscape(data) + ' <small class="text-id">' + full.sessionInternalOrgid + '<small>'
                }
              },
              { // 6 Export visible (root)
                data: 'sessionInternalOrgid',
                render: datatablesHelper.sanitizeEscape,
                visible: false,
                className: 'never'
              },
              { // 7 Export visible (root)
                data: 'sessionBusinessNumber',
                render: datatablesHelper.sanitizeEscape,
                visible: false,
                className: 'never'
              },
              { // 8 REPORT-VISIBLE (root) Org groups, from organization id (as tokenized id, ex: '1,2,3,5')
                data: 'sessionOrgid',
                visible: sessionState.isRoot,
                className: (!sessionState.isRoot ? 'never' : ''),
                searchable: false,
                orderable: false,
                render: (data, type, full, meta) => {
                  const org = organizationsAsObjects[data] // find the org object using the sessionOrgid
                  if (!sessionState.isRoot || !org) {
                    return ''
                  }
                  return datatablesHelper.renderGroups(utilService.toArrayById(org.groupids), type, full, meta, groupsAsObjects)
                }
              },
              {
                data: 'tagids',
                searchable: false,
                orderable: false,
                render: (data, type, full, meta) => datatablesHelper.renderTags(utilService.toArrayById(data), type, full, meta, tagsAsObjects)
              },
              {
                data: 'id', // sessionid
                visible: false
              },
              {
                data: 'userid',
                visible: false
              },
              {
                data: 'internalUserid',
                visible: false
              },
              {
                data: 'displayName',
                render: (data, type, full, meta) => {
                  result = datatablesHelper.sanitizeEscape(data)
                  if (full.orgid !== full.sessionOrgid) {
                    result +=  ' <br><i><small class="text-id">' + $translate.instant('UnderAnotherOrg', { orgid: full.sessionOrgid }) + '<small></i>'
                  }
                  return result
                }
              },
              { // 14
                data: 'email',
                render: datatablesHelper.sanitizeEscape,
                searchable: false,
                orderable: false,
                visible: false
              },
              { // 15 categories from contentid
                data: 'contentid',
                visible: false,
                searchable: false,
                orderable: false,
                render: (data, type, full, meta) => {
                  const content = contentsAsObjects[data]
                  const categoryids = content ? content.categoryids : ''
                  return datatablesHelper.renderCategories(categoryids, type, full, meta, categoriesAsObjects)
                }
              },
              {
                data: 'contentid',
                searchable: false,
                orderable: false,
                visible: false
              },
              {
                data: 'locale',
                searchable: false,
                orderable: false,
                visible: false
              },
              { // 18
                // contentTitle
                data: row => {
                  const content = contentsAsObjects[row.contentid]
                  if (!content) {
                    // unknown content (the admin has no access to this content, can be private content from another org)
                    return '<i class="text-id">' + $translate.instant('ContentTitleNotKnown', { contentid: row.contentid }) + '</i>'
                  }
                  return `${content.titleText}${content.deprecated ? `&nbsp; <div class="label-mosaik label-mosaik-warning">${$translate.instant('Deprecated')}</div>` : ''}`
                },
                searchable: false,
                orderable: false
              },
              { // 19 scoreScaled always empty, to match "sessionCompleted" report format
                data: () => '',
                searchable: false,
                orderable: false,
                visible: false,
                className: 'never'
                
              },
              { // 20
                data: 'timeInMillisecond',
                render: (data, type, full, meta) => (data / 1000 / 60).toFixed(1)
              },
              { // 21 givesCertificate
                data: (row, type, full, meta) => {
                  const content = contentsAsObjects[row.contentid]
                  return content && content.givesCertificate ? datatablesHelper.renderYesOnly(true, type, full, meta, '', '', 'certificate') : ''
                },
                searchable: false,
                orderable: false,
                visible: false,
                className: 'never'
              },
              { // render actions menu
                searchable: false,
                orderable: false,
                data: (row, type) => {
                  const content = contentsAsObjects[row.contentid] || {}
                  const canDelete = row.timeInMillisecond <= 300000 // can delete in progress content with less than 5 minutes
                  const showMenu = content.id !== undefined
                  if (!showMenu) {
                    return ''
                  }
                  const template = `
                    <div class="dt-buttons btn-group dt-button-actions dropdown">
                      <button type="button" class="btn btn-defaut btn-icon btn-left dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-h"></i> 
                      </button> 
                      <ul class="dropdown-menu dropdown-menu-right">
                        <li class="dt-button"><a id="show-interactions" data-id="${row.id}">{{"ShowInteractions" | translate}}</a></li>
                        <li class="dt-button"><a id="show-quiz" data-id="${row.id}">{{"ShowQuiz" | translate}}</a></li>
                        <li role="separator" class="divider"></li>
                        <li class="dt-button"><a id="delete-session" class="${canDelete ? '' : 'disabled'}" data-id="${row.id}">${$translate.instant('Delete')}</a></li>
                      </ul>
                    </div>`
                  return ($interpolate(template)($scope))
                }
              }
            ],
            order: [0, 'desc']
          })
          datatablesHelper.createAndSetTable(datatableParams)

          // On table select
          $scope.table.on('select.dt.DT deselect.dt.DT', () => {
            const data = $scope.table.rows({ selected: true }).data()
            const selected = data.any()
            const canEdit = !data.toArray().some(one => {
              let content = contentsAsObjects[one.contentid]
              return !content
            })
            const canDelete = data.toArray().every(one => {
              return one.timeInMillisecond <= 300000
            })
            $scope.table.buttons([0,1]).enable(selected && canEdit) // first 2 buttons
            $scope.table.buttons([2]).enable(selected && canEdit && canDelete) // delete button
          })
        }

        function showInteractions(selectedRows) {
          const data = selectedRows.data()[0]
          const content = contentsAsObjects[data.contentid] || {}
          $state.go('dashboardInteraction', { 
            sessionid: data.id,
            title: content.titleText,
            displayName: data.displayName,
            date: data.updatedAt,
            previousState: 'dashboardSessionInProgress' 
          }, { reload: 'secure' })
        }

        function showQuiz(selectedRows) {
          const data = selectedRows.data()[0]

          contentService.setQuizViewer(data.contentid, data.locale)
            .then(response => {
              if (!response.success) {
                return $window.Swal.fire({
                  title: $translate.instant('NoQuiz'),
                  icon: 'warning'
                })
              }
              // open the viewer
              contentService.showQuizViewer()
            })
        }

        const deleteSession = selected => {
          const selectedData = selected.data()

          editorDelete.remove(selected.indexes(), true, {
            title: $translate.instant('DeleteSessionTitle', { count: selectedData.length }),
            message: $translate.instant('DeleteSessionMessage'),
            buttons: $translate.instant('DeleteBtn')
          })
        }

        function doAction(scope, fnAction) {
          datatablesHelper.selectClosestRow($scope.table, datatablesHelper.getParentDiv(scope))
          fnAction($scope.table.rows({ selected: true }))
        }

        tableElement.on('click', '#show-interactions:not(.disabled)', (event) => doAction(event.target, showInteractions))
        tableElement.on('click', '#show-quiz:not(.disabled)', (event) => doAction(event.target, showQuiz))
        tableElement.on('click', '#delete-session:not(.disabled)', event => doAction(event.target, deleteSession))
      }
    ])
