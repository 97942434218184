.directive('clickStartContent',
  ['$window', '$rootScope', '$http', '$location', '$translate', 'sessionStateService', 'notificationHelper',
    function ($window, $rootScope, $http, $location, $translate, sessionStateService, notificationHelper) {
      return {
        restrict: 'A',
        scope: {
          content: "=",
          consult: "@"
        },
        link: (scope, element, attrs) => {
          element.on('click', () => {
            // check for valid session first
            sessionStateService.validSessionOrRedirect()
              .then(sessionState => {
                if (!sessionState || !sessionState.connected) {
                  return
                }
                const restart = scope.content.completionStatus === 'completed' && !scope.content.toRedo
                const consult = scope.consult === 'true'
                const data = {
                  contentid: scope.content.id,
                  sessionid: scope.content.sessionid ? scope.content.sessionid : '',
                  consult,
                  redirectState: $location.$$path
                }


                // if consulting, ask before launching if it's ok to consult.
                if (consult) {
                  $window.Swal.fire({
                    icon: 'warning',
                    title: $translate.instant('ConsultWarn'),
                    html: $translate.instant('ConsultWarnText'),
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonText: $translate.instant('ConsultWarnConfirm'),
                    cancelButtonText: $translate.instant('ConsultWarnCancel'),
                    customClass: {
                      popup: 'swal2-popup-large'
                    },
                  }).then(result => beforeLaunch(result, 'isConfirmed'))
                } else {
                  if (restart) {
                    $window.Swal.fire({
                      icon: 'info',
                      title: $translate.instant('RestartInfo'),
                      html: $translate.instant('RestartInfoText'),
                      showCancelButton: true,
                      showCloseButton: true,
                      confirmButtonText: $translate.instant('RestartInfoConfirm'),
                      cancelButtonText: $translate.instant('RestartInfoCancel'),
                      customClass: {
                        popup: 'swal2-popup-large'
                      },
                    }).then(result => beforeLaunch(result, 'isDismissed'))
                  } else {
                    launch()
                  }
                }

                function beforeLaunch(promptResult, consultProperty) {
                  if (promptResult.isDismissed && promptResult.dismiss !== 'cancel') return // prompt cancelled
                  data.consult = promptResult[consultProperty]
                  launch()
                }

                function launch() {
                  $http.put('/player/setContent', data)
                    .then(success => {
                      // The content has been setup, open the player
                      // an external content plays outside the player with no tracking
                      const url = scope.content.external ? scope.content.entryPoint : '/player'

                      // The content may require to open in a new window/tab
                      if (scope.content.target === '_blank') {
                        $rootScope.$broadcast('close-content-popup')
                        return $window.open(url)
                      }
                      $window.location = url
                    })
                    .catch(error => notificationHelper.failureFrom(error))
                }
              })
          })
        }
      }
    }])