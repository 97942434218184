.directive('loaderSquare',
  ['$compile',
    ($compile) => {
      return {
        restrict: 'E',
        template: `
        <div class="loader-container">
          <div class="loader-square-container">
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square-center"></div>
          </div>
        </div>`,
        link: (scope, element) => {
        }
      }
    }])