angular.module('mosaik.services')
  .factory('iconService',
    ['utilService',
      function (utilService) {
        var iconsCache = []
        var iconsAsOptionsCache = []
        var self = {
          /**
           * Scan CSS for font-awesome icons
           * @returns [] font awesome array of object with classes[] and words(comma separated string) properties
           */
          iconClasses: function () {
            if (iconsCache.length) {
              return iconsCache
            }
            var styleSheets = utilService.iterableObjectToArray(document.styleSheets)
            iconsCache = styleSheets.map(function (styleSheet) {
                try {
                  var cssRules = utilService.iterableObjectToArray(styleSheet.cssRules)
                  return cssRules.map(function (rule) {
                      if (!rule.selectorText) {
                        return null
                      }
                      var match = rule.selectorText.match(/(fa-[^,\s]*)::before/g)
                      if (!match) {
                        return null
                      }
                      var classes = match.map(function (fa) {
                        return fa.replace('::before', '')
                      })
                      return {
                        classes: classes,
                        words: classes.map(function (fa) {
                          return utilService.capitalizeFirstLetter(fa.replace(/fa-|-o$/g, '')).replace(/-/g, ' ')
                        }).join(', ')
                      }
                    }).filter(Boolean)
                } catch (e) { }
              }).filter(function (sheet) {
                return sheet.length
              }).flat()

            return iconsCache
          },

          getAsPickerOptions: function () {
            if (iconsAsOptionsCache.length) {
              return iconsAsOptionsCache
            }
            iconsAsOptionsCache = self.iconClasses().map(function (iconInfo) {
              var iconClass = iconInfo.classes[0]
              return {
                label: iconInfo.words,
                value: iconInfo.classes[0]
              }
            })

            return iconsAsOptionsCache
          },

          renderLabel: function (iconWords, iconClass) {
            return '<i class="fa fa-fw ' + iconClass + '"></i>&nbsp;' + iconWords + ' <i><small>'+ iconClass + '</small></i>'
          }
        }
        return self
      }
    ])
