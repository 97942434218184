angular.module('mosaik.controllers')
  .controller('redeemController',
    ['$scope', '$state', 'sessionState', '$http', '$window', '$stateParams', 'notificationHelper', 'accessCodeService', '$rootScope', '$translate', 'captchaHelper', '$timeout', 'utilService',
      function ($scope, $state, sessionState, $http, $window, $stateParams, notificationHelper, accessCodeService, $rootScope, $translate, captchaHelper, $timeout, utilService) {
        $scope.captchaRequired = false
        $timeout(() => {
          $scope.show = true
          utilService.focusOnDesktop('redeem-code')
        }, 100)
        $scope.redeem = {
          captcha: ''
        }
        $scope.loading = false
        $scope.$emit('stop-loader') // if the loader had been triggered

        //show messages
        notificationHelper.notifyFromParams($stateParams)

        const captchaConfig = {
          'elementid': 'recaptcha-container',
          'callback': response => $scope.redeem.captcha = response,
          'expired-callback': () => $scope.redeem.captcha = null
        }

        // Scope function to perform actual access code redeem
        $scope.redeemCode = () => {
          accessCodeService.redeem($scope.redeem)
            .then(success => {
              $rootScope.$broadcast('index-state-reload')
              $state.go(success.data.redirectState.name, success.data.redirectState.params, { reload: 'secure' })
            }, error => {
              accessCodeService.showErrorMessage(error.data.errorName, error.data.message)
              // activate captcha if required by backend
              if (error.data.captchaRequired) {
                if (!$scope.captchaRequired) {
                  // first time we need captcha for this form: we need to load      
                  captchaHelper.load(captchaConfig, true)
                } else {
                  captchaHelper.reset()
                }
                $timeout(() => $scope.captchaRequired = true)
              }

              if (!error.data.captchaRequired) {
                captchaHelper.remove()
                $scope.captchaRequired = false
              }
            })
            .finally(response => {
              $scope.loading = false
              $scope.location = false
            })
        }

        $scope.$on('locale-changed', (event, args) => {
          if ($scope.captchaRequired) captchaHelper.load(captchaConfig)
        })
      }
    ]
  )
