angular.module('mosaik.directives')
  .directive('animatedGrid',
    ['$window', '$stateParams', '$timeout',
      ($window, $stateParams, $timeout) => {
        return {
          restrict: 'A',
          scope: {
            gridItemsCount: '<'
          },
          link: (scope, element, attrs) => {
            const unhook = scope.$on('animate-grid-once', event => {
              //show deferred item
              $timeout(() => {
                angular.element('.grid-item-deferred-fadeIn.level1').addClass('animate__fadeIn')
                $timeout(() => {
                  angular.element('.grid-item-deferred-fadeIn.level2').addClass('animate__fadeIn')
                  unhook()
                }, 150)
              }, 150)
            })

            scope.$on('animate-grid-refresh-level2', event => $timeout(() => angular.element('.grid-item-deferred-fadeIn.level2').addClass('animate__fadeIn'), 150)) //refresh show deferred item

            // On empty items list, force init the grid
            if (!scope.gridItemsCount) {
              $timeout(() => scope.$emit('repeat-done'))
            }
          }
        }
      }])
