angular.module('mosaik.services')
  .factory('passwordService',
    ['$window', '$timeout', '$http', '$state', 'sessionStateService', 'notificationHelper', 'captchaHelper', 'translationService',
      function ($window, $timeout, $http, $state, sessionStateService, notificationHelper, captchaHelper, translationService) {
        const self = {
          validateStrategyAccessOrRedirect: scope => {
            sessionStateService.lastSession().then(session => {
              if (session.strategyName !== 'email') { // Can't change password if not an email account
                return $state.go('landing')
              }
              scope.model = {
                userid: session.id
              } 
            })
          },

          getMinLength: () => {
            return 8 // increase to 12 eventually
          },

          analyze: password => {
            // requires the low-budget password strength estimation library to be loaded
            // score: 0 == very weak, 1 == weak, 2,3,4 = acceptable
            if (!password || !$window.zxcvbn) {
              return { score: 0 }
            }
            const result = $window.zxcvbn(password)
            if ($window.zxcvbn.i18n) {
              const locale = translationService.getLocaleShort()
              // translate messages
              result.feedback.warning = $window.zxcvbn.i18n.translate(result.feedback.warning, locale)
              result.feedback.suggestions = result.feedback.suggestions.map(suggestion => {
                return $window.zxcvbn.i18n.translate(suggestion, locale)
              })
            }
            // return weak 1 when 0, to show this is weak
            if (result.score === 0) result.score = 1
            return result
          },

          changeForMe: options => {
            notificationHelper.removeAll()
            options.scope.loading = true
            // DT style format
            const data = {}
            data[options.model.userid] = options.model
            $http.put(options.api, { data: data })
              .then(response => {
                // clear model
                options.model.currentPassword = undefined
                options.model.password = undefined
                options.model.confirmPassword = undefined

                if (options.gotoRedirectState) {
                  // the response tells us where to go once the password is changed; only when the changePassword is forced after login
                  // notify using our standard notifier (top right corner)
                  notificationHelper.successFrom(response)
                  captchaHelper.remove()
                  $state.go(response.data.redirectState.name, response.data.redirectState.params, { reload: 'secure' })
                } else {
                  // notity using a clear popup to make sure the user got it!
                  $window.Swal.fire({
                    title: response.data.message,
                    icon: response.data.success ? 'success' : 'error'
                  })
                }
              })
              .catch(error => {
                notificationHelper.failureFrom(error)
                // activate captcha if required by backend and used in the form
                if (error.data.captchaRequired && options.captchaConfig) {
                  if (!options.scope.captchaRequired) {
                    // first time we need captcha for this form: we need to load      
                    captchaHelper.load(options.captchaConfig, true)
                  } else {
                    captchaHelper.reset()
                  }
                  $timeout(() => options.scope.captchaRequired = true)
                }

                if (!error.data.captchaRequired) {
                  captchaHelper.remove()
                  options.scope.captchaRequired = false
                }
              })
              .finally(() => options.scope.loading = false)
          },

          /**
           * 
           * @param {*} users DT style Object. ex: { data: { 234: { user1 }, 544: { user2 }, ... }}
           * @returns {Promise}
           */
          changeByAdmin: users => {
            const ids = Object.values(users.data).map(user => user.id).join(',')
            return $http.put(`/admin-api/password/change/${ids}`, users)
          },

          /**
           * 
           * @param {*} users DT style Object. ex: { data: { 234: { user1 }, 544: { user2 }, ... }}
           * @returns {Promise}
           */
          sendRecovery: users => {
            const ids = Object.values(users.data).map(user => user.id).join(',')
            return $http.put(`/admin-api/password/recover/${ids}`, users)
          }
        }
        return self
      }
    ]
  )